import React, { useMemo, useState } from 'react';
import format from 'date-fns/format';
import Question from './Question';
import { useQuestionPage } from './QuestionPage.hook';
import { QuestionQueries } from '../../queries';
import { endOfDay, startOfDay } from 'date-fns';

const QuestionPage = (props: any) => {
  const {
    questionTemplateList,
    isLoading: isQuestionTemplateLoading,
    addQuestionTemplate,
    editQuestionTemplate,
    updateQuestionTemplateOrder,
    deleteQuestionTemplate,
  } = useQuestionPage();
  const now = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);
  const [filterStartDatetime, setFilterStartDatetime] = useState<string>(now);
  const [filterEndDatetime, setFilterEndDatetime] = useState<string>(now);
  const {
    data: getQuestionTagListResponse,
    isLoading: isGetQuestionTagListLoading,
  } = QuestionQueries.useGetQuestionTagList();
  const {
    data: getQuestionListResponse,
    isLoading: isGetQuestionListLoading,
    isFetching,
    refetch,
  } = QuestionQueries.useGetQuestionList({
    gteCreatedAt: startOfDay(new Date(filterStartDatetime)).getTime(),
    ltCreatedAt: endOfDay(new Date(filterEndDatetime)).getTime(),
    deletedIncluded: false,
  });
  const { mutateAsync: answerQuestionList } =
    QuestionQueries.useAnswerQuestionListMutation();
  const isLoading =
    isQuestionTemplateLoading ||
    isGetQuestionListLoading ||
    isFetching ||
    isGetQuestionTagListLoading;

  return (
    <Question
      questionList={getQuestionListResponse?.questions || []}
      questionTagList={getQuestionTagListResponse?.tags || []}
      filterStartDatetime={filterStartDatetime}
      filterEndDatetime={filterEndDatetime}
      questionTemplateList={questionTemplateList}
      isLoading={isLoading}
      onRefreshQuestionPress={refetch}
      setFilterStartDatetime={setFilterStartDatetime}
      setFilterEndDatetime={setFilterEndDatetime}
      onAddQuestionTemplate={addQuestionTemplate}
      onUpdateQuestionTemplateOrder={updateQuestionTemplateOrder}
      onEditQuestionTemplate={editQuestionTemplate}
      onDeleteQuestionTemplate={deleteQuestionTemplate}
      onAnswerQuestionList={answerQuestionList}
      {...props}
    />
  );
};

export default QuestionPage;
