// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { uploadImage as uploadImageApi } from '@api/index';

const dataURItoBlob = (dataUri: string) => {
  const binary = atob(dataUri.split(',')[1]);
  const array = [];

  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

const cropImage = async (image, imageWidth, imageHeight, cropSize) => {
  const fileList = [];
  let cropY = 0;
  let cropHeight = cropSize;
  const cropX = 0;
  const cropWidth = cropSize;
  const count = Math.ceil(imageHeight / imageWidth);

  for (let i = 0; i < count; i++) {
    const canvas = document.createElement('canvas');

    canvas.width = cropWidth;
    cropHeight = Math.min(cropHeight, imageHeight - cropY);
    canvas.height = cropHeight;

    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight,
    );
    // eslint-disable-next-line no-await-in-loop
    const url: string = await new Promise((resolve) => {
      resolve(canvas.toDataURL('image/jpeg'));
    });
    const file = new File([dataURItoBlob(url)], `${i}.jpg`);

    fileList.push(file);

    cropY += cropHeight;
  }

  return fileList;
};

const cropImageFile = async (file: File): Promise<File[]> => {
  const imgElement = document.createElement('img');

  imgElement.src = URL.createObjectURL(file);

  return new Promise((resolve) => {
    imgElement.onload = async () => {
      const { width, height } = imgElement;
      const files = await cropImage(
        imgElement,
        width,
        height,
        width,
        file.name,
      );

      resolve(files);
    };
  });
};

const uploadImageFile = async (file: File): Promise<string> => {
  const formData = new FormData();
  const fileName = `${new Date().getTime()}_${file.name}`;

  formData.append('image', file, fileName);
  formData.append('fileName', fileName);
  formData.append('folderName', 'challengers-admin');

  const response = await uploadImageApi(formData);

  return response.url || '';
};

const uploadImageFiles = async (files: File[]): Promise<string[]> => {
  const promises = files.map((file) => uploadImageFile(file));

  return Promise.all(promises);
};

const cropAndUploadImageFile = async (file: File): Promise<string[]> => {
  const chunkedFile = await cropImageFile(file);
  const uploadedUrls = await uploadImageFiles(chunkedFile);

  return uploadedUrls;
};

export { uploadImageFile, uploadImageFiles, cropImage, cropAndUploadImageFile };
