import * as S from '@component/ChipList/ChipList.style';
import Dropdown from '@component/Dropdown';
import { useGetPurchaseChallenge } from '@queries/common/useGetPurchaseChallengeDetail';
import { useGetPurchaseChallengeList } from '@queries/common/useGetPurchaseChallengeList';
import { Purchase } from '@types';
import _ from 'lodash';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Form, Icon, Label, Table } from 'semantic-ui-react';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

type ModuleData = {
  id: string;
  name: 'PurchaseChallengeListModule';
  text: string;
  data?: { challengeIDs: number[] };
};

type Props = {
  sectionIdx: number;
  moduleIdx: number;
  module: ModuleData;
  handleChange: (e: { target: { name: string; value: number[] } }) => void;
};

const PurchaseChallengeListModule = ({
  sectionIdx,
  moduleIdx,
  module,
  handleChange,
}: Props) => {
  const { challengeIDs: purchaseChallengeIds = [] } = module.data ?? {};

  const [keyword, setKeyword] = useState('');

  const { data, hasNextPage, fetchNextPage } = useGetPurchaseChallengeList({
    keyword,
  });

  const purchaseChallengeList = useMemo(() => {
    return data?.pages.flatMap((page) => page.challenges) ?? [];
  }, [data]);

  const formatChallenge = (challenge: Purchase.Challenge) => {
    return `${challenge.id} / ${
      challenge.displayType === 'ALL' ? '노출' : '미노출'
    } / ${format(challenge.purchaseStartDate, 'M.d a h시', {
      locale: ko,
    })} / ${challenge.title}`;
  };

  const handleClickDelete = (idx: number) => {
    const updatedIds = [...purchaseChallengeIds];
    updatedIds.splice(idx, 1);
    handleChangeInput(updatedIds);
  };

  const handleChangeInput = (values: number[]) => {
    handleChange({
      target: {
        name: `${sectionIdx}-${moduleIdx}-challengeIDs`,
        value: values,
      },
    });
  };

  const handleChangeOrder = async (idx: number, direction: string) => {
    const _purchaseChallengeIds = [...purchaseChallengeIds];

    const removed = _purchaseChallengeIds.splice(idx, 1);
    if (direction === 'up') {
      if (idx === 0) return;
      _purchaseChallengeIds.splice(idx - 1, 0, removed[0]);
    } else {
      if (idx === purchaseChallengeIds.length - 1) return;
      _purchaseChallengeIds.splice(idx + 1, 0, removed[0]);
    }
    handleChangeInput(_purchaseChallengeIds);
  };

  const handleSearchKeyword = useCallback(
    _.debounce((text) => {
      setKeyword(text);
    }, 500),
    [],
  );

  const handleRemove = (idx: number) => {
    const updatedIds = [...purchaseChallengeIds];
    updatedIds.splice(idx, 1);
    handleChangeInput(updatedIds);
  };

  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 구매 챌린지 목록</h4>

      <>
        <Form.Field>
          <S.ChipListContainer style={{ marginBottom: 6 }}>
            {purchaseChallengeIds.map((id, index) => (
              <S.Chip key={`purchase-challenge-${id}`}>
                <ChallengeRenderer id={id}>
                  {(challenge) => `[${challenge.id}] ${challenge.title}`}
                </ChallengeRenderer>
                <S.RemoveButton
                  onClick={() => handleRemove(index)}
                  role="button"
                >
                  <Icon name="delete" size="small" />
                </S.RemoveButton>
              </S.Chip>
            ))}
          </S.ChipListContainer>

          <Dropdown
            search
            label="구매 챌린지 목록"
            placeholder="챌린지를 선택하세요."
            options={purchaseChallengeList.map((challenge) => {
              return {
                text: formatChallenge(challenge),
                value: challenge.id,
              };
            })}
            keyExtractor={(challenge) => challenge}
            onSearchChange={handleSearchKeyword}
            onReachEnd={hasNextPage ? fetchNextPage : undefined}
            onChange={(id) => {
              if (!id || purchaseChallengeIds.includes(id)) return;
              handleChangeInput([...purchaseChallengeIds, id]);
            }}
          />
        </Form.Field>

        <h5>노출 순서</h5>
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>제목</Table.HeaderCell>
              <Table.HeaderCell>순서변경</Table.HeaderCell>
              <Table.HeaderCell>삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {purchaseChallengeIds.map((id, idx) => (
              <Table.Row key={`purchase-challenge-row-${id}`}>
                <Table.Cell>
                  <ChallengeRenderer id={id}>
                    {(challenge) => formatChallenge(challenge)}
                  </ChallengeRenderer>
                </Table.Cell>
                <Table.Cell>
                  <Label onClick={() => handleChangeOrder(idx, 'up')}>
                    <Icon name="angle up" style={{ margin: 0 }} />
                  </Label>
                  <Label onClick={() => handleChangeOrder(idx, 'down')}>
                    <Icon name="angle down" style={{ margin: 0 }} />
                  </Label>
                </Table.Cell>
                <Table.Cell>
                  <Label onClick={() => handleClickDelete(idx)}>
                    <Icon name="close" style={{ margin: 0 }} />
                  </Label>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    </>
  );
};

type ChallengeRendererProps = {
  id: number;
  children: (props: Purchase.Challenge) => ReactNode;
};
function ChallengeRenderer({ id, children }: ChallengeRendererProps) {
  const { data } = useGetPurchaseChallenge(id);

  if (!data) {
    return <></>;
  }

  return <>{children(data.challenge)}</>;
}

export default PurchaseChallengeListModule;
