import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Grid,
  Header,
  Image,
  Menu,
} from 'semantic-ui-react';
import { apis } from '../../../api';
import BenefitMenu from '../BenefitMenu';
import { CountRow, CountWrapper } from './style';
import { getOfferWallTitle } from './utils';
import { uploadImageFile } from '../../../utils/uploadImage';
import { downloadUrl } from '../../../utils/js.utils';

const OfferWallAchievement = () => {
  const [beforeOfferWalls, setBeforeOfferWalls] = useState([]);
  const [afterOfferWalls, setAfterOfferWalls] = useState([]);
  const [currentOfferWall, setCurrentOfferWall] = useState(null);
  const [isOnlyFail, setIsOnlyFail] = useState(false);

  const [achievementList, setAchievementList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const getOfferWallList = async () => {
    const res = await apis.getOfferWalls('result_date');
    const { offerWallInfoList } = res;

    const _offerWallInfoList = offerWallInfoList.filter(
      (o) =>
        o.type === 'EXTERNAL_SIGN_UP' ||
        o.type === 'EXTERNAL_EXPERIENCE' ||
        o.type === 'CHALLENGE_PARTICIPATE' ||
        o.type === 'EXTERNAL_MISSION' ||
        o.type === 'RACE_PARTICIPATE' ||
        o.type === 'RACE_INSTAGRAM_MISSION' ||
        o.type === 'RACE_EXTERNAL_MISSION' ||
        o.type === 'RACE_SURVEY',
    );

    setBeforeOfferWalls(
      _offerWallInfoList.filter((offerWall) =>
        moment().isBetween(
          moment(offerWall.startDateTime),
          moment(offerWall.resultDate),
        ),
      ),
    );
    setAfterOfferWalls(
      _offerWallInfoList.filter((offerWall) =>
        moment().isAfter(moment(offerWall.resultDate)),
      ),
    );

    setCurrentOfferWall([..._offerWallInfoList][0]);
  };

  const getAchievementList = async (currentOfferWall) => {
    if (!currentOfferWall) return;
    const res = await apis.getOfferWallAchievementsById(currentOfferWall.id);
    setAchievementList(res.offerWallParticipationList);
  };

  const handleOfferWallImageDownloadClick = async () => {
    if (!currentOfferWall?.id) return;
    const { url } = await apis.downloadOfferWallImage(currentOfferWall.id);
    downloadUrl(url, `(${currentOfferWall.id})${currentOfferWall.title}`);
  };

  useEffect(() => {
    getAchievementList(currentOfferWall);
  }, [currentOfferWall]);

  useEffect(() => {
    setFilteredList(achievementList);
  }, [achievementList]);

  useEffect(() => {
    // isOnlyFail에 따른 필터링 필요
    if (isOnlyFail) {
      setFilteredList(achievementList.filter((a) => !a.isSucceed));
    } else {
      setFilteredList(achievementList.filter((a) => a.isSucceed));
    }
  }, [isOnlyFail]);

  useEffect(() => {
    getOfferWallList();
  }, []);

  const handleChangeSuccess = async (achievement) => {
    // 실패 처리 취소 (원복)
    if (!achievement.isSucceed) {
      if (
        confirm(
          '실패 처리를 취소하시겠어요? 실패 처리를 취소하시면 유저가 미션을 제대로 수행한것으로 처리하여 유저에게 포인트를 정상적으로 지급합니다.',
        )
      ) {
        const { success } = await apis.passOfferWallAchievement(
          achievement.id,
          true,
        );

        if (success) {
          alert(`인증 처리 되었어요`);
          const newAchievementList = achievementList.map((o) => {
            if (o.id === achievement.id) return { ...o, isSucceed: true };
            return { ...o };
          });
          setAchievementList(newAchievementList);
          setIsOnlyFail(false);
          return;
        }
      }
    }

    // 실패 처리
    if (
      window.confirm(
        '이 인증샷을 실패 처리하시겠어요? 실패 처리 시 유저에게 재인증 유도 푸시가 즉시 발송됩니다.',
      )
    ) {
      const { success } = await apis.passOfferWallAchievement(
        achievement.id,
        false,
      );

      if (success) {
        alert(`실패 처리 되었어요`);
        const newAchievementList = achievementList.map((o) => {
          if (o.id === achievement.id) return { ...o, isSucceed: false };
          return { ...o };
        });
        setAchievementList(newAchievementList);
      }
    }
  };

  useEffect(() => {
    // 검토해야하는 오퍼월 중 가장 첫 오퍼월을 디폴트로
    setCurrentOfferWall([...beforeOfferWalls, ...afterOfferWalls][0]);
  }, [beforeOfferWalls, afterOfferWalls]);

  if (!currentOfferWall) return <></>;

  const allCount = achievementList.length;
  const failedCount = achievementList.filter(
    ({ isSucceed }) => !isSucceed,
  ).length;
  const succedCount = achievementList.filter(
    ({ isSucceed }) => isSucceed,
  ).length;

  const handleFileUpload = async (e, id) => {
    if (!e.target.files) return;

    const url = await uploadImageFile(e, {});
    if (confirm('인증샷을 진짜 변경하시겠어요?')) {
      const { success } = await apis.updateOfferWallImageAchievement(id, [url]);
      if (success) {
        setFilteredList((prev) =>
          prev.map((p) => (p.id === id ? { ...p, imageUrl: url } : p)),
        );
      }
    }
  };

  return (
    <>
      <div style={{ margin: 40 }}>
        <Grid columns="equal">
          <Grid.Column width={2}>
            <BenefitMenu />
          </Grid.Column>
          <Grid.Column width={4}>
            <Header as="h3">오퍼월 인증샷</Header>
            <Menu vertical fluid>
              <Menu.Item>
                <Menu.Header>검토해야하는 오퍼월</Menu.Header>
                <Menu.Menu>
                  {beforeOfferWalls.length > 0 ? (
                    <>
                      {beforeOfferWalls.map((offerWall) => (
                        <Menu.Item
                          key={offerWall.id}
                          name={offerWall.title}
                          value={offerWall.id}
                          active={offerWall.id === currentOfferWall?.id}
                          onClick={() => setCurrentOfferWall(offerWall)}
                        >
                          {`[${moment(offerWall.resultDate).format(
                            'YYYY.MM.DD',
                          )}] (${offerWall.id}) ` +
                            getOfferWallTitle({
                              brand: offerWall.brand,
                              title: offerWall.title,
                              reward: offerWall.reward,
                            })}
                        </Menu.Item>
                      ))}
                    </>
                  ) : (
                    <Menu.Item active={false}>없음</Menu.Item>
                  )}
                </Menu.Menu>
              </Menu.Item>

              <Menu.Item>
                <Menu.Header>종료된 오퍼월</Menu.Header>
                <Menu.Menu>
                  {afterOfferWalls.length > 0 ? (
                    <>
                      {afterOfferWalls.map((offerWall) => (
                        <Menu.Item
                          key={offerWall.id}
                          name={offerWall.title}
                          value={offerWall.id}
                          active={offerWall.id === currentOfferWall?.id}
                          onClick={() => setCurrentOfferWall(offerWall)}
                        >
                          {`[${moment(offerWall.resultDate).format(
                            'YYYY.MM.DD',
                          )}] (${offerWall.id}) ` +
                            getOfferWallTitle({
                              brand: offerWall.brand,
                              title: offerWall.title,
                              reward: offerWall.reward,
                            })}
                        </Menu.Item>
                      ))}
                    </>
                  ) : (
                    <Menu.Item active={false}>없음</Menu.Item>
                  )}
                </Menu.Menu>
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column>
            {!!currentOfferWall && (
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Header as="h3">
                      {getOfferWallTitle({
                        brand: currentOfferWall.brand,
                        title: currentOfferWall.title,
                        reward: currentOfferWall.reward,
                      })}
                    </Header>

                    <Button
                      size={'small'}
                      color={'blue'}
                      onClick={handleOfferWallImageDownloadClick}
                      content={'오퍼월 이미지 다운로드'}
                    />
                  </div>

                  <CountWrapper>
                    <CountRow>
                      <span>총 개수: </span>
                      <strong>{allCount}개</strong>
                    </CountRow>
                    <CountRow>
                      <span>통과된 개수: </span>
                      <strong>{succedCount}개</strong>
                    </CountRow>
                    <CountRow>
                      <span>실패 처리된 개수: </span>
                      <strong>{failedCount}개</strong>
                    </CountRow>
                  </CountWrapper>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                  }}
                >
                  <Grid.Column>
                    <Checkbox
                      name="isOnlyFailed"
                      checked={isOnlyFail}
                      label={'실패 처리 완료만 모아보기'}
                      onChange={() => setIsOnlyFail(!isOnlyFail)}
                    />
                  </Grid.Column>
                </div>
              </>
            )}
            {filteredList?.length === 0 ? (
              <>
                <Header as="h4">없음</Header>
              </>
            ) : (
              <Grid>
                <Grid.Row columns={3}>
                  {filteredList?.map((achievement) => {
                    return (
                      <Grid.Column key={achievement.id}>
                        <Card key={achievement.id}>
                          <a
                            href={achievement.imageUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src={achievement.imageUrl}
                              size="large"
                              centered
                            />
                          </a>
                          <Card.Content extra>
                            <Header as="h5">
                              {achievement.user.nickname} {achievement.user.id}
                            </Header>
                            <div style={{ display: 'flex' }}>
                              <Button
                                color={achievement.isSucceed ? 'black' : 'red'}
                                disabled={
                                  !!afterOfferWalls.find(
                                    (offerWall) =>
                                      offerWall.id === achievement.offerWallId,
                                  )
                                }
                                onClick={() => handleChangeSuccess(achievement)}
                                content={
                                  achievement.isSucceed
                                    ? '실패 처리'
                                    : '실패 처리 완료'
                                }
                              />
                              <div className="file-input-wrapper">
                                <label
                                  className="btn-file-input"
                                  style={{
                                    color: 'white',
                                    backgroundColor: 'gray',
                                    fontWeight: 700,
                                    padding: 10,
                                    borderRadius: 5,
                                    cursor: 'pointor',
                                  }}
                                >
                                  인증샷 변경하기
                                  <input
                                    type="file"
                                    style={{
                                      display: 'none',
                                    }}
                                    onChange={(e) =>
                                      handleFileUpload(e, achievement.id)
                                    }
                                  />
                                </label>
                              </div>
                            </div>
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                    );
                  })}
                </Grid.Row>
              </Grid>
            )}
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
};

export default OfferWallAchievement;
