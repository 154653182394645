import React from 'react';
import { Image } from 'semantic-ui-react';
import _ from 'lodash';
import { RaceImageFilter } from '../../../../../types/race.types';
import { uploadImageFile } from '../../../../../utils/uploadImage';

const CustomFilterImage: React.FC<CustomFilterImageProps> = (props) => {
  const { filter, imageFilters, setImageFilters, filterIndex } = props;

  const handleRegisterImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedImageFilters = _.cloneDeep(imageFilters);

    if (!e.target.files) return;

    const url = await uploadImageFile(e.target.files[0]);
    updatedImageFilters[filterIndex].imageUrl = url;

    setImageFilters(updatedImageFilters);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label style={{ color: 'blue' }}>커스텀 필터 이미지</label>
      <div>
        <div
          style={{
            marginTop: 8,
          }}
        >
          <label>썸네일 이미지 등록</label>
          <input
            type="file"
            name="thumbnailImageUrl"
            onChange={handleRegisterImage}
          />
          {/* 썸네일 이미지 */}
          {filter.thumbnailImageUrl ? (
            <div
              style={{
                width: 60,
                height: 60,
                position: 'relative',
                marginTop: 4,
              }}
            >
              <Image
                size="tiny"
                src={filter.thumbnailImageUrl}
                style={{
                  width: 60,
                  height: 60,
                }}
              />
            </div>
          ) : (
            <div
              style={{
                width: 60,
                height: 60,
                backgroundColor: 'lightgray',
                color: 'white',
                fontSize: 12,
                padding: 8,
              }}
            >
              썸네일 이미지
            </div>
          )}
        </div>
        <div
          style={{
            marginTop: 8,
          }}
        >
          <label>필터 이미지 등록</label>
          <input type="file" name="imageUrl" onChange={handleRegisterImage} />
          {/* 필터 이미지 */}
          {filter.imageUrl ? (
            <div
              style={{
                width: 300,
                height: 300,
                position: 'relative',
              }}
            >
              <Image
                style={{ width: 300, height: 300 }}
                src={filter.imageUrl}
              />
            </div>
          ) : (
            <div
              style={{
                width: 300,
                height: 300,
                backgroundColor: 'lightgray',
                color: 'white',
                fontSize: 16,
                padding: 8,
              }}
            >
              필터 이미지
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type CustomFilterImageProps = {
  filter: RaceImageFilter;
  imageFilters: RaceImageFilter[];
  setImageFilters: (imageFilters: RaceImageFilter[]) => void;
  filterIndex: number;
};

export default CustomFilterImage;
