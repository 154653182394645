import React, { Component } from 'react';
import {
  Card,
  Image,
  Loader,
  Grid,
  Feed,
  Button,
  Modal,
  Label,
  Icon,
  Divider,
  Header,
  Dropdown,
  TextArea,
  Dimmer,
  Form,
  Radio,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import api, { apiGo, apis } from '../../../api/index';
import AchievementComment from './AchievementComment/AchievementComment';
import resizeImage from '../../../global/resizeImage';
import {
  reportReasonObj,
  reviewOptions,
  reviewResult,
} from '../../../constant/reviewResult';
import { chlngersUrl, itemUrl } from '../../../constant/imgUrls';
import RegisterInfo from './RegisterInfo';
import AbusingInfo from './AbusingInfo';
import AWS from 'aws-sdk';
import { awsConfig } from '../../../config';
import { RetryChallengeInfo, CartInfo } from '../../Shared';
import { uploadImageFile } from '../../../utils/uploadImage';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import AllAchievementModal from '../AllAchievementModal';
import { REVIEW_TEMPLATES } from '../../../constant/reviewTemplate';

AWS.config.update({
  region: awsConfig.bucketRegion,
  credentials: new AWS.Credentials({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
  }),
  dynamoDbCrc32: false,
});

const ddb = new AWS.DynamoDB({
  apiVersion: '2012-08-10',
});

class AchievementDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      achievement: {},
      user: {
        nickname: '',
      },
      carts: [],
      userChallengesCompleted: {},
      challengeCompletedMounted: false,
      challengeCompletedLoading: false,
      isMounted: false,
      likeModalOpen: false,
      reviewResultModalOpen: false,
      reportModalOpen: false,
      reviewModalOpen: false,
      heartModalOpen: false,
      review: '',
      reviewComment: '',
      message: '',
      isLoading: false,
      reviewModalOpenRegisterInfo: false,
      reviewRegisterInfo: '',
      achievementRegisterInfo: {},
      messageRegisterInfo: '',
      deleteModalOpen: false,
      commentModalOpen: false,
      redirect: false,
      deleteModalOpenRegisterInfo: false,
      uploadModalOpenRegisterInfo: false,
      commentModalOpenRegisterInfo: false,
      selectImage: 'custom',
      adminCommentModalOpen: false,
      reportModalOpenRegisterInfo: false,
      reports: [],
      reviewTemplates: [],
      reviewMessageOptions: [],
      reviewDeleteCommentOptions: [
        {
          key: 0,
          message:
            '[본 내용은 회원님에게만 보입니다. 다른 의견이 있으신 경우 댓글이 아닌 마이페이지탭의 문의하기를 통해 연락주세요]\n\n사유 : \n\n인증 규정과 달라 회원님의 인증샷이 정상처리 되지 않았습니다😥 공정한 진행을 위해 아쉽게도 이번 인증샷은 삭제되오니 다음에는 인증 규정을 꼭 지켜주세요!\n\n[인증 규정]\n${authenticationMethod}',
          order: 0,
          text: '삭제 - 푸시발송용',
          value: 0,
        },
        {
          key: 1,
          message:
            '[본 내용은 회원님에게만 보입니다. 다른 의견이 있으신 경우 댓글이 아닌 마이페이지탭의 문의하기를 통해 연락주세요]\n\n[인증 규정]\n${authenticationMethod}',
          order: 1,
          text: '삭제 - 인증처리용',
          value: 1,
        },
      ],
      deleteOptionValue: '',
      heartModalOpenRegisterInfo: false,
      heartCancelModalOpenRegisterInfo: false,
      cancelModalOpen: false,
      deleteAndPassCheckedModalOpen: false,
      checkedAchievementFileIds: [],
      retries: [],
      allAchievementFilesModalOpenRegisterInfo: false,
      allAchievementFiles: [],
      authentications: {},
      challengeAccordion: {},
      challengeAccordionAllOpen: false,
      switchModalOpen: false,
      dragItem: null,
      dropItem: null,
    };
    this.el = React.createRef();
    this.commentEl = React.createRef();
    this.ref = React.createRef();
    this.isScrolling = false;
    this.clientY = 0;
    this.scrollY = 0;
    this.review = {
      reviewComment: '',
    };
    this.comment = {
      value: '',
      commentCompleted: true,
    };
    this.deleteCommentEl = React.createRef();
    this.deleteCommentVariable = {
      value: '',
      commentCompleted: true,
    };
    this.date = {
      filterStartDatetime: moment().subtract(30, 'd').format('YYYY-MM-DD'),
      filterEndDatetime: moment().format('YYYY-MM-DD'),
    };
  }

  onMouseDown = (e) => {
    this.isScrolling = true;
    this.clientY = e.clientY;
  };

  onMouseUp = () => {
    this.isScrolling = false;
    this.clientY = 0;
    this.scrollY = 0;
  };

  onMouseMove = (e) => {
    if (this.isScrolling) {
      this.ref.current.scrollTop = this.scrollY + e.clientY - this.clientY;
      this.scrollY = this.scrollY + e.clientY - this.clientY;
    }
  };

  async componentDidMount() {
    const res = await apis.getAchievement(this.props.match.params.id);
    const { achievement } = res;
    const resUser = await apis.user.getUser(achievement.user.id);
    const { user, carts } = resUser;
    user.achievements.forEach((a) => (a.isChecked = false));
    const challengeAccordion = {};
    user.relationUserChallenges.forEach((ruc) => {
      challengeAccordion[ruc.challenge.id] = false;
    });
    const pictureUrl =
      user.pictureUrl === ''
        ? 'https://d246jgzr1jye8u.cloudfront.net/development/challenge/1536807709708362.jpg'
        : user.pictureUrl;
    user.pictureUrl = resizeImage(pictureUrl, 80);
    this.setState({
      user,
      carts,
      achievement,
      isMounted: true,
      challengeAccordion,
    });
    this.getUserRetries(achievement.user.id);
    this.getReviewTemplates();
  }

  getReviewTemplates = async () => {
    const { reviewTemplates: _reviewTemplates } =
      await apis.reviewTemplates.getReviewTemplates();

    const reviewMessageOptions = _.sortBy(_reviewTemplates, ['order']);
    const sortedTemplates = _.map(reviewMessageOptions, (t) => t.message);

    this.setState({
      reviewTemplates: sortedTemplates,
      reviewMessageOptions,
    });
  };

  getUserRetries = async (userId) => {
    const res = await apis.user.getUserRetryChallenges(userId);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { retries } = res;
    this.setState({ retries });
  };

  deleteCart = (cartId) => {
    api
      .delete(`/cart/${cartId}`)
      .then(() => {
        alert('삭제가 완료되었습니다.');
        const carts = this.state.carts.filter((c) => c.id !== cartId);
        this.setState({ carts });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  showLikeModal = () => this.setState({ likeModalOpen: true });
  closeLikeModal = () => this.setState({ likeModalOpen: false });

  showReviewResultModal = () => this.setState({ reviewResultModalOpen: true });
  closeReviewResultModal = () =>
    this.setState({ reviewResultModalOpen: false });

  showReportModal = () => this.setState({ reportModalOpen: true });
  closeReportModal = () => this.setState({ reportModalOpen: false });

  showReviewModal = (e) =>
    this.setState({ reviewModalOpen: true, review: e.target.name });
  closeReviewModal = () => this.setState({ reviewModalOpen: false });

  showDeleteModal = () =>
    this.setState({ deleteModalOpen: true }, () => {
      this.deleteCommentEl.current.ref.current.value =
        this.deleteCommentVariable.value;
    });
  closeDeleteModal = () => this.setState({ deleteModalOpen: false });

  deleteAndSendPush = () => {
    if (confirm('인증샷 삭제 및 푸시를 발송하시겠습니까?')) {
      const { achievement } = this.state;
      let authenticationMethodText = `${achievement.challenge.authenticationMethod}\n`;
      if (achievement.challenge.authenticationChecklist) {
        authenticationMethodText +=
          achievement.challenge.authenticationChecklist
            ?.map((item) => '* ' + item)
            .join('\n');
      }

      this.deleteCommentVariable.value = `[본 내용은 회원님에게만 보입니다. 다른 의견이 있으신 경우 댓글이 아닌 마이페이지탭의 문의하기를 통해 연락주세요]\n인증 규정과 달라 회원님의 인증샷이 정상처리 되지 않았습니다. 😥\n\n[챌린지 인증방법]\n${authenticationMethodText}\n\n공정한 진행을 위해 아쉽게도 이번 인증샷은 삭제되오니 다음에는 인증 규정을 꼭 지켜주세요!`;
      this.deleteAchievementFile('PUSH');
    }
  };

  showCommentModal = () => {
    this.setState({ commentModalOpen: true }, () => {
      this.commentEl.current.ref.current.value = this.comment.value;
    });
  };
  closeCommentModal = () => this.setState({ commentModalOpen: false });

  showHeartModal = (e) => this.setState({ heartModalOpen: true });
  closeHeartModal = () => this.setState({ heartModalOpen: false });

  handleChange = (e) => {
    this.setState({ reviewComment: e.target.value });
  };
  handleRadioChange = (e, { name, value }) => {
    this.setState({ review: value });
  };
  handleReviewResult = async (isPushNeeded) => {
    this.setState({ isLoading: true });
    const { achievement, review, reviewComment } = this.state;
    const res = await apis.reviewAchievementFile(
      achievement.achievementFile.id,
      {
        reviewResult: review,
        reviewComment,
        isPushNeeded,
      },
    );
    const { achievement: newAchievement } = res;
    this.setState({
      isLoading: false,
      reviewModalOpen: false,
      achievement: newAchievement,
    });
  };

  deleteAchievementFile = async (deleteType) => {
    const { achievement, user } = this.state;
    const res = await apis.deleteAchievementFile(
      achievement.achievementFile.id,
      {
        deleteType,
        reviewComment: this.deleteCommentVariable.value,
        userId: user.id,
      },
    );
    if (res?.response?.data?.err) {
      this.setState({ deleteModalOpen: false });
      return;
    }

    alert('삭제가 완료되었어요.');
    this.setState({ redirect: true });
  };
  commentAchievement = async (e, { name }) => {
    if (!this.comment.commentCompleted) {
      alert('발송 진행 중입니다.');
      return;
    }
    this.comment.commentCompleted = false;
    const { achievement } = this.state;
    const { value } = this.comment;

    const res = await apis.commentAchievementFile(
      achievement.achievementFile.id,
      {
        reviewComment: value,
        sendType: name,
      },
    );
    if (res?.response?.data?.err) {
      return;
    }
    const { achievementFileComment: comment } = res;
    achievement.comments.push(comment);
    this.setState({ achievement, commentModalOpen: false });
    this.comment.commentCompleted = true;
    alert('댓글 및 푸시(문자) 발송이 완료되었습니다.');
  };
  handleChangeCommentTemplate = (e, { value }) => {
    const { reviewTemplates, user, achievement } = this.state;
    this.setState({ message: value });
    let comment = reviewTemplates[value];
    comment = comment.replace(/\${nickname}/g, user.nickname);
    let authenticationMethodText = `${achievement.challenge.authenticationMethod}\n`;
    if (achievement.challenge.authenticationChecklist) {
      authenticationMethodText += achievement.challenge.authenticationChecklist
        ?.map((item) => '* ' + item)
        .join('\n');
    }
    comment = comment.replace(
      /\${authenticationMethod}/g,
      authenticationMethodText,
    );
    this.comment.value = comment;
    this.commentEl.current.ref.current.value = comment;
  };
  handleChangeComment = (e) => {
    this.comment.value = e.target.value;
  };
  handleChangeDeleteCommentTemplate = (e, { value }) => {
    const {
      reviewDeleteCommentOptions,
      achievement: { challenge },
      user,
    } = this.state;
    let reviewComment = reviewDeleteCommentOptions[value].message;

    reviewComment = reviewComment.replace(/\${nickname}/g, user.nickname);
    let authenticationMethodText = `${challenge.authenticationMethod}\n`;
    if (challenge.authenticationChecklist) {
      authenticationMethodText += challenge.authenticationChecklist
        ?.map((item) => '* ' + item)
        .join('\n');
    }
    reviewComment = reviewComment.replace(
      /\${authenticationMethod}/g,
      authenticationMethodText,
    );

    this.setState({ deleteOptionValue: value });
    this.deleteCommentVariable.value = reviewComment;
    this.deleteCommentEl.current.ref.current.value = reviewComment;
  };
  handleChangeDeleteComment = (e) => {
    this.deleteCommentVariable.value = e.target.value;
  };

  userHeart = async () => {
    const { achievement } = this.state;
    const res = await apis.useAchievementItem(achievement.id);
    if (res?.response?.data?.err) {
      return;
    }
    achievement.achievementFile = {
      url: itemUrl,
      comments: [],
      reports: [],
      createdAt: moment(),
      submitDatetime: moment(
        `${achievement.dueStartDate} ${achievement.dueStartTime}`,
      ),
    };
    alert('하트 사용이 완료되었습니다.');
    this.setState({ achievement, heartModalOpen: false });
  };

  showReviewModalRegisterInfo = (e, { name, value }) => {
    this.setState({
      reviewModalOpenRegisterInfo: true,
      reviewRegisterInfo: e.target.name,
      achievementRegisterInfo: value,
    });
  };
  closeReviewModalRegisterInfo = () =>
    this.setState({ reviewModalOpenRegisterInfo: false });
  handleChangeMessageRegisterInfo = (e, { value }) => {
    const { user, achievementRegisterInfo, reviewTemplates } = this.state;
    let comment = reviewTemplates[value];
    comment = comment.replace(/\${nickname}/g, user.nickname);
    let authenticationMethodText = `${achievementRegisterInfo.challenge.authenticationMethod}\n`;
    if (achievementRegisterInfo.challenge.authenticationChecklist) {
      authenticationMethodText +=
        achievementRegisterInfo.challenge.authenticationChecklist
          ?.map((item) => '* ' + item)
          .join('\n');
    }
    comment = comment.replace(
      /\${authenticationMethod}/g,
      authenticationMethodText,
    );
    this.setState({ messageRegisterInfo: value });
    this.el.current.ref.current.value = comment;
    this.review.reviewComment = comment;
  };
  handleRadioChangeRegisterInfo = (e, { value }) => {
    this.setState({ reviewRegisterInfo: value });
  };
  handleChangeReviewCommentRegisterInfo = (e) => {
    this.review.reviewComment = e.target.value;
  };

  handleReviewResultRegisterInfo = async (isPushNeeded) => {
    const { reviewRegisterInfo, achievementRegisterInfo, user } = this.state;
    const { reviewComment } = this.review;
    const res = await apis.reviewAchievementFile(
      achievementRegisterInfo.achievementFile.id,
      {
        reviewResult: reviewRegisterInfo,
        reviewComment,
        isPushNeeded,
      },
    );
    const { achievement: newAchievement } = res;
    const newAchievements = user.achievements.map((a) => {
      if (a.id === newAchievement.id) {
        a.achievementFile.reviewResult = reviewRegisterInfo;
        return a;
      } else return a;
    });
    this.setState({
      achievements: newAchievements,
      reviewModalOpenRegisterInfo: false,
    });
  };

  showDeleteModalRegisterInfo = (e, { value }) =>
    this.setState(
      {
        deleteModalOpenRegisterInfo: true,
        achievementRegisterInfo: value,
      },
      () => {
        this.deleteCommentEl.current.ref.current.value =
          this.deleteCommentVariable.value;
      },
    );
  closeDeleteModalRegisterInfo = () =>
    this.setState({ deleteModalOpenRegisterInfo: false });
  showCommentModalRegisterInfo = (e, { value }) =>
    this.setState(
      {
        commentModalOpenRegisterInfo: true,
        achievementRegisterInfo: value,
      },
      () => {
        this.commentEl.current.ref.current.value = this.comment.value;
      },
    );
  closeCommentModalRegisterInfo = () =>
    this.setState({ commentModalOpenRegisterInfo: false });
  showUploadModalRegisterInfo = (e, { value }) => {
    value.submitDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
    this.setState({
      uploadModalOpenRegisterInfo: true,
      achievementRegisterInfo: value,
    });
  };
  closeUploadModalRegisterInfo = () =>
    this.setState({ uploadModalOpenRegisterInfo: false });

  deleteAchievementFileRegisterInfo = async (deleteType) => {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true }, async () => {
      const { achievementRegisterInfo, user } = this.state;
      const res = await apis.deleteAchievementFile(
        achievementRegisterInfo.achievementFile.id,
        {
          deleteType,
          reviewComment: this.deleteCommentVariable.value,
          userId: user.id,
        },
      );
      if (res?.response?.data?.err) {
        this.setState({ deleteModalOpen: false });
        return;
      }
      const { achievementIds } = res;
      this.updateDeletedAchievement(achievementIds, deleteType);
      this.setState({ isLoading: false });
    });
  };

  updateDeletedAchievement = (achievementIds, deleteType) => {
    const { user } = this.state;
    user.achievements.forEach((a) => {
      if (achievementIds.includes(a.id)) {
        if (['DELETE', 'PUSH'].includes(deleteType)) {
          a.achievementFile = {};
        } else {
          a.achievementFile.url =
            'https://d246jgzr1jye8u.cloudfront.net/development/achievement/success.jpg';
        }
        a.transactionItem = null;
      }
    });
    this.setState(
      { user, deleteModalOpenRegisterInfo: false, achievementRegisterInfo: {} },
      () => {
        alert('인증샷 삭제가 완료되었어요');
      },
    );
  };
  commentAchievementRegisterInfo = async (e, { name }) => {
    if (!this.comment.commentCompleted) {
      alert('발송 진행 중입니다.');
      return;
    }
    this.comment.commentCompleted = false;
    const { achievementRegisterInfo } = this.state;
    const { value } = this.comment;
    const res = await apis.commentAchievementFile(
      achievementRegisterInfo.achievementFile.id,
      {
        reviewComment: value,
        sendType: name,
      },
    );
    if (res?.response?.data?.err) {
      return;
    }
    this.setState({ commentModalOpenRegisterInfo: false });
    this.comment.commentCompleted = true;
    alert('댓글 및 푸시(문자) 발송이 완료되었습니다.');
  };
  uploadAchievementFileRegisterInfo = async () => {
    const { achievementRegisterInfo, user } = this.state;
    const res = await apis.createAchievementFile(achievementRegisterInfo.id, {
      imageUrl: achievementRegisterInfo.url,
      submitDatetime: achievementRegisterInfo.submitDatetime,
      userId: user.id,
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { achievementFile } = res;

    const newAchievements = user.achievements.map((a) => {
      if (a.id === achievementRegisterInfo.id) {
        a.achievementFile = {
          ...achievementFile,
          comments: [],
          reports: [],
        };
        return a;
      } else return a;
    });

    this.setState({
      achievements: newAchievements,
      uploadModalOpenRegisterInfo: false,
      selectImage: 'custom',
    });
  };

  returnFeedUsers = (users) => {
    return _.map(users, (user) => {
      let userUrl = user.pictureUrl === '' ? chlngersUrl : user.pictureUrl;
      userUrl = resizeImage(userUrl, 100);
      return {
        image: userUrl,
        summary: user.nickname,
        extraText: user.reportReason + ' / ' + user.comment,
      };
    });
  };

  deleteComment = async (e, { value }) => {
    const { achievement } = this.state;
    const res = await apis.achievement.deleteAchievementFileComment(
      achievement.achievementFile.id,
      value,
    );

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    alert('댓글 삭제가 완료되었어요.');

    achievement.comments = _.filter(
      achievement.comments,
      (item) => item.id !== value,
    );
    this.setState({ achievement });
  };

  showCancelModal = (e) => {
    const { user } = this.state;
    const ruc = _.find(
      user.relationUserChallenges,
      (r) => r.id === parseInt(e.target.value, 10),
    );
    this.setState({ ruc: ruc, cancelModalOpen: true });
  };
  closeCancelModal = () => {
    if (!this.state.isLoading) {
      this.setState({ cancelModalOpen: false });
    }
  };
  registerCancel = () => {
    this.setState({ isLoading: false });
    const { user, ruc } = this.state;
    apiGo
      .post('/challenges/cancel/forced', {
        userIDs: [user.id],
        challengeIDs: [ruc.challengeId],
      })
      .then(() => {
        user.relationUserChallenges = _.filter(
          user.relationUserChallenges,
          (r) => r.id !== parseInt(ruc.id, 10),
        );
        this.setState({ user, cancelModalOpen: false, isLoading: false });
        alert('챌린지 신청 취소가 완료되었습니다.');
      })
      .catch((error) => {
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        );
      });
  };

  showDeleteAndPassCheckedModal = () => {
    this.setState({ deleteAndPassCheckedModalOpen: true });
  };
  closeDeleteAndPassCheckedModal = () =>
    this.setState({ deleteAndPassCheckedModalOpen: false });

  setDragItem = (achievement) => this.setState({ dragItem: achievement });
  setDropItem = (achievement) => {
    if (this.state.dragItem.id !== achievement.id) {
      this.setState({ dropItem: achievement, switchModalOpen: true });
    }
  };
  closeSwitchModal = () => {
    if (!this.state.isLoading) {
      this.setState({ switchModalOpen: false, dragItem: null, dropItem: null });
    }
  };
  switchAchievementFiles = async () => {
    this.setState({ isLoading: true });
    const { dragItem, dropItem, user } = this.state;
    const dragItemFile = { ...dragItem.achievementFile };
    const dropItemFile = { ...dropItem.achievementFile };
    // 드래그 아이템을 드롭 아이템으로 바꿔치기
    if (dropItem.achievementFile.id !== 0) {
      // 드래그 아이템이 있을 경우
      if (dragItem.achievementFile.id !== 0) {
        await apis.achievement.updateAchievementFileAchievementId(
          dragItem.achievementFile.id,
          dropItem.id,
        );
        user.achievements.forEach((a) => {
          if (a.id === dragItem.id) {
            a.achievementFile = dropItemFile;
          }
        });
      } else {
        // 드래그 아이템이 원래 없는 경우에는 새로 등록한다
        const res = await apis.createAchievementFile(dragItem.id, {
          imageUrl: dropItem.achievementFile.url,
          submitDatetime: moment(
            `${dragItem.dueStartDate} ${dragItem.dueStartTime}`,
          ).format('YYYY-MM-DD HH:mm:ss'),
          userId: user.id,
        });

        if (res?.response?.data && 'err' in res?.response?.data) {
          return;
        }

        const { achievementFile } = res;

        await apis.deleteAchievementFile(dropItem.achievementFile.id, {
          deleteType: 'DELETE',
          reviewComment: '',
          userId: user.id,
        });

        user.achievements.forEach((a) => {
          if (a.id === dragItem.id) {
            a.achievementFile = {
              ...achievementFile,
              comments: [],
              reports: [],
            };
          } else if (a.id === dropItem.id) {
            a.achievementFile = {};
            a.rate = null;
          }
        });
      }
    }
    // 드롭 아이템을 드래그 아이템으로 바꿔치기
    if (dragItem.achievementFile.id !== 0) {
      // 드롭 아이템이 원래 있는 경우
      if (dropItem.achievementFile.id !== 0) {
        await apis.achievement.updateAchievementFileAchievementId(
          dropItem.achievementFile.id,
          dragItem.id,
        );
        user.achievements.forEach((a) => {
          if (a.id === dropItem.id) {
            a.achievementFile = dragItemFile;
          }
        });
      } else {
        // 드롭 아이템이 원래 없는 경우에는 새로 등록한다
        const res = await apis.createAchievementFile(dropItem.id, {
          imageUrl: dragItem.achievementFile.url,
          submitDatetime: moment(
            `${dropItem.dueStartDate} ${dropItem.dueStartTime}`,
          ).format('YYYY-MM-DD HH:mm:ss'),
          userId: user.id,
        });

        if (res?.response?.data && 'err' in res?.response?.data) {
          return;
        }

        const { achievementFile } = res;

        await apis.deleteAchievementFile(dragItem.achievementFile.id, {
          deleteType: 'DELETE',
          reviewComment: '',
          userId: user.id,
        });

        user.achievements.forEach((a) => {
          if (a.id === dropItem.id) {
            a.achievementFile = {
              ...achievementFile,
              comments: [],
              reports: [],
            };
          } else if (a.id === dragItem.id) {
            a.achievementFile = {};
            a.rate = null;
          }
        });
      }
    }
    this.setState({ user, isLoading: false }, () => {
      alert('변경이 완료되었습니다.');
      this.closeSwitchModal();
    });
  };

  handleCheckbox = (e, { name, value }) => {
    const { user, checkedAchievementFileIds } = this.state;
    user.achievements.forEach((a) => {
      if (a.achievementFile.id === value) {
        a.isChecked = !a.isChecked;
      }
    });
    let newCheckedAchievementFileIds;
    if (checkedAchievementFileIds.includes(value)) {
      newCheckedAchievementFileIds = checkedAchievementFileIds.filter(
        (id) => id !== value,
      );
    } else {
      newCheckedAchievementFileIds = [...checkedAchievementFileIds, value];
    }
    this.setState({
      user,
      checkedAchievementFileIds: newCheckedAchievementFileIds,
    });
  };

  deleteAndPassCheckedImages = async () => {
    const { checkedAchievementFileIds, isLoading } = this.state;
    if (isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    const res = await apis.achievement.bulkUpdateAchievementFileReviewResult({
      achievementFileIds: checkedAchievementFileIds,
      reviewComment: 'DELETE_AND_PASS',
      reviewComment: '',
      isPushNeeded: false,
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    alert(
      `선택한 인증샷 ${checkedAchievementFileIds.length}개 삭제가 완료되었습니다.`,
    );

    window.location.reload();
  };

  handleChangeMessage = (e, { value }) => {
    const { reviewTemplates, user, achievement } = this.state;
    let reviewComment = reviewTemplates[value];
    reviewComment = reviewComment.replace(/\${nickname}/g, user.nickname);
    let authenticationMethodText = `${achievement.challenge.authenticationMethod}\n`;
    if (achievement.challenge.authenticationChecklist) {
      authenticationMethodText += achievement.challenge.authenticationChecklist
        ?.map((item) => '* ' + item)
        .join('\n');
    }
    reviewComment = reviewComment.replace(
      /\${authenticationMethod}/g,
      authenticationMethodText,
    );
    this.setState({ message: value, reviewComment });
  };
  handleAchievementRegisterInfoChange = (e) => {
    const { achievementRegisterInfo } = this.state;
    achievementRegisterInfo[e.target.name] = e.target.value;
    this.setState({ achievementRegisterInfo });
  };
  handleChangeSingleFile = async (e) => {
    if (!e.target.files) return;

    const url = await uploadImageFile(e.target.files[0]);
    this.setState({
      achievementRegisterInfo: {
        ...this.state.achievementRegisterInfo,
        [e.target.name]: url,
      },
    });
  };
  handleSelectImageChange = (e, { name, value }) => {
    const { achievementRegisterInfo } = this.state;
    achievementRegisterInfo.url =
      value === 'success'
        ? 'https://d246jgzr1jye8u.cloudfront.net/development/achievement/success.jpg'
        : '';
    this.setState({ selectImage: value, achievementRegisterInfo });
  };

  getCompletedChallenges = () => {
    const { user } = this.state;
    const { filterStartDatetime, filterEndDatetime } = this.date;
    this.setState({ challengeCompletedLoading: true });
    api
      .get(`/admin/users/${user.id}/challenges/completed`, {
        params: {
          filterStartDatetime: filterStartDatetime,
          filterEndDatetime: filterEndDatetime,
        },
      })
      .then((response) => {
        const userChallengesCompleted = response.data.data.user;
        user.commentAchievementFiles = user.commentAchievementFiles.concat(
          userChallengesCompleted.commentAchievementFiles,
        );
        this.setState({
          user,
          userChallengesCompleted,
          challengeCompletedMounted: true,
          challengeCompletedLoading: false,
        });
      });
  };

  handleDate = (e, { name, value }) => {
    this.date[name] = value;
  };

  showAdminCommentModal = (e, { name, value }) =>
    this.setState({ adminCommentModalOpen: true, adminComments: value });
  closeAdminCommentModal = () =>
    this.setState({ adminCommentModalOpen: false });

  showReportModalRegisterInfo = (e, { name, value }) =>
    this.setState({
      reportModalOpenRegisterInfo: true,
      reports: value,
    });
  closeReportModalRegisterInfo = () =>
    this.setState({ reportModalOpenRegisterInfo: false });

  showHeartModalRegisterInfo = (e, { name, value }) => {
    value.submitDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
    this.setState({
      heartModalOpenRegisterInfo: true,
      achievementRegisterInfo: value,
    });
  };
  closeHeartModalRegisterInfo = () =>
    this.setState({ heartModalOpenRegisterInfo: false });

  useHeartRegisterInfo = async () => {
    const { user, achievementRegisterInfo } = this.state;
    const res = await apis.useAchievementItem(achievementRegisterInfo.id);
    if (res?.response?.data?.err) {
      return;
    }
    const { achievementIds } = res;
    user.achievements.forEach((a) => {
      if (achievementIds.includes(a.id)) {
        a.achievementFile = {
          url: itemUrl,
          comments: [],
          reports: [],
          createdAt: moment(),
          submitDatetime: moment(
            `${achievementRegisterInfo.dueStartDate} ${achievementRegisterInfo.dueStartTime}`,
          ),
        };
      }
    });
    this.setState({ user, heartModalOpenRegisterInfo: false });
    alert('하트 사용이 완료되었습니다.');
  };
  showHeartCancelModalRegisterInfo = (e, { value }) =>
    this.setState({
      heartCancelModalOpenRegisterInfo: true,
      achievementRegisterInfo: value,
    });
  closeHeartCancelModalRegisterInfo = () =>
    this.setState({ heartCancelModalOpenRegisterInfo: false });
  cancelHeartRegisterInfo = async () => {
    const { achievementRegisterInfo } = this.state;
    const res = await apis.deleteTransactionItem(achievement.id, {
      userId: user.id,
    });
    if (res?.response?.data?.err) {
      this.setState({ heartCancelModalOpenRegisterInfo: false });
      return;
    }
    alert('하트 사용 취소가 완료되었습니다.');
    const { achievementIds } = res;
    user.achievements.forEach((a) => {
      if (achievementIds.includes(a.id)) {
        a.achievementFile = {};
        delete a['transactionItem'];
      }
    });
    this.setState({ user, heartCancelModalOpenRegisterInfo: false });
  };

  showAllAchievementFilesModalRegisterInfo = async (e, { value }) => {
    LoadingIndicator.show();

    try {
      const res =
        await apis.achievement.getAchievementFilesByAchievementId(value);

      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }

      if (allAchievementFiles.length === 0) {
        LoadingIndicator.hide();
        return;
      }

      const { allAchievementFiles } = res;

      this.setState({
        allAchievementFilesModalOpenRegisterInfo: true,
        allAchievementFiles,
        authentications: {
          authenticationMethod:
            allAchievementFiles[0].challenge.authenticationMethod,
          authenticationChecklist:
            allAchievementFiles[0].challenge.authenticationChecklist,
        },
      });
    } finally {
      LoadingIndicator.hide();
    }
  };
  closeAllAchievementFilesModalRegisterInfo = () => {
    this.setState({
      allAchievementFilesModalOpenRegisterInfo: false,
      allAchievementFiles: [],
      authentications: {},
    });
  };

  handleChallengeAccordion = (e, titleProps) => {
    const { index: challengeId } = titleProps;
    const { challengeAccordion } = this.state;
    challengeAccordion[challengeId] = !challengeAccordion[challengeId];
    this.setState({ challengeAccordion });
  };

  changeChallengeAccordionAllOpen = () => {
    const { challengeAccordion } = this.state;
    const challengeAccordionAllOpen = !this.state.challengeAccordionAllOpen;
    Object.keys(challengeAccordion).forEach((key) => {
      challengeAccordion[key] = challengeAccordionAllOpen;
    });
    this.setState({ challengeAccordionAllOpen, challengeAccordion });
  };

  render() {
    const {
      achievement,
      redirect,
      isMounted,
      likeModalOpen,
      reviewResultModalOpen,
      reportModalOpen,
      reviewModalOpen,
      review,
      reviewComment,
      isLoading,
      user,
      message,
      reviewModalOpenRegisterInfo,
      reviewRegisterInfo,
      messageRegisterInfo,
      deleteModalOpen,
      deleteModalOpenRegisterInfo,
      uploadModalOpenRegisterInfo,
      achievementRegisterInfo,
      selectImage,
      cancelModalOpen,
      ruc,
      commentModalOpen,
      commentModalOpenRegisterInfo,
      userChallengesCompleted,
      challengeCompletedMounted,
      challengeCompletedLoading,
      adminComments,
      adminCommentModalOpen,
      reportModalOpenRegisterInfo,
      reports,
      reviewMessageOptions,
      heartModalOpen,
      heartModalOpenRegisterInfo,
      heartCancelModalOpenRegisterInfo,
      reviewDeleteCommentOptions,
      deleteOptionValue,
      deleteAndPassCheckedModalOpen,
      checkedAchievementFileIds,
      retries,
      allAchievementFilesModalOpenRegisterInfo,
      allAchievementFiles,
      authentications,
      challengeAccordion,
      challengeAccordionAllOpen,
      carts,
      switchModalOpen,
      dragItem,
      dropItem,
    } = this.state;
    const { filterStartDatetime, filterEndDatetime } = this.date;

    if (redirect) return <Redirect to={{ pathname: '/achievements' }} />;
    if (!isMounted) return <Loader active inline="centered" />;

    const likeUsers = this.returnFeedUsers(achievement.likeUsers);
    const reportAchievement = user.achievements.find(
      (a) => a.id === achievement.id,
    );
    let authenticationMethodText = `${achievement.challenge.authenticationMethod}`;
    if (
      achievement.challenge.authenticationChecklist &&
      achievement.challenge.authenticationChecklist.length > 0
    ) {
      authenticationMethodText += `\n\n`;
      authenticationMethodText += achievement.challenge.authenticationChecklist
        .map((item) => '* ' + item)
        .join(`\n`);
    }

    return (
      <div
        ref={this.ref}
        style={{ margin: 40 }}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
      >
        <Grid columns="equal">
          <Grid.Column />
          <Grid.Column width={10}>
            <Card fluid>
              <a
                href={achievement.achievementFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  size={'medium'}
                  src={achievement.achievementFile.url}
                  centered
                />
              </a>
              <Card.Content>
                <Card.Header> {achievement.challenge.title}</Card.Header>
                <Card.Meta>
                  <a
                    href={`/users/${achievement.user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Label>
                      <Icon name="user">
                        {' '}
                        {achievement.user.id} | {achievement.user.nickname}
                      </Icon>
                    </Label>
                  </a>
                  <br />
                  {achievement.nthTerm}회차 | id: {achievement.id} | fileId:{' '}
                  {achievement.achievementFile.id} <br />
                  메모 : {achievement.achievementFile.memo}
                </Card.Meta>
                <Card.Description>
                  마감일 :{' '}
                  {moment(achievement.dueEndDate).format('YYYY-MM-DD') +
                    ' ' +
                    achievement.dueEndTime}{' '}
                  <br />
                  제출시간 :{' '}
                  {moment(achievement.achievementFile.submitDatetime).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}{' '}
                  <br />
                  <h4>인증방법</h4>
                  {authenticationMethodText.split('\n').map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Card.Description>
              </Card.Content>

              <Card.Content extra>
                <Label color="red" onClick={this.showLikeModal}>
                  <Icon name="like" /> {achievement.likeUsers.length}
                </Label>
                <Label color="black" onClick={this.showReportModal}>
                  <Icon name="warning sign" />{' '}
                  {achievement.achievementFile.reportCount}
                </Label>
                <Label color="yellow" onClick={this.showReportModal}>
                  챌린지 내 경고 누적수: {achievement.warnCount}
                </Label>
                {achievement.achievementFile.reviewResult ===
                  reviewResult.before && <Label>리뷰전</Label>}
                {achievement.achievementFile.reviewResult ===
                  reviewResult.pass && (
                  <Label onClick={this.showReviewResultModal}>통과</Label>
                )}
                {achievement.achievementFile.reviewResult ===
                  reviewResult.red && (
                  <Label onClick={this.showReviewResultModal}>레드</Label>
                )}
                {achievement.achievementFile.reviewResult ===
                  reviewResult.fail && (
                  <Label onClick={this.showReviewResultModal}>미인증</Label>
                )}
                {achievement.achievementFile.reviewResult ===
                  reviewResult.comment && (
                  <Label onClick={this.showReviewResultModal}>경고댓글</Label>
                )}
                <Button
                  size="mini"
                  floated="right"
                  onClick={this.showHeartModal}
                >
                  인증패스
                </Button>
                <Button
                  size="mini"
                  floated="right"
                  onClick={this.showCommentModal}
                >
                  경고댓글
                </Button>
                <Button
                  size="mini"
                  floated="right"
                  onClick={this.deleteAndSendPush}
                >
                  삭제 푸시
                </Button>
                <Button
                  size="mini"
                  floated="right"
                  onClick={this.showDeleteModal}
                >
                  인증샷삭제
                </Button>
                <Button
                  size="mini"
                  color="blue"
                  name={reviewResult.pass}
                  floated="right"
                  onClick={this.showReviewModal}
                >
                  통과
                </Button>
                <Button
                  size="mini"
                  color="red"
                  name={reviewResult.red}
                  floated="right"
                  onClick={this.showReviewModal}
                >
                  레드
                </Button>
              </Card.Content>
            </Card>
            <Divider />
            <Feed>
              {achievement.comments.length === 0 && (
                <Feed.Content>
                  <Feed.Extra text>댓글이 없습니다.</Feed.Extra>
                </Feed.Content>
              )}
              {achievement.comments.map((comment, idx) => (
                <AchievementComment
                  key={idx}
                  comment={comment}
                  deleteComment={this.deleteComment}
                />
              ))}
            </Feed>
          </Grid.Column>
          <Grid.Column />
        </Grid>
        <RegisterInfo
          user={user}
          title={'현재 진행중인 챌린지 참가이력'}
          showCancelModal={this.showCancelModal}
          showReviewModal={this.showReviewModalRegisterInfo}
          showDeleteModal={this.showDeleteModalRegisterInfo}
          showCommentModal={this.showCommentModalRegisterInfo}
          showUploadModal={this.showUploadModalRegisterInfo}
          getCompletedChallenges={this.getCompletedChallenges}
          showAdminCommentModal={this.showAdminCommentModal}
          showAdminReportModal={this.showReportModalRegisterInfo}
          filterStartDatetime={filterStartDatetime}
          filterEndDatetime={filterEndDatetime}
          handleDate={this.handleDate}
          showHeartModal={this.showHeartModalRegisterInfo}
          showHeartCancelModal={this.showHeartCancelModalRegisterInfo}
          showAllAchievementFilesModal={
            this.showAllAchievementFilesModalRegisterInfo
          }
          handleCheckbox={this.handleCheckbox}
          showDeleteAndPassCheckedModal={this.showDeleteAndPassCheckedModal}
          challengeAccordion={challengeAccordion}
          handleChallengeAccordion={this.handleChallengeAccordion}
          challengeAccordionAllOpen={challengeAccordionAllOpen}
          changeChallengeAccordionAllOpen={this.changeChallengeAccordionAllOpen}
          setDragItem={this.setDragItem}
          setDropItem={this.setDropItem}
        />
        <Modal
          size="tiny"
          open={reviewModalOpenRegisterInfo}
          onClose={this.closeReviewModalRegisterInfo}
        >
          <Modal.Header>인증샷 리뷰하기</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>메세지 템플릿</label>
                <Dropdown
                  placeholder="템플릿을 선택하세요."
                  fluid
                  search
                  selection
                  name="messageId"
                  value={messageRegisterInfo}
                  options={reviewMessageOptions.map((template) => ({
                    ...template,
                    key: template.order,
                    value: template.order,
                    text: template.title,
                  }))}
                  onChange={this.handleChangeMessageRegisterInfo}
                />
              </Form.Field>
              <Form.Field>
                <label>리뷰 결과</label>
                <Dropdown
                  placeholder="리뷰 결과를 선택하세요.."
                  name="review"
                  selection
                  options={reviewOptions}
                  value={reviewRegisterInfo}
                  onChange={this.handleRadioChangeRegisterInfo}
                />
              </Form.Field>
              <Form.Field>
                <label>코멘트</label>
                <TextArea
                  ref={this.el}
                  rows={6}
                  name="reviewComment"
                  onChange={this.handleChangeReviewCommentRegisterInfo}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="제출"
              onClick={() => this.handleReviewResultRegisterInfo(false)}
            />
            <Button
              color="blue"
              content="제출(푸시)"
              onClick={() => this.handleReviewResultRegisterInfo(true)}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeReviewModalRegisterInfo}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={cancelModalOpen}
          onClose={this.closeCancelModal}
        >
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Modal.Header>챌린지 신청 취소</Modal.Header>
          <Modal.Content>
            {!_.isEmpty(ruc) && (
              <p>
                {ruc.id} {ruc.challenge.title}
              </p>
            )}
            <p>위의 챌린지 신청을 취소 하시겠습니까?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="신청취소"
              onClick={this.registerCancel}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeCancelModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={deleteModalOpen}
          onClose={this.closeDeleteModal}
        >
          <Modal.Header>인증샷 삭제</Modal.Header>
          <Modal.Content>
            인증샷 삭제를 하시겠습니까?
            <br />
            인증기간 내 인증샷 삭제의 경우 재인증이 가능합니다.
            <Form>
              <Form.Field>
                <label>메세지 템플릿</label>
                <Dropdown
                  placeholder="템플릿을 선택하세요."
                  fluid
                  search
                  selection
                  name="messageId"
                  value={deleteOptionValue}
                  options={reviewDeleteCommentOptions.map((template) => ({
                    ...template,
                    key: template.order,
                    value: template.order,
                    text: template.title,
                  }))}
                  onChange={this.handleChangeDeleteCommentTemplate}
                />
              </Form.Field>
              <Form.Field>
                <label>댓글 내용을 작성해주세요.</label>
                <TextArea
                  ref={this.deleteCommentEl}
                  rows={20}
                  onChange={this.handleChangeDeleteComment}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="삭제"
              onClick={() => this.deleteAchievementFile('DELETE')}
            />
            <Button
              color="blue"
              content="삭제(푸시발송)"
              onClick={() => this.deleteAchievementFile('PUSH')}
            />
            <Button
              color="blue"
              content="삭제(성공처리)"
              onClick={() => this.deleteAchievementFile('DELETE_AND_PASS')}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeDeleteModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={commentModalOpen}
          onClose={this.closeCommentModal}
        >
          <Modal.Header>인증샷 경고 댓글</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>메세지 템플릿</label>
                <Dropdown
                  placeholder="템플릿을 선택하세요."
                  fluid
                  search
                  selection
                  name="messageId"
                  value={message}
                  options={reviewMessageOptions.map((template) => ({
                    ...template,
                    key: template.order,
                    value: template.order,
                    text: template.title,
                  }))}
                  onChange={this.handleChangeCommentTemplate}
                />
              </Form.Field>
              <Form.Field>
                <label>댓글 내용을 작성해주세요.</label>
                <TextArea
                  ref={this.commentEl}
                  rows={20}
                  onChange={this.handleChangeComment}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="yellow"
              content="경고처리(푸시)"
              name="WARN"
              onClick={this.commentAchievement}
            />
            <Button
              color="blue"
              content="댓글작성(푸시)"
              name="PUSH"
              onClick={this.commentAchievement}
            />
            <Button
              color="blue"
              content="댓글작성(문자)"
              name="SMS"
              onClick={this.commentAchievement}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeCommentModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal size="tiny" open={heartModalOpen} onClose={this.closeHeartModal}>
          <Modal.Header>인증패스 처리하기</Modal.Header>
          <Modal.Content>
            <p>[{achievement.id}] 인증카드에 인증패스 처리를 하시겠습니까?</p>
            <span style={{ color: 'red' }}>
              *100% 달성 시, 예치금만 반환되며 상금(페이백)은 지급되지 않는
              처리방법입니다.
            </span>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="처리" onClick={this.userHeart} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeHeartModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={deleteModalOpenRegisterInfo}
          onClose={this.closeDeleteModalRegisterInfo}
        >
          <Modal.Header>인증샷 삭제</Modal.Header>
          <Modal.Content>
            인증샷 삭제를 하시겠습니까?
            <br />
            인증기간 내 인증샷 삭제의 경우 재인증이 가능합니다.
            <Form>
              <Form.Field>
                <label>메세지 템플릿</label>
                <Dropdown
                  placeholder="템플릿을 선택하세요."
                  fluid
                  search
                  selection
                  name="messageId"
                  value={deleteOptionValue}
                  options={reviewDeleteCommentOptions.map((template) => ({
                    ...template,
                    key: template.order,
                    value: template.order,
                    text: template.title,
                  }))}
                  onChange={this.handleChangeDeleteCommentTemplate}
                />
              </Form.Field>
              <Form.Field>
                <label>댓글 내용을 작성해주세요.</label>
                <TextArea
                  ref={this.deleteCommentEl}
                  rows={20}
                  onChange={this.handleChangeDeleteComment}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="삭제"
              onClick={() => this.deleteAchievementFileRegisterInfo('DELETE')}
            />
            <Button
              color="blue"
              content="삭제(푸시발송)"
              onClick={() => this.deleteAchievementFileRegisterInfo('PUSH')}
            />
            <Button
              color="blue"
              content="삭제(성공처리)"
              onClick={() =>
                this.deleteAchievementFileRegisterInfo('DELETE_AND_PASS')
              }
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeDeleteModalRegisterInfo}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={commentModalOpenRegisterInfo}
          onClose={this.closeCommentModalRegisterInfo}
        >
          <Modal.Header>인증샷 경고 댓글</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>메세지 템플릿</label>
                <Dropdown
                  placeholder="템플릿을 선택하세요."
                  fluid
                  search
                  selection
                  name="messageId"
                  value={message}
                  options={reviewMessageOptions.map((template) => ({
                    ...template,
                    key: template.order,
                    value: template.order,
                    text: template.title,
                  }))}
                  onChange={this.handleChangeCommentTemplate}
                />
              </Form.Field>
              <Form.Field>
                <label>댓글 내용을 작성해주세요.</label>
                <TextArea
                  ref={this.commentEl}
                  rows={20}
                  onChange={this.handleChangeComment}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="경고처리(푸시)"
              name="WARN"
              onClick={this.commentAchievementRegisterInfo}
            />
            <Button
              color="blue"
              content="댓글작성(푸시)"
              name="PUSH"
              onClick={this.commentAchievementRegisterInfo}
            />
            <Button
              color="blue"
              content="댓글작성(문자)"
              name="SMS"
              onClick={this.commentAchievementRegisterInfo}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeCommentModalRegisterInfo}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="small"
          open={deleteAndPassCheckedModalOpen}
          onClose={this.closeDeleteAndPassCheckedModal}
        >
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Modal.Header>인증샷 일괄 삭제 및 인증처리</Modal.Header>
          <Modal.Content>
            총 {checkedAchievementFileIds.length}개의 인증샷 삭제 후 인증 처리를
            진행하시겠습니까?
            <br />
            {user.achievements
              .filter((a) =>
                checkedAchievementFileIds.includes(a.achievementFile.id),
              )
              .map((a) => {
                return (
                  <div key={a.id} style={{ marginBottom: 10 }}>
                    <Image size={'small'} src={a.achievementFile.url} />
                    {a.id} / {a.achievementFile.id}
                  </div>
                );
              })}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="삭제(성공처리)"
              onClick={this.deleteAndPassCheckedImages}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeDeleteAndPassCheckedModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={uploadModalOpenRegisterInfo}
          onClose={this.closeUploadModalRegisterInfo}
        >
          <Modal.Header>인증샷 업로드</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <Form.Group inline>
                  <Form.Field
                    control={Radio}
                    label="성공 처리"
                    value="success"
                    name="selectImage"
                    checked={selectImage === 'success'}
                    onChange={this.handleSelectImageChange}
                  />
                  <Form.Field
                    control={Radio}
                    label="개별 선택"
                    value="custom"
                    name="selectImage"
                    checked={selectImage === 'custom'}
                    onChange={this.handleSelectImageChange}
                  />
                </Form.Group>
                <span style={{ color: 'red' }}>
                  *100% 달성 시, 예치금과 상금(페이백) 모두 지급되는
                  처리방법입니다.
                </span>
              </Form.Field>
              <Form.Field disabled={selectImage === 'success'}>
                <label>인증샷 이미지</label>
                <input
                  type="file"
                  name={'url'}
                  onChange={this.handleChangeSingleFile}
                />
              </Form.Field>
              <Image src={achievementRegisterInfo.url} size="medium" />
              <Form.Field>
                <label>제출시간</label>
                <input
                  name="submitDatetime"
                  value={achievementRegisterInfo.submitDatetime}
                  onChange={this.handleAchievementRegisterInfoChange}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="업로드"
              onClick={this.uploadAchievementFileRegisterInfo}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeUploadModalRegisterInfo}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={adminCommentModalOpen}
          onClose={this.closeDeleteModal}
        >
          <Modal.Header>공식 댓글 정보</Modal.Header>
          <Modal.Content>
            {adminComments &&
              adminComments.map((comment, idx) => (
                <p key={idx}>
                  {moment(comment.createdAt)
                    .add(9, 'h')
                    .format('YYYY-MM-DD HH:mm:ss')}
                  <br />
                  {comment.comment}
                </p>
              ))}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              content="닫기"
              onClick={this.closeAdminCommentModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={reportModalOpenRegisterInfo}
          onClose={this.closeReportModalRegisterInfo}
        >
          <Modal.Header>신고 정보</Modal.Header>
          <Modal.Content>
            {reports &&
              reports.map((ru) => {
                return (
                  <div key={ru.id}>
                    {ru.id} / {ru.name} / {ru.nickname} / {ru.reportReason} /{' '}
                    {ru.comment}
                    <br />
                  </div>
                );
              })}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              content="닫기"
              onClick={this.closeReportModalRegisterInfo}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={heartModalOpenRegisterInfo}
          onClose={this.closeHeartModalRegisterInfo}
        >
          <Modal.Header>인증패스 처리하기</Modal.Header>
          <Modal.Content>
            <p>
              [{achievementRegisterInfo.id}] 인증카드에 인증패스 처리를
              하시겠습니까?
            </p>
            <Form>
              <Form.Field>
                <label>제출시간</label>
                <input
                  name="submitDatetime"
                  value={achievementRegisterInfo.submitDatetime}
                  onChange={this.handleAchievementRegisterInfoChange}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="사용"
              onClick={this.useHeartRegisterInfo}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeHeartModalRegisterInfo}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={heartCancelModalOpenRegisterInfo}
          onClose={this.closeHeartCancelModalRegisterInfo}
        >
          <Modal.Header>하트 사용 취소하기</Modal.Header>
          <Modal.Content>
            <p>
              [{achievementRegisterInfo.id}] 인증카드에 사용한 하트를
              취소하시겠습니까?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="취소하기"
              onClick={this.cancelHeartRegisterInfo}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeHeartCancelModalRegisterInfo}
            />
          </Modal.Actions>
        </Modal>

        <AllAchievementModal
          isOpened={allAchievementFilesModalOpenRegisterInfo}
          onClosePress={this.closeAllAchievementFilesModalRegisterInfo}
          allAchievementFiles={allAchievementFiles}
          authentications={authentications}
        />

        <Modal
          size="tiny"
          open={switchModalOpen}
          onClose={this.closeSwitchModal}
        >
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Modal.Header>인증샷 교체</Modal.Header>
          <Modal.Content>
            <p>두 인증카드의 인증샷을 교체하시겠습니까?</p>
            {dragItem && dropItem && (
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 2 }}>
                  <Image fluid src={dragItem.achievementFile.url} />
                  <p>{dragItem.challenge.title}</p>
                  <p>
                    {dragItem.id} / {dragItem.achievementFile.id}
                  </p>
                </div>
                <div style={{ flex: 1, textAlign: 'center', fontSize: 30 }}>
                  ↔
                </div>
                <div style={{ flex: 2 }}>
                  <Image fluid src={dropItem.achievementFile.url} />
                  <p>{dropItem.challenge.title}</p>
                  <p>
                    {dropItem.id} / {dropItem.achievementFile.id}
                  </p>
                </div>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="교체"
              onClick={this.switchAchievementFiles}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeSwitchModal}
            />
          </Modal.Actions>
        </Modal>

        {challengeCompletedMounted && (
          <RegisterInfo
            user={userChallengesCompleted}
            title={'상금 수령 완료한 챌린지 참가이력'}
            showCancelModal={this.showCancelModal}
            showReviewModal={this.showReviewModalRegisterInfo}
            showDeleteModal={this.showDeleteModalRegisterInfo}
            showCommentModal={this.showCommentModalRegisterInfo}
            showUploadModal={this.showUploadModalRegisterInfo}
            showAdminCommentModal={this.showAdminCommentModal}
            showAdminReportModal={this.showReportModalRegisterInfo}
            showHeartModal={this.showHeartModalRegisterInfo}
            challengeAccordion={challengeAccordion}
            handleChallengeAccordion={this.handleChallengeAccordion}
            challengeAccordionAllOpen={challengeAccordionAllOpen}
            changeChallengeAccordionAllOpen={
              this.changeChallengeAccordionAllOpen
            }
          />
        )}
        {challengeCompletedLoading && <Loader active inline="centered" />}
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <RetryChallengeInfo retries={retries} />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <CartInfo carts={carts} deleteCart={this.deleteCart} />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <AbusingInfo user={user} />
        <Modal size="mini" open={likeModalOpen} onClose={this.closeLikeModal}>
          <Modal.Header>좋아요</Modal.Header>
          <Modal.Content>
            <Feed events={likeUsers} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              content="닫기"
              onClick={this.closeLikeModal}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          size="tiny"
          open={reportModalOpen}
          onClose={this.closeReportModal}
        >
          <Modal.Header>신고 정보</Modal.Header>
          <Modal.Content>
            {reportAchievement &&
              reportAchievement.achievementFile.reports?.map((ru) => {
                return (
                  <div key={ru.id}>
                    {ru.id} / {ru.name} / {ru.nickname} /{' '}
                    {reportReasonObj[ru.reportReason]} / {ru.comment}
                    <br />
                  </div>
                );
              })}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              content="닫기"
              onClick={this.closeReportModal}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          size="tiny"
          open={reviewResultModalOpen}
          onClose={this.closeReviewResultModal}
        >
          <Modal.Header>인증샷 리뷰</Modal.Header>
          <Modal.Content>
            <Header as="h3">리뷰 결과</Header>
            <p>{achievement.achievementFile.reviewResult}</p>
            <Header as="h3">코멘트</Header>
            <p>{achievement.achievementFile.reviewComment}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              content="닫기"
              onClick={this.closeReviewResultModal}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          size="tiny"
          open={reviewModalOpen}
          onClose={this.closeReviewModal}
        >
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
          <Modal.Header>인증샷 리뷰하기</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>메세지 템플릿</label>
                <Dropdown
                  placeholder="템플릿을 선택하세요."
                  fluid
                  search
                  selection
                  name="messageId"
                  value={message}
                  options={reviewMessageOptions.map((template) => ({
                    ...template,
                    key: template.order,
                    value: template.order,
                    text: template.title,
                  }))}
                  onChange={this.handleChangeMessage}
                />
              </Form.Field>
              <Form.Field>
                <label>리뷰결과</label>
                <Dropdown
                  placeholder="리뷰 결과를 선택하세요.."
                  name="review"
                  selection
                  options={reviewOptions}
                  value={review}
                  onChange={this.handleRadioChange}
                />
              </Form.Field>
              <Form.Field>
                <label>코멘트</label>
                <TextArea
                  rows={6}
                  name="reviewComment"
                  value={reviewComment}
                  onChange={this.handleChange}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="제출"
              onClick={() => this.handleReviewResult(false)}
            />
            <Button
              color="blue"
              content="제출(푸시)"
              onClick={() => this.handleReviewResult(true)}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeReviewModal}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default AchievementDetail;
