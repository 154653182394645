import { useEffect, useState } from 'react';
import { Form, Image, Button } from 'semantic-ui-react';
import {
  uploadImageFile,
  uploadImageFiles,
} from '../../../../../utils/uploadImage';
import * as S from '../../../Style';

import { RaceCardInfo } from 'src/types/race.types';
import { RaceFormType } from '../../RaceForm.types';

export const colors = {
  GRAY_900: '#111111',
  BASIC_WHITE: '#FFFFFF',
};

type Props = {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
};

const RaceCardInfoField = ({ race, setRace }: Props) => {
  const [goodsImageUrls, setGoodsImageUrls] = useState<string[]>([]);

  const handleChangeValue: <K extends keyof RaceCardInfo>(
    key: K,
    value: RaceCardInfo[K],
  ) => void = (key, value) => {
    setRace({
      ...race,
      info: {
        ...race.info,
        cardInfo: {
          ...race.info.cardInfo,
          [key]: value,
        },
      },
    });
  };

  const handleChangeTextValue: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    const text = e.target.value;
    const value = !!text && e.target.type === 'number' ? Number(text) : text;
    const name = e.target.name as keyof RaceCardInfo;
    handleChangeValue(name, value);
  };

  const handleImageChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    const name = e.target.name as keyof RaceCardInfo;

    if (!e.target.files) return;

    const url = await uploadImageFile(e.target.files[0]);
    handleChangeValue(name, url);
  };

  const handleGoodsImageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const name = e.target.name as keyof RaceCardInfo;

    if (!e.target.files) return;

    const urls = await uploadImageFiles([...e.target.files]);
    const copyed = [...urls, ...goodsImageUrls];
    handleChangeValue(name, copyed);
    setGoodsImageUrls(copyed);
  };

  const handleAddGoodsClick = () => {
    setGoodsImageUrls((prev) => [...prev, '']);
  };

  const handleDeleteGoodsClick = (idx: number) => {
    const filtered = goodsImageUrls.filter((_, index) => index !== idx);
    setGoodsImageUrls(filtered);
    handleChangeValue('goodsImageUrls', filtered);
  };

  useEffect(() => {
    setGoodsImageUrls(
      race.info.cardInfo?.goodsImageUrls?.length
        ? race.info.cardInfo?.goodsImageUrls
        : [''],
    );
  }, [race.info.cardInfo?.goodsImageUrls]);

  return (
    <>
      <h3>대회 홈 카드 정보</h3>

      <h3>문구</h3>
      <S.RowContainer>
        <S.RowFormField>
          <label>후킹 문구</label>
          <textarea
            style={{ resize: 'none' }}
            rows={2}
            name="hookingText"
            value={race.info.cardInfo?.hookingText}
            onChange={handleChangeTextValue}
          />
        </S.RowFormField>
        <S.RowFormField margin={'0 0 0 20px'}>
          <label>후킹 문구 컬러 hex 코드 (ex) #000000</label>
          <input
            name="hookingTextColor"
            value={race.info.cardInfo?.hookingTextColor}
            onChange={handleChangeTextValue}
          />
        </S.RowFormField>
      </S.RowContainer>

      <h3>
        세로형 대회 카드 (랜선탭용)
        <br />
        <span style={{ fontSize: 12, color: 'blue' }}>
          *가로형/ 세로형 카드는 서로 다른 이미지로 세팅해주세요
        </span>
      </h3>
      <S.RowContainer>
        <S.RowFormField>
          <label>배경 이미지</label>
          <input
            type="file"
            name="backgroundImageUrl"
            onChange={handleImageChange}
          />
          <Image
            src={race.info.cardInfo?.backgroundImageUrl || ''}
            size="small"
          />
          <label style={{ fontSize: 12, color: 'blue' }}>
            * 마감 리포트 Page1 커버에도 동일한 이미지가 등록됩니다.
          </label>
        </S.RowFormField>
      </S.RowContainer>

      <h3>
        가로형 대회 카드 (홈탭용)
        <br />
        <span style={{ fontSize: 12, color: 'blue' }}>
          *가로형/ 세로형 카드는 서로 다른 이미지로 세팅해주세요
        </span>
      </h3>
      <S.RowContainer style={{ gap: 12, marginTop: 20 }}>
        <S.RowFormField>
          <label>배경 이미지 (5:7)</label>
          <input
            type="file"
            name="cardBackgroundImageUrlA"
            onChange={handleImageChange}
          />
          <Image
            src={race.info.cardInfo?.cardBackgroundImageUrlA || ''}
            size="small"
          />
        </S.RowFormField>

        <S.RowFormField>
          <label>
            굿즈 이미지들{' '}
            <span
              style={{
                fontSize: 12,
                color: 'blue',
              }}
            >
              * 굿즈 이미지는 랜선탭 카드에도 동일하게 보여집니다
            </span>
          </label>
          <input
            type="file"
            multiple
            name="goodsImageUrls"
            onChange={handleGoodsImageChange}
          />

          <S.RowContainer gap="5px" style={{ flexWrap: 'wrap' }}>
            {goodsImageUrls.map((url, idx) => (
              <div key={`${url}-${idx}`}>
                <Image
                  src={url || ''}
                  size="small"
                  style={{ marginBottom: 10 }}
                />
                <button
                  type="button"
                  style={{ width: 54, height: 34, cursor: 'pointer' }}
                  onClick={() => handleDeleteGoodsClick(idx)}
                >
                  삭제
                </button>
              </div>
            ))}
          </S.RowContainer>

          <Button color="black" content="추가" onClick={handleAddGoodsClick} />
        </S.RowFormField>
      </S.RowContainer>

      <h3>웹페이지용 대회 카드</h3>
      <S.BlueText>
        *기존 100원딜 카드. 이벤트 페이지 모듈로 대회를 노출하고 싶다면
        세팅해주세요.
      </S.BlueText>
      <S.RowContainer style={{ gap: 20 }}>
        <S.RowFormField>
          <label>
            썸네일 이미지(1:1){' '}
            <S.BlueText>(상품 뷰티컷 세팅 O. 누끼컷 X)</S.BlueText>
          </label>
          <input
            type="file"
            name="thumbnailImageUrl"
            onChange={handleImageChange}
          />
          <Image
            src={race.info.cardInfo?.thumbnailImageUrl || ''}
            size="small"
          />
        </S.RowFormField>

        <S.RowFormField>
          <label>썸네일 카드 문구(2줄 까지)</label>
          <textarea
            style={{ resize: 'none' }}
            rows={2}
            name="thumbnailTitle"
            value={race.info.cardInfo?.thumbnailTitle}
            onChange={handleChangeTextValue}
          />
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

export default RaceCardInfoField;
