import React, { Component } from 'react';
import { createHashHistory } from 'history';
import {
  Button,
  Container,
  Dropdown,
  Form,
  Grid,
  Header,
  Image,
  Input,
  Menu,
  Modal,
} from 'semantic-ui-react';
import ChallengeStickerTable from './ChallengeStickerTable';
import StickerCompanyTable from './StickerCompanyTable';
import StickerChallengeTable from './StickerChallengeTable';
import getLocal, { setLocal } from '../../../global/local';
import _ from 'lodash';
import { apis } from '../../../api';
import moment from 'moment';
import { uploadImageFile } from '../../../utils/uploadImage';
import {
  getChallengeOptions,
  getCompanyOptions,
} from '../../../utils/dropdownOptions';

class ChallengeSticker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      challengeStickers: [],
      challengeSticker: {},
      challengeOptions: [],
      companies: [],
      companyOptions: [],
      challenges: [],
      column: null,
      direction: null,
      stickerCompanyId: 0,
      stickerChallengeId: 0,
      checkedAll: false,
      checkedIds: [],
      isMounted: false,
      stickerActiveItem:
        getLocal('stickerActiveItem') === null
          ? 'sticker'
          : getLocal('stickerActiveItem'),
      activeChallengeRequests: [],
      editStickerModalOpen: false,
      deleteStickerModalOpen: false,
    };
    this.sticker = {
      challengeId: 0,
      companyId: 0,
      imageUrl: '',
      positionX: 0.3,
      positionY: 0,
      width: 0,
      isFixed: 'true',
      order: 0,
    };
  }

  async componentDidMount() {
    const companyOptions = await getCompanyOptions();
    const challengeOptions = await getChallengeOptions({
      gteResultDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      isOfficial: true,
      isPublic: true,
      isDeleted: false,
    });
    this.setState({ companyOptions, challengeOptions }, () => {
      this.getAllStickers();
    });
  }

  getAllStickers = async () => {
    const { companyOptions, challengeOptions } = this.state;
    const { challengeStickers } = await apis.challenge.getChallengeStickers();

    const companyObj = {};
    companyOptions.forEach((o) => {
      if (!companyObj[o.value]) {
        companyObj[o.value] = { id: o.value, name: o.text, stickers: [] };
      }
    });
    const challengeObj = {};
    challengeOptions.forEach((o) => {
      if (!challengeObj[o.value]) {
        challengeObj[o.value] = { id: o.value, title: o.title, stickers: [] };
      }
    });

    const newChallengeObj = {};
    const newComapnyObj = {};
    challengeStickers.forEach((s) => {
      if (challengeObj[s.challengeId]) {
        newChallengeObj[s.challengeId] = { ...challengeObj[s.challengeId] };
        newChallengeObj[s.challengeId].stickers.push(s);
        s.challengeTitle = challengeObj[s.challengeId].title;
      }
      if (companyObj[s.companyId]) {
        newComapnyObj[s.companyId] = { ...companyObj[s.companyId] };
        newComapnyObj[s.companyId].stickers.push(s);
        s.companyName = companyObj[s.companyId].name;
      }
    });

    const challenges = _.values(newChallengeObj);
    const companies = _.values(newComapnyObj);
    _.reverse(challengeStickers);
    _.reverse(challenges);
    _.reverse(companies);

    this.setState({ challengeStickers, companies, challenges });
  };

  handleSort = (clickedColumn) => () => {
    const { column, challengeStickers, direction } = this.state;
    if (column !== clickedColumn) {
      const newChallengeStickers = _.sortBy(challengeStickers, [clickedColumn]);
      this.setState({
        column: clickedColumn,
        challengeStickers: newChallengeStickers,
        direction: 'ascending',
        isSorted: true,
      });
      return;
    }

    this.setState({
      challengeStickers: challengeStickers.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  handleSortCompanies = (clickedColumn) => () => {
    const { column, companies, direction } = this.state;
    if (column !== clickedColumn) {
      const newCompanies = _.sortBy(companies, [clickedColumn]);
      this.setState({
        column: clickedColumn,
        companies: newCompanies,
        direction: 'ascending',
        isSorted: true,
      });
      return;
    }

    this.setState({
      companies: companies.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  handleSortChallenges = (clickedColumn) => () => {
    const { column, challenges, direction } = this.state;
    if (column !== clickedColumn) {
      const newChallenges = _.sortBy(challenges, [clickedColumn]);
      this.setState({
        column: clickedColumn,
        challenges: newChallenges,
        direction: 'ascending',
        isSorted: true,
      });
      return;
    }

    this.setState({
      challenges: challenges.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  showStickerAddModal = () => this.setState({ addStickerModalOpen: true });
  closeStickerAddModal = () => this.setState({ addStickerModalOpen: false });

  handleChangeSticker = (e) => {
    this.sticker[e.target.name] = e.target.value;
  };
  handleRadioChange = (e, { name, value }) => {
    this.sticker[name] = value;
  };
  handleChangeSingleFile = async (e) => {
    if (!e.target.files) return;

    const url = await uploadImageFile(e.target.files[0]);
    this.setState({ imageUrl: url });
  };
  addSticker = async () => {
    this.sticker.isFixed = this.sticker.isFixed === 'true';
    await apis.challenge.createChallengeSticker(this.sticker);
    await this.getAllStickers();
    this.closeStickerAddModal();
  };

  handleItemClick = (e, { name }) => {
    this.setState({ stickerActiveItem: name });
    setLocal('stickerActiveItem', name);
  };

  showStickerEditModal = (e, { value }) => {
    this.setState({ editStickerModalOpen: true, challengeSticker: value });
  };
  closeStickerEditModal = () => this.setState({ editStickerModalOpen: false });
  editSticker = async () => {
    const {
      challengeSticker,
      challengeStickers,
      challengeOptions,
      companyOptions,
    } = this.state;
    const newChallengeStickers = _.map(challengeStickers, (s) => {
      if (s.id === challengeSticker.id) {
        s.companyId = challengeSticker.companyId;
        s.companyName =
          companyOptions.find((o) => o.value === challengeSticker.companyId)
            ?.text || '';
        s.imageUrl = challengeSticker.imageUrl;
        s.order = challengeSticker.order;
        s.challengeId = challengeSticker.challengeId;
        s.challengeTitle =
          challengeOptions.find((o) => o.value === challengeSticker.challengeId)
            ?.title || '';
      }
      return s;
    });
    await apis.challenge.updateChallengeSticker(challengeSticker.id, {
      ...challengeSticker,
    });
    this.setState({ challengeStickers: newChallengeStickers });
    this.closeStickerEditModal();
  };
  handleChangeStickerEdit = (e) => {
    const { challengeSticker } = this.state;
    challengeSticker[e.target.name] = e.target.value;
    this.setState({ challengeSticker });
  };
  handleStickerRadioChangeEdit = (e, { name, value }) => {
    const { challengeSticker } = this.state;
    challengeSticker[name] = value;
    this.setState({ challengeSticker });
  };
  handleStickerChangeSingleFile = async (e) => {
    if (!e.target.files) return;

    const url = await uploadImageFile(
      e.target.files[0],
      this.state.challengeSticker,
    );
    this.setState({
      challengeSticker: {
        ...this.state.challengeSticker,
        imageUrl: url,
      },
    });
  };

  showStickerDeleteModal = (e, { value }) => {
    this.setState({ deleteStickerModalOpen: true, challengeSticker: value });
  };
  closeStickerDeleteModal = () =>
    this.setState({ deleteStickerModalOpen: false });
  deleteSticker = async () => {
    const { challengeStickers, challengeSticker } = this.state;
    await apis.challenge.deleteChallengeSticker(challengeSticker.id);
    const newChallengeStickers = _.filter(
      challengeStickers,
      (s) => s.id !== challengeSticker.id,
    );
    this.setState({ challengeStickers: newChallengeStickers });
    this.closeStickerDeleteModal();
    alert('삭제가 완료되었어요.');
  };

  render() {
    const {
      challengeStickers,
      column,
      direction,
      addStickerModalOpen,
      editStickerModalOpen,
      deleteStickerModalOpen,
      challengeOptions,
      companyOptions,
      imageUrl,
      stickerActiveItem,
      companies,
      challenges,
      challengeSticker,
    } = this.state;
    const { challengeId, companyId } = this.sticker;
    return (
      <Container>
        <Header>챌린지 스티커</Header>
        <Grid columns={2}>
          <Grid.Column width={3}>
            <Menu fluid secondary vertical>
              <Menu.Item
                name="sticker"
                active={stickerActiveItem === 'sticker'}
                onClick={this.handleItemClick}
              >
                전체 스티커
              </Menu.Item>
              <Menu.Item
                name="challenge"
                active={stickerActiveItem === 'challenge'}
                onClick={this.handleItemClick}
              >
                챌린지 별 스티커 (종료되지 않은 챌린지 기준)
              </Menu.Item>
              <Menu.Item
                name="company"
                active={stickerActiveItem === 'company'}
                onClick={this.handleItemClick}
              >
                회사별 스티커
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column width={13}>
            <Button
              size="mini"
              floated="right"
              onClick={this.showStickerAddModal}
            >
              추가
            </Button>
            {stickerActiveItem === 'challenge' && (
              <StickerChallengeTable
                column={column}
                direction={direction}
                stickerChallenges={challenges}
                handleSort={this.handleSortChallenges}
              />
            )}
            {stickerActiveItem === 'company' && (
              <StickerCompanyTable
                column={column}
                direction={direction}
                stickerCompanies={companies}
                handleSort={this.handleSortCompanies}
              />
            )}
            {stickerActiveItem === 'sticker' && (
              <ChallengeStickerTable
                column={column}
                direction={direction}
                challengeStickers={challengeStickers}
                handleSort={this.handleSort}
                showStickerEditModal={this.showStickerEditModal}
                showStickerDeleteModal={this.showStickerDeleteModal}
              />
            )}
          </Grid.Column>
        </Grid>

        <Modal
          size="small"
          open={addStickerModalOpen}
          onClose={this.closeStickerAddModal}
        >
          <Modal.Header>스티커 추가</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>챌린지</label>
                <Dropdown
                  placeholder="챌린지를 선택하세요."
                  fluid
                  search
                  selection
                  name="challengeId"
                  options={challengeOptions}
                  defaultValue={challengeId}
                  onChange={this.handleRadioChange}
                />
              </Form.Field>
              <Form.Field>
                <label>회사</label>
                <Dropdown
                  placeholder="회사를 선택하세요."
                  fluid
                  search
                  selection
                  name="companyId"
                  options={companyOptions}
                  defaultValue={companyId}
                  onChange={this.handleRadioChange}
                />
              </Form.Field>
              <Form.Field>
                <label>스티커 이미지</label>
                <input
                  name="imageUrl"
                  type="file"
                  onChange={this.handleChangeSingleFile}
                />
                <Image src={imageUrl} size="medium" />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="추가" onClick={this.addSticker} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeStickerAddModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="small"
          open={editStickerModalOpen}
          onClose={this.closeStickerEditModal}
        >
          <Modal.Header>스티커 수정</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>챌린지</label>
                <Dropdown
                  placeholder="챌린지를 선택하세요."
                  fluid
                  search
                  selection
                  name="challengeId"
                  options={challengeOptions}
                  defaultValue={challengeSticker.challengeId}
                  onChange={this.handleStickerRadioChangeEdit}
                />
              </Form.Field>
              <Form.Field>
                <label>회사</label>
                <Dropdown
                  placeholder="회사를 선택하세요."
                  fluid
                  search
                  selection
                  name="companyId"
                  options={companyOptions}
                  value={challengeSticker.companyId}
                  onChange={this.handleStickerRadioChangeEdit}
                />
              </Form.Field>
              <Form.Field>
                <label>스티커 이미지</label>
                <input
                  name="imageUrl"
                  type="file"
                  onChange={this.handleStickerChangeSingleFile}
                />
                <Image src={challengeSticker.imageUrl} size="medium" />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="변경" onClick={this.editSticker} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeStickerEditModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={deleteStickerModalOpen}
          onClose={this.closeStickerDeleteModal}
        >
          <Modal.Header>스티커 삭제</Modal.Header>
          <Modal.Content>
            <Image size="tiny" src={challengeSticker.imageUrl} />
            <p>
              {challengeSticker.challengeTitle} / {challengeSticker.companyName}
            </p>
            <p>위 스티커를 삭제하시겠습니까?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="삭제" onClick={this.deleteSticker} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeStickerDeleteModal}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

export default ChallengeSticker;
