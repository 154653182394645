import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Menu,
  Button,
  Checkbox,
  Divider,
  Image,
  Table,
} from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';
import RaceMenu from '../RaceMenu';
import { apis } from '../../../api';
import { Benefit } from '../../../types';
import { uploadImageFile } from '../../../utils/uploadImage';
import * as S from './Style';
import { downloadUrl } from '../../../utils/js.utils';
import {
  OfferWallForAdmin,
  OfferWallParticipation,
  OfferWallWithUserFilterForAdmin,
} from 'src/types/offerwall.types';

const RaceOfferWallAchievements: React.FC = () => {
  const [beforeOfferWalls, setBeforeOfferWalls] = useState<OfferWallForAdmin[]>(
    [],
  );
  const [afterOfferWalls, setAfterOfferWalls] = useState<OfferWallForAdmin[]>(
    [],
  );
  const [currentOfferWall, setCurrentOfferWall] =
    useState<OfferWallForAdmin | null>(null);
  const [achievementList, setAchievementList] = useState<
    OfferWallParticipation[]
  >([]);
  const [bestAchievementList, setBestAchievementList] = useState<
    (OfferWallParticipation | null)[]
  >([]);
  const [filteredList, setFilteredList] = useState<OfferWallParticipation[]>(
    [],
  );
  const [isOnlyFail, setIsOnlyFail] = useState(false);

  const getOfferWallList = async () => {
    const res = await apis.getOfferWalls('priority');
    const { offerWallInfoList } = res;

    const raceInstagramMissionOfferWallInfoList = offerWallInfoList.filter(
      (o: OfferWallWithUserFilterForAdmin) =>
        o.type === 'RACE_INSTAGRAM_MISSION',
    );

    const beforeOfferWallList = raceInstagramMissionOfferWallInfoList.filter(
      (offerWall: OfferWallWithUserFilterForAdmin) =>
        moment().isBetween(
          moment(offerWall.startDateTime),
          moment(offerWall.resultDate),
        ),
    );

    const afterOfferWallList = raceInstagramMissionOfferWallInfoList.filter(
      (offerWall: OfferWallWithUserFilterForAdmin) =>
        moment().isAfter(moment(offerWall.resultDate)),
    );

    setBeforeOfferWalls(beforeOfferWallList);
    setAfterOfferWalls(afterOfferWallList);
  };

  const getAchievementList = useCallback(
    async (offerWall: OfferWallForAdmin) => {
      if (!currentOfferWall) return;

      const res = await apis.getOfferWallAchievementsById(offerWall.id);
      setAchievementList(res.offerWallParticipationList);
    },
    [currentOfferWall],
  );

  const handleChangeSuccess = async (achievement: OfferWallParticipation) => {
    // 실패 처리 취소 (원복)
    if (!achievement.isSucceed) {
      if (
        window.confirm(
          '실패 처리를 취소하시겠어요? 실패 처리를 취소하시면 유저가 미션을 제대로 수행한것으로 처리하여 유저에게 포인트를 정상적으로 지급합니다.',
        )
      ) {
        const { success } = await apis.passOfferWallAchievement(
          achievement.id,
          true,
        );

        if (success) {
          alert(`인증 처리 되었어요`);
          const newAchievementList = achievementList.map((o) => {
            if (o.id === achievement.id) return { ...o, isSucceed: true };
            return { ...o };
          });
          setAchievementList(newAchievementList);
        }
      }
      return;
    }

    // 실패 처리
    if (
      window.confirm(
        '이 인증샷을 실패 처리하시겠어요? 실패 처리 시 유저에게 재인증 유도 푸시가 즉시 발송됩니다.',
      )
    ) {
      const { success } = await apis.passOfferWallAchievement(
        achievement.id,
        false,
      );
      if (success) {
        alert(`실패 처리 되었어요`);
        // 리스트 업데이트
        const newAchievementList = achievementList.map((o) => {
          if (o.id === achievement.id) return { ...o, isSucceed: false };
          return { ...o };
        });
        setAchievementList(newAchievementList);
      }
    }
  };

  const handleSelectBest = async (achievement: OfferWallParticipation) => {
    if (!currentOfferWall?.bestParticipantCount) {
      alert('해당 오퍼월은 우수 참여자 수가 0으로 설정되어있어요! 😇');
      return;
    }
    if (bestAchievementList.length >= currentOfferWall.bestParticipantCount) {
      alert(
        `오퍼월 우수 참여자는 ${currentOfferWall.bestParticipantCount}명 이상 선정하지 못해요! 😇`,
      );
      return;
    }
    // 우수 참여자 선정
    if (window.confirm('우수 참여자로 선정하시겠어요?')) {
      const { success } = await apis.selectBestOfferWallAchievement(
        achievement.id,
        true,
      );
      if (success) {
        alert(`우수 참여자 선정 완료!`);
      }
      if (currentOfferWall) getAchievementList(currentOfferWall);
    }
  };

  const handleDeleteBest = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    achievement: OfferWallParticipation,
  ) => {
    e.preventDefault();
    // 우수 참여자 삭제
    if (window.confirm('우수 참여자 선정을 취소하시겠어요?')) {
      const { success } = await apis.selectBestOfferWallAchievement(
        achievement.id,
        false,
      );
      if (success) {
        alert('취소 완료!');
        setBestAchievementList((prev) =>
          prev.map((p) => {
            if (p?.id === achievement.id) {
              return null;
            }
            return p;
          }),
        );
      }

      if (currentOfferWall) getAchievementList(currentOfferWall);
    }
  };

  const handleFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    if (!e.target.files) return;

    const url = await uploadImageFile(e.target.files[0]);
    if (window.confirm('인증샷을 진짜 변경하시겠어요?')) {
      const { success } = await apis.updateOfferWallImageAchievement(id, [url]);
      if (success) {
        setFilteredList((prev) =>
          prev.map((p) => (p.id === id ? { ...p, imageUrls: [url] } : p)),
        );
      }
    }
  };

  const handleOfferWallImageDownloadClick = async () => {
    if (!currentOfferWall?.id) return;
    const { url } = await apis.downloadOfferWallImage(currentOfferWall.id);
    downloadUrl(url, `(${currentOfferWall.id})${currentOfferWall.title}`);
  };

  useEffect(() => {
    getOfferWallList();
  }, []);

  useEffect(() => {
    if (!currentOfferWall) return;
    if (isOnlyFail) {
      setFilteredList(achievementList.filter((a) => !a.isSucceed));
    } else {
      setFilteredList(achievementList);
    }

    // 우수 참여자 인증샷 로직
    if (!currentOfferWall.bestParticipantCount) return;
    const filteredAchievementList: (OfferWallParticipation | null)[] =
      achievementList.filter((a) => a.isBest);

    const bestRemainCount =
      currentOfferWall.bestParticipantCount - filteredAchievementList.length;
    if (bestRemainCount > 0) {
      const nullArray = Array.from({ length: bestRemainCount }, () => null);
      filteredAchievementList.push(...nullArray);
    }

    setBestAchievementList(filteredAchievementList);
  }, [achievementList, currentOfferWall, isOnlyFail]);

  useEffect(() => {
    if (!currentOfferWall) {
      setAchievementList([]);
      return;
    }
    getAchievementList(currentOfferWall);
  }, [currentOfferWall, getAchievementList]);

  useEffect(() => {
    // 검토해야하는 오퍼월 중 가장 첫 오퍼월을 디폴트로
    setCurrentOfferWall([...beforeOfferWalls, ...afterOfferWalls][0]);
  }, [beforeOfferWalls, afterOfferWalls]);

  return (
    <>
      <Grid columns="equal" style={{ margin: 50 }}>
        <Grid.Column width={2}>
          <RaceMenu />
        </Grid.Column>
        <Grid.Column width={4}>
          <Menu.Header as="h3">오퍼월 인증샷</Menu.Header>
          <Menu vertical fluid>
            <Menu.Item>
              <Menu.Header>검토해야하는 오퍼월</Menu.Header>
              <Menu.Menu>
                {beforeOfferWalls.length > 0 ? (
                  <>
                    {beforeOfferWalls.map((offerWall) => (
                      <Menu.Item
                        key={offerWall.id}
                        name={offerWall.title}
                        value={offerWall.id}
                        active={offerWall.id === currentOfferWall?.id}
                        onClick={() => setCurrentOfferWall(offerWall)}
                      >
                        {`[${moment(offerWall.resultDate).format(
                          'YYYY.MM.DD',
                        )}] [${offerWall.id}] ${offerWall.brand}-${
                          offerWall.title
                        }`}
                      </Menu.Item>
                    ))}
                  </>
                ) : (
                  <Menu.Item active={false}>없음</Menu.Item>
                )}
              </Menu.Menu>
            </Menu.Item>

            <Menu.Item>
              <Menu.Header>종료된 오퍼월</Menu.Header>
              <Menu.Menu>
                {afterOfferWalls.length > 0 ? (
                  <>
                    {afterOfferWalls.map((offerWall) => (
                      <Menu.Item
                        key={offerWall.id}
                        name={offerWall.title}
                        value={offerWall.id}
                        active={offerWall.id === currentOfferWall?.id}
                        onClick={() => setCurrentOfferWall(offerWall)}
                      >
                        {`[${moment(offerWall.resultDate).format(
                          'YYYY.MM.DD',
                        )}] [${offerWall.id}] ${offerWall.brand}-${
                          offerWall.title
                        }`}
                      </Menu.Item>
                    ))}
                  </>
                ) : (
                  <Menu.Item active={false}>없음</Menu.Item>
                )}
              </Menu.Menu>
            </Menu.Item>
          </Menu>
        </Grid.Column>
        {currentOfferWall && (
          <Grid.Column>
            <>
              {/* 헤더 */}
              <S.OfferWallHeader>
                <h2>{`(${currentOfferWall.id})${currentOfferWall.brand}-${currentOfferWall.title}`}</h2>
                <Button
                  size="small"
                  color="blue"
                  onClick={handleOfferWallImageDownloadClick}
                  content="오퍼월 이미지 다운로드"
                />
              </S.OfferWallHeader>

              <Divider />

              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>전체</Table.HeaderCell>
                    <Table.HeaderCell>성공</Table.HeaderCell>
                    <Table.HeaderCell>실패</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{achievementList.length}</Table.Cell>
                    <Table.Cell>
                      {achievementList.filter((a) => a.isSucceed).length}
                    </Table.Cell>
                    <Table.Cell>
                      {achievementList.filter((a) => !a.isSucceed).length}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <div style={{ marginTop: 40, marginBottom: 20 }}>
                {/* 실패 처리만 모아보기 필터링 */}
                <Grid.Column>
                  <Checkbox
                    name="isOnlyFail"
                    checked={isOnlyFail}
                    label="실패 처리 완료만 모아보기"
                    onChange={() => {
                      setIsOnlyFail(!isOnlyFail);
                    }}
                  />
                </Grid.Column>
              </div>
            </>

            {/* 우수 참여자 */}
            {!!currentOfferWall.bestParticipantCount && (
              <>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <h3>
                    우수 참여자
                    {currentOfferWall && (
                      <span style={{ marginLeft: 10 }}>
                        {bestAchievementList.filter((a) => a?.isBest).length} /{' '}
                        {currentOfferWall.bestParticipantCount}
                      </span>
                    )}
                  </h3>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 12,
                    marginTop: 12,
                    flexWrap: 'wrap',
                  }}
                >
                  {bestAchievementList.map((achievement, index) =>
                    achievement ? (
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        key={achievement.id}
                      >
                        <div
                          key={achievement.id}
                          style={{
                            width: 80,
                            height: 80,
                            marginBottom: 4,
                          }}
                        >
                          <a
                            href={achievement.imageUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              alt="achievement"
                              src={achievement.imageUrl}
                              style={{
                                width: 80,
                                height: 80,
                                objectFit: 'cover',
                              }}
                            />
                          </a>
                        </div>
                        <Button
                          size="small"
                          onClick={(e) => handleDeleteBest(e, achievement)}
                          content="🗑️ 삭제"
                        />
                      </div>
                    ) : (
                      <div
                        key={index}
                        style={{
                          backgroundColor: '#F5F5F5',
                          width: 80,
                          height: 80,
                        }}
                      />
                    ),
                  )}
                </div>
              </>
            )}

            {/* 전체 참여자 인증샷 */}
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                marginBottom: 20,
              }}
            >
              <h3>
                전체 참여자 인증샷
                <span style={{ marginLeft: 8 }}>
                  (현재 {filteredList.length}개)
                </span>
              </h3>
            </div>

            {filteredList.length === 0 ? (
              <h4>없음</h4>
            ) : (
              <>
                <Grid>
                  <Grid.Row columns={3}>
                    {filteredList.map((achievement) => {
                      return (
                        <Grid.Column key={achievement.id}>
                          <Card key={achievement.id} fluid>
                            <a
                              href={achievement.imageUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image
                                alt="achievement url"
                                src={achievement.imageUrl}
                                size="large"
                                centered
                              />
                            </a>
                            <Card.Content extra>
                              <h5>
                                <a
                                  style={{
                                    color: 'black',
                                  }}
                                  href={`/users/${achievement.user.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  [{achievement.user.id}]{' '}
                                  {achievement.user.nickname}
                                </a>
                              </h5>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 12,
                                }}
                              >
                                <Button
                                  color={achievement.isBest ? 'grey' : 'blue'}
                                  onClick={(e) =>
                                    achievement.isBest
                                      ? handleDeleteBest(e, achievement)
                                      : handleSelectBest(achievement)
                                  }
                                  content={
                                    achievement.isBest
                                      ? '우수 참여자 선정 취소'
                                      : '🏆 우수 참여자 선정 🏆'
                                  }
                                />
                                <Button
                                  color={
                                    achievement.isSucceed ? 'black' : 'red'
                                  }
                                  disabled={
                                    !!afterOfferWalls.find(
                                      (offerWall) =>
                                        offerWall.id ===
                                        achievement.offerWallId,
                                    )
                                  }
                                  onClick={() =>
                                    handleChangeSuccess(achievement)
                                  }
                                  content={
                                    achievement.isSucceed
                                      ? '❌ 실패 처리 ❌'
                                      : '실패 처리 완료'
                                  }
                                />

                                <button
                                  type="button"
                                  style={{
                                    cursor: 'pointor',
                                    padding: 10,
                                  }}
                                >
                                  <label
                                    className="btn-file-input"
                                    style={{
                                      fontWeight: 700,
                                    }}
                                  >
                                    🔄 인증샷 변경하기 🔄
                                    <input
                                      type="file"
                                      style={{
                                        display: 'none',
                                      }}
                                      onChange={(e) =>
                                        handleFileUpload(e, achievement.id)
                                      }
                                    />
                                  </label>
                                </button>
                              </div>
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                      );
                    })}
                  </Grid.Row>
                </Grid>
              </>
            )}
          </Grid.Column>
        )}
      </Grid>
    </>
  );
};

export const Row = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default RaceOfferWallAchievements;
