import { ApiType } from '@constant/apiType';
import { _request } from './factory';
import { ReviewTemplate } from '@container/Achievement/Achievement.types';
import { AxiosError } from 'axios';

type GetReviewTemplateResponse = {
  reviewTemplates: ReviewTemplate[];
};

export const getReviewTemplates =
  async (): Promise<GetReviewTemplateResponse> => {
    return _request({
      apiType: ApiType.V3,
      method: 'GET',
      url: `/compatible/v2/admin/review-templates`,
    });
  };

type CreateReviewTemplateRequest = {
  title: string;
  message: string;
};

type CreateReviewTemplateResponse = CreateReviewTemplateRequest & {
  id: number;
};

export const createReviewTemplate = async (
  data: CreateReviewTemplateRequest,
  options?: { onSuccess?: (data: unknown) => void },
): Promise<CreateReviewTemplateResponse> => {
  return _request({
    apiType: ApiType.V3,
    method: 'POST',
    url: `/compatible/v2/admin/review-templates`,
    data,
    ...options,
  });
};

type UpdateReviewTemplateRequest = {
  id: number;
  title: string;
  message: string;
};

export const updateReviewTemplate = async (
  { id, ...data }: UpdateReviewTemplateRequest,
  options?: { onSuccess?: (data: unknown) => void },
) => {
  return _request({
    apiType: ApiType.V3,
    method: 'PUT',
    url: `/compatible/v2/admin/review-templates/${id}`,
    data,
    ...options,
  });
};

export const deleteReviewTemplate = async (
  templateId: number,
  options?: {
    onSuccess?: (data: unknown) => void;
    onError?: (error: AxiosError) => void;
  },
) => {
  return _request({
    apiType: ApiType.V3,
    method: 'DELETE',
    url: `/compatible/v2/admin/review-templates/${templateId}`,
    ...options,
  });
};

type ChangeOrderReviewTemplateRequest = {
  orders: number[];
};

export const changeOrderReviewTemplate = async (
  data: ChangeOrderReviewTemplateRequest,
  options?: {
    onSuccess?: (data: unknown) => void;
    onError?: (error: AxiosError) => void;
  },
) => {
  return _request({
    apiType: ApiType.V3,
    method: 'PATCH',
    url: `/compatible/v2/admin/review-templates/order`,
    data,
    ...options,
  });
};

export default {
  getReviewTemplates,
  createReviewTemplate,
  updateReviewTemplate,
  deleteReviewTemplate,
  changeOrderReviewTemplate,
};
