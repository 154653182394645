import React, { Component } from 'react';
import {
  Container,
  Header,
  Grid,
  Table,
  Divider,
  Button,
  Modal,
  Form,
  Input,
  Checkbox,
  Image,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import Workbook from 'react-excel-workbook';
import api, { apis } from '../../../api';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import { uploadImageFile } from '../../../utils/uploadImage';
import ValidationRequestModal from '../../ValidationRequestModal';

class CompanyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {},
      editCompanyModalOpen: false,
      addProductModalOpen: false,
      editProductModalOpen: false,
      deleteProductModalOpen: false,
      product: {},
      transactionProducts: [],
      column: '',
      direction: null,
      checkedAll: false,
      checkedIds: [],
      productIds: [],
      validated: false,
    };
    this.input = {
      companyName: '',
      description: '',
      homepageUrl: '',
      companyId: '',
      productName: '',
      price: '',
      fee: '',
      shippingPrice: '',
    };
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    this.getCompanyProducts();
    api
      .get('/transaction-products', {
        params: { companyId: this.props.match.params.id },
      })
      .then((response) => {
        const transactionProducts = response.data.data.transactionProducts;
        this.setState({ transactionProducts });
      });
  }

  getCompanyProducts = () => {
    api
      .get(`/admin/companies/${this.props.match.params.id}/products`)
      .then((response) => {
        const company = response.data.data.company;
        this.setProductFormat(company);
      });
  };

  editProduct = async () => {
    const { company, product } = this.state;

    try {
      const { product: newProduct } = await apis.updateProduct(product.id, {
        name: product.name,
        price: product.price,
        fee: product.fee,
        shippingPrice: product.shippingPrice,
        companyId: product.companyId,
        isAddressNeeded: product.isAddressNeeded === 'true',
      });
      if (newProduct) {
        company.products = _.map(company.products, (p) => {
          if (p.id === product.id) {
            p.name = newProduct.name;
            p.price = newProduct.price;
            p.fee = newProduct.fee;
            p.shippingPrice = newProduct.shippingPrice;
            p.isAddressNeeded =
              newProduct.isAddressNeeded === true ? 'true' : 'false';
          }
          return p;
        });
        this.setState({ company });
        this.closeEditProductModal();
      }
    } catch (e) {
      console.log(e);
      alert('[' + e.response.status + '] ' + e.response.data.userMessage);
    }
  };
  showEditCompanyModal = () => this.setState({ editCompanyModalOpen: true });
  closeEditCompanyModal = () => {
    this.setState({ editCompanyModalOpen: false });
    this.input.companyName = '';
    this.input.description = '';
    this.input.homepageUrl = '';
  };

  showAddProductModal = () => this.setState({ addProductModalOpen: true });
  closeAddProductModal = () => {
    this.setState({ addProductModalOpen: false });
    this.input.productName = '';
    this.input.price = '';
    this.input.fee = '';
    this.input.shippingPrice = '';
  };

  showEditProductModal = (e, { value }) =>
    this.setState({ editProductModalOpen: true, product: value });
  closeEditProductModal = () => {
    this.setState({ editProductModalOpen: false });
    this.input.productName = '';
    this.input.price = '';
    this.input.fee = '';
    this.input.shippingPrice = '';
    this.input.companyId = '';
  };

  showDeleteProductModal = (e, { value }) =>
    this.setState({ deleteProductModalOpen: true, product: value });
  closeDeleteProductModal = () =>
    this.setState({ deleteProductModalOpen: false });

  handleInput = (e) => (this.input[e.target.name] = e.target.value);
  handleRadioChange = (e, { name, value }) => (this.input[name] = value);

  handleCompanyInput = (e) => {
    const { company } = this.state;
    company[e.target.name] = e.target.value;
    this.setState({ company });
  };
  handleChangeSingleFile = async (e) => {
    const { company } = this.state;

    if (!e.target.files) return;

    const url = await uploadImageFile(e.taget.files[0], {});
    company.logoImageUrl = url;
    this.setState({ company });
  };
  handleProductInput = (e) => {
    const { product } = this.state;
    console.log(product, e.target.name, e.target.value);
    product[e.target.name] = e.target.value;
    this.setState({ product });
  };
  editCompany = () => {
    const { company } = this.state;
    const { name, description, homepageUrl } = company;
    api
      .put(`/companies/${company.id}`, { name, description, homepageUrl })
      .then((response) => {
        const newCompany = response.data.data.company;
        company.name = newCompany.name;
        company.description = newCompany.description;
        company.homepageUrl = newCompany.homepageUrl;
        this.setState({ company });
        this.closeEditCompanyModal();
      })
      .catch((error) =>
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        ),
      );
  };

  addProduct = async () => {
    const { productName, price, fee, shippingPrice, isAddressNeeded } =
      this.input;
    const { company } = this.state;
    try {
      const { product } = await apis.addProduct({
        companyId: Number(company.id),
        name: productName,
        price: Number(price),
        fee: Number(fee),
        shippingPrice: Number(shippingPrice),
        isAddressNeeded: isAddressNeeded === 'true',
      });
      if (product) {
        product.isAddressNeeded =
          product.isAddressNeeded === true ? 'true' : 'false';
        product['challenges'] = [];
        company.products.push(product);
        this.setState({ company });
        this.closeAddProductModal();
      }
    } catch (e) {
      console.log(e);
      alert('[' + e.response.status + '] ' + e.response.data.userMessage);
    }
  };

  deleteProduct = async () => {
    const { company, product } = this.state;
    try {
      const { ok } = await apis.deleteProduct(product.id);
      if (ok) {
        alert('삭제가 완료되었습니다.');
        company.products = _.filter(
          company.products,
          (p) => p.id !== product.id,
        );
        this.setState({ company, deleteProductModalOpen: false });
      }
    } catch (e) {
      alert('[' + e.response.status + '] ' + e.response.data.userMessage);
    }
  };

  handleProductCheckbox = async (e, { value }) => {
    const { company, checkedIds, checkedAll } = this.state;
    if (value === 'all') {
      await this.setState({ checkedIds: [] });
      if (checkedAll) {
        _.map(company.products, (c) => (c.isChecked = !checkedAll));
        await this.setState({ checkedIds: [], checkedAll: !checkedAll });
        console.log(this.state.checkedIds);
      } else {
        _.map(company.products, (c) => {
          c.isChecked = !checkedAll;
          checkedIds.push(c.id);
        });
        await this.setState({ checkedIds, checkedAll: !checkedAll });
        console.log(this.state.checkedIds);
      }
      return;
    }
    const product = _.find(company.products, (c) => c.id === value);
    product.isChecked = product.isChecked ? !product.isChecked : true;
    if (product.isChecked && !checkedIds.includes(value))
      checkedIds.push(value);
    else if (!product.isChecked && checkedIds.includes(value)) {
      const index = checkedIds.indexOf(value);
      checkedIds.splice(index, 1);
    }
    this.setState({ company });
  };

  setProductFormat = (company) => {
    let productIds = [];
    _.map(company.products, (p) => {
      p.isAddressNeeded = p.isAddressNeeded === true ? 'true' : 'false';
      p.isChecked = false;
      if (p.challenges.length > 0) productIds.push(p.id);
    });
    this.setState({ company, productIds });
  };

  handleSort = (clickedColumn) => () => {
    const { column, transactionProducts, direction } = this.state;
    if (column !== clickedColumn) {
      const newTransactionProducts = _.sortBy(transactionProducts, [
        clickedColumn,
      ]);
      this.setState({
        column: clickedColumn,
        transactionProducts: newTransactionProducts,
        direction: 'ascending',
      });
      return;
    }

    this.setState({
      transactionProducts: transactionProducts.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  showValidationModal = () => this.setState({ validationModalOpen: true });
  closeValidationModal = () => this.setState({ validationModalOpen: false });
  setValidated = (validated) => {
    this.setState({ validated }, () => {
      this.buttonRef.current.ref.current.click();
      this.closeValidationModal();
    });
  };

  render() {
    const {
      company,
      addProductModalOpen,
      deleteProductModalOpen,
      product,
      editCompanyModalOpen,
      editProductModalOpen,
      transactionProducts,
      column,
      direction,
      checkedAll,
      validated,
    } = this.state;
    return (
      <Container>
        <Header>회사 및 상품 정보</Header>
        <Divider hidden />
        <Grid columns="equal">
          <Grid.Column width={4}>
            <Header as="h4">
              회사 정보
              <Button
                size="tiny"
                style={styles.button}
                onClick={this.showEditCompanyModal}
                content="수정"
              />
            </Header>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>id</Table.HeaderCell>
                  <Table.HeaderCell>회사명</Table.HeaderCell>
                  <Table.HeaderCell>산업</Table.HeaderCell>
                  <Table.HeaderCell>로고</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{company.id}</Table.Cell>
                  <Table.Cell>{company.name}</Table.Cell>
                  <Table.Cell>{company.description}</Table.Cell>
                  <Table.Cell>{company.homepageUrl}</Table.Cell>
                  <Table.Cell>
                    {!!company.logoImageUrl ? (
                      <Image src={company.logoImageUrl} size="mini" />
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>

          <Grid.Column>
            <Header as="h4">
              상품 정보
              <Button
                size="tiny"
                style={styles.button}
                onClick={this.showAddProductModal}
                content="상품 추가"
              />
            </Header>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  {/* <Table.HeaderCell>
                    <Checkbox
                      name="all"
                      checked={checkedAll}
                      value="all"
                      onChange={this.handleProductCheckbox}
                    />
                  </Table.HeaderCell> */}
                  <Table.HeaderCell>id</Table.HeaderCell>
                  <Table.HeaderCell>상품명</Table.HeaderCell>
                  <Table.HeaderCell>가격</Table.HeaderCell>
                  <Table.HeaderCell>수수료</Table.HeaderCell>
                  <Table.HeaderCell>배송비</Table.HeaderCell>
                  <Table.HeaderCell>배송 필요여부</Table.HeaderCell>
                  <Table.HeaderCell>판매중인 챌린지</Table.HeaderCell>
                  <Table.HeaderCell>수정/삭제</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(company.products, (p, idx) => (
                  <Table.Row key={idx}>
                    {/* <Table.Cell collapsing>
                      <Checkbox
                        checked={p.isChecked}
                        value={p.id}
                        onChange={this.handleProductCheckbox}
                      />
                    </Table.Cell> */}
                    <Table.Cell>{p.id}</Table.Cell>
                    <Table.Cell>{p.name}</Table.Cell>
                    <Table.Cell>{p.price}</Table.Cell>
                    <Table.Cell>{p.fee}</Table.Cell>
                    <Table.Cell>{p.shippingPrice}</Table.Cell>
                    <Table.Cell>
                      {p.isAddressNeeded === 'true' ? '배송' : '미배송'}
                    </Table.Cell>
                    <Table.Cell>
                      {_.map(p.challenges, (c) => (
                        <span key={c.id}>
                          {c.id} / {c.title}
                          <br />
                        </span>
                      ))}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        size="tiny"
                        value={p}
                        disabled={p.challenges.length > 0}
                        onClick={this.showEditProductModal}
                        content="수정"
                      />
                      <Button
                        size="tiny"
                        value={p}
                        disabled={p.challenges.length > 0}
                        onClick={this.showDeleteProductModal}
                        content="삭제"
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>

          <Modal
            size="small"
            open={editCompanyModalOpen}
            onClose={this.closeEditCompanyModal}
          >
            <Modal.Header>회사 정보 수정</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label>회사명</label>
                  <Input
                    size="mini"
                    name="name"
                    placeholder="회사명"
                    value={company.name}
                    onChange={this.handleCompanyInput}
                  />
                </Form.Field>
                <Form.Field>
                  <label>설명내용</label>
                  <Input
                    name="description"
                    placeholder="산업군"
                    value={company.description}
                    onChange={this.handleCompanyInput}
                  />
                </Form.Field>
                <Form.Field>
                  <label>홈페이지 주소</label>
                  <Input
                    name="homepageUrl"
                    placeholder="홈페이지 주소"
                    value={company.homepageUrl}
                    onChange={this.handleCompanyInput}
                  />
                </Form.Field>
                <Form.Field>
                  <label>로고</label>
                  <Input
                    type="file"
                    name="logoImageUrl"
                    onChange={this.handleChangeSingleFile}
                  />
                  {!!company.logoImageUrl && (
                    <Image src={company.logoImageUrl} size="small" />
                  )}
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color="blue" content="변경" onClick={this.editCompany} />
              <Button
                color="black"
                content="닫기"
                onClick={this.closeEditCompanyModal}
              />
            </Modal.Actions>
          </Modal>

          <Modal
            size="small"
            open={addProductModalOpen}
            onClose={this.closeAddProductModal}
          >
            <Modal.Header>상품 추가</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label>회사</label>
                  <p>{company.name}</p>
                </Form.Field>
                <Form.Field>
                  <label>상품명</label>
                  <Input
                    name="productName"
                    placeholder="상품명"
                    onChange={this.handleInput}
                  />
                </Form.Field>
                <Form.Field>
                  <label>가격</label>
                  <Input
                    name="price"
                    placeholder="가격"
                    onChange={this.handleInput}
                  />
                </Form.Field>
                <Form.Field>
                  <label>수수료</label>
                  <Input
                    name="fee"
                    placeholder="수수료"
                    onChange={this.handleInput}
                  />
                </Form.Field>
                <Form.Field>
                  <label>배송료</label>
                  <Input
                    name="shippingPrice"
                    placeholder="배송료"
                    onChange={this.handleInput}
                  />
                </Form.Field>
                <Form.Group grouped>
                  <label>배송필요여부</label>
                  <Form.Field
                    label="배송"
                    control="input"
                    type="radio"
                    name="isAddressNeeded"
                    value="true"
                    onChange={this.handleInput}
                  />
                  <Form.Field
                    label="미배송"
                    control="input"
                    type="radio"
                    name="isAddressNeeded"
                    value="false"
                    onChange={this.handleInput}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color="blue" content="추가" onClick={this.addProduct} />
              <Button
                color="black"
                content="닫기"
                onClick={this.closeAddProductModal}
              />
            </Modal.Actions>
          </Modal>

          <Modal
            size="small"
            open={editProductModalOpen}
            onClose={this.closeEditProductModal}
          >
            <Modal.Header>상품 변경</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <label>상품명</label>
                  <Input
                    name="name"
                    placeholder="상품명"
                    value={product.name}
                    onChange={this.handleProductInput}
                  />
                </Form.Field>
                <Form.Field>
                  <label>가격</label>
                  <Input
                    name="price"
                    placeholder="가격"
                    value={product.price}
                    onChange={this.handleProductInput}
                  />
                </Form.Field>
                <Form.Field>
                  <label>수수료</label>
                  <Input
                    name="fee"
                    placeholder="수수료"
                    value={product.fee}
                    onChange={this.handleProductInput}
                  />
                </Form.Field>
                <Form.Field>
                  <label>배송료</label>
                  <Input
                    name="shippingPrice"
                    placeholder="배송료"
                    value={product.shippingPrice}
                    onChange={this.handleProductInput}
                  />
                </Form.Field>
                <Form.Group grouped>
                  <label>배송필요여부</label>
                  <Form.Field
                    label="배송"
                    control="input"
                    type="radio"
                    name="isAddressNeeded"
                    value="true"
                    checked={product.isAddressNeeded === 'true'}
                    onChange={this.handleProductInput}
                  />
                  <Form.Field
                    label="미배송"
                    control="input"
                    type="radio"
                    name="isAddressNeeded"
                    value="false"
                    checked={product.isAddressNeeded === 'false'}
                    onChange={this.handleProductInput}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color="blue" content="변경" onClick={this.editProduct} />
              <Button
                color="black"
                content="닫기"
                onClick={this.closeEditProductModal}
              />
            </Modal.Actions>
          </Modal>

          <Modal
            size="small"
            open={deleteProductModalOpen}
            onClose={this.closeDeleteProductModal}
          >
            <Modal.Header>상품 삭제</Modal.Header>
            <Modal.Content>
              <p>선택하신 상품 [{product.name}]을(를) 삭제하시겠습니까?</p>
              <p>
                만약 해당 상품이 판매된 이력이 있다면 삭제를 하실 수 없습니다.
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="blue"
                content="삭제"
                onClick={this.deleteProduct}
              />
              <Button
                color="black"
                content="닫기"
                onClick={this.closeDeleteProductModal}
              />
            </Modal.Actions>
          </Modal>
        </Grid>

        <Grid>
          <Header as="h4">
            판매 정보
            {validated ? (
              <Workbook
                filename={`${moment().format('YYYYMMDD')}_${
                  company.name
                }_판매정보.xlsx`}
                element={
                  <Button
                    ref={this.buttonRef}
                    basic
                    color="green"
                    floated="right"
                    size="mini"
                  >
                    Excel
                  </Button>
                }
              >
                <Workbook.Sheet data={transactionProducts} name="판매정보">
                  <Workbook.Column label="구매id" value={(tp) => tp.id} />
                  <Workbook.Column
                    label="구매시점"
                    value={(tp) =>
                      moment(tp.createdAt)
                        .add(9, 'h')
                        .format('YYYY-MM-DD HH:mm:ss')
                    }
                  />
                  <Workbook.Column
                    label="챌린지id"
                    value={(tp) => tp.challenge.id}
                  />
                  <Workbook.Column
                    label="챌린지명"
                    value={(tp) => tp.challenge.title}
                  />
                  <Workbook.Column label="유저id" value={(tp) => tp.user.id} />
                  <Workbook.Column
                    label="유저닉네임"
                    value={(tp) => tp.user.nickname}
                  />
                  <Workbook.Column
                    label="상품id"
                    value={(tp) => tp.product.id}
                  />
                  <Workbook.Column
                    label="상품명"
                    value={(tp) => tp.product.name}
                  />
                  <Workbook.Column
                    label="상품가격"
                    value={(tp) =>
                      tp.product.price > 0
                        ? setCurrencyFormat(tp.product.price)
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="상품배송비"
                    value={(tp) =>
                      tp.product.shippingPrice > 0
                        ? setCurrencyFormat(tp.product.shippingPrice)
                        : '0'
                    }
                  />
                  <Workbook.Column label="구매수량" value={(tp) => tp.count} />
                  <Workbook.Column
                    label="구매금액(캐시)"
                    value={(tp) =>
                      tp.cashAmount > 0 ? setCurrencyFormat(tp.cashAmount) : '0'
                    }
                  />
                  <Workbook.Column
                    label="구매금액(카드)"
                    value={(tp) =>
                      tp.cardAmount > 0 ? setCurrencyFormat(tp.cardAmount) : '0'
                    }
                  />
                  <Workbook.Column
                    label="구매금액(이체)"
                    value={(tp) =>
                      tp.transferAmount > 0
                        ? setCurrencyFormat(tp.transferAmount)
                        : '0'
                    }
                  />
                </Workbook.Sheet>
              </Workbook>
            ) : (
              <Button
                basic
                color="green"
                floated="right"
                size="mini"
                onClick={this.showValidationModal}
              >
                엑셀 요청하기
              </Button>
            )}
          </Header>
          <Table basic="very" size="small" sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'id' ? direction : null}
                  onClick={this.handleSort('id')}
                >
                  구매id
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'createdAt' ? direction : null}
                  onClick={this.handleSort('createdAt')}
                >
                  구매시점
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'challengeId' ? direction : null}
                  onClick={this.handleSort('challengeId')}
                >
                  챌린지id
                </Table.HeaderCell>
                <Table.HeaderCell>챌린지명</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'userId' ? direction : null}
                  onClick={this.handleSort('userId')}
                >
                  유저id
                </Table.HeaderCell>
                <Table.HeaderCell>유저닉네임</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'productId' ? direction : null}
                  onClick={this.handleSort('productId')}
                >
                  상품id
                </Table.HeaderCell>
                <Table.HeaderCell>상품명</Table.HeaderCell>
                <Table.HeaderCell>상품가격</Table.HeaderCell>
                <Table.HeaderCell>상품배송비</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'count' ? direction : null}
                  onClick={this.handleSort('count')}
                >
                  구매수량
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'cashAmount' ? direction : null}
                  onClick={this.handleSort('cashAmount')}
                >
                  구매금액(캐시)
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'cardAmount' ? direction : null}
                  onClick={this.handleSort('cardAmount')}
                >
                  구매금액(카드)
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'transferAmount' ? direction : null}
                  onClick={this.handleSort('transferAmount')}
                >
                  구매금액(계좌이체)
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(transactionProducts, (tp) => (
                <Table.Row key={tp.id}>
                  <Table.Cell>{tp.id}</Table.Cell>
                  <Table.Cell>
                    {moment(tp.createdAt)
                      .add(9, 'h')
                      .format('YYYY-MM-DD HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{tp.challenge.id}</Table.Cell>
                  <Table.Cell>{tp.challenge.title}</Table.Cell>
                  <Table.Cell>{tp.user.id}</Table.Cell>
                  <Table.Cell>{tp.user.nickname}</Table.Cell>
                  <Table.Cell>{tp.product.id}</Table.Cell>
                  <Table.Cell>{tp.product.name}</Table.Cell>
                  <Table.Cell>{setCurrencyFormat(tp.product.price)}</Table.Cell>
                  <Table.Cell>
                    {setCurrencyFormat(tp.product.shippingPrice)}
                  </Table.Cell>
                  <Table.Cell>{tp.count}</Table.Cell>
                  <Table.Cell>{setCurrencyFormat(tp.cashAmount)}</Table.Cell>
                  <Table.Cell>{setCurrencyFormat(tp.cardAmount)}</Table.Cell>
                  <Table.Cell>
                    {setCurrencyFormat(tp.transferAmount)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <ValidationRequestModal
            validationModalOpen={this.state.validationModalOpen}
            closeValidationModal={this.closeValidationModal}
            setValidated={this.setValidated}
            location={'판매 정보'}
          />
        </Grid>
      </Container>
    );
  }
}

export default CompanyDetail;

const styles = {
  button: {
    marginLeft: 10,
  },
};
