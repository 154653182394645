import React, { Component } from 'react';
import {
  Container,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Image,
} from 'semantic-ui-react';
import _ from 'lodash';
import { createHashHistory } from 'history';
import { Redirect } from 'react-router-dom';
import { apis } from '../../../api';
import { uploadImageFile } from '../../../utils/uploadImage';

class CompanyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      direction: null,
      redirect: false,
      redirectEdit: false,
      isSorted: true,
      isMounted: false,
      companies: [],
      companyId: 0,
      company: {},
      editCompanyModalOpen: false,
      deleteCompanyModalOpen: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { companies: nextProps.companies };
  }

  goCompanyDetail = (companyId) => {
    const history = createHashHistory();
    history.push('/commerces');
    this.setState({ companyId }, () => {
      this.setState({ redirect: true });
    });
  };

  showEditCompanyModal = (e, { value }) =>
    this.setState({ editCompanyModalOpen: true, company: value });

  closeEditCompanyModal = () => {
    this.setState({ editCompanyModalOpen: false });
  };

  handleCompanyInput = (e) => {
    const { company } = this.state;
    company[e.target.name] = e.target.value;
    this.setState({ company });
  };
  handleChangeSingleFile = async (e) => {
    const { company } = this.state;

    if (!e.target.files) return;

    const url = await uploadImageFile(e.target.files[0]);
    company.logoImageUrl = url;
    this.setState({ company });
  };

  editCompany = async () => {
    const { company, companies } = this.state;
    const res = await apis.updateCompany(company.id, {
      name: company.name,
      description: company.description,
      homepageUrl: company.homepageUrl,
      logoImageUrl: company.logoImageUrl,
    });

    const { company: newCompany } = res;
    const newCompanies = _.map(companies, (c) => {
      if (c.id === newCompany) {
        c.name = newCompany.name;
        c.description = newCompany.description;
        c.homepageUrl = newCompany.homepageUrl;
        c.logoImageUrl = newCompany.logoImageUrl;
      }
      return c;
    });
    this.setState({ companies: newCompanies });
    this.closeEditCompanyModal();
  };

  showDeleteCompanyModal = (e, { value }) =>
    this.setState({ deleteCompanyModalOpen: true, company: value });
  closeDeleteCompanyModal = () =>
    this.setState({ deleteCompanyModalOpen: false });

  deleteCompany = async () => {
    const { company } = this.state;

    await apis.deleteCompany(company.id);

    alert('삭제가 완료되었습니다.');
    this.closeDeleteCompanyModal();
    this.props.deleteCompany(company.id);
  };

  render() {
    const {
      companies,
      redirect,
      companyId,
      editCompanyModalOpen,
      company,
      deleteCompanyModalOpen,
    } = this.state;
    const { column, direction, handleSort } = this.props;
    if (redirect)
      return <Redirect to={{ pathname: '/companies/' + companyId }} />;
    return (
      <React.Fragment>
        <Container
          fluid
          style={{ overflowX: 'auto', maxHeight: 700, marginTop: 20 }}
        >
          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>회사명</Table.HeaderCell>
                <Table.HeaderCell>수정/삭제</Table.HeaderCell>
                <Table.HeaderCell>로고</Table.HeaderCell>
                <Table.HeaderCell>설명</Table.HeaderCell>
                <Table.HeaderCell>홈페이지</Table.HeaderCell>
                <Table.HeaderCell>상품군</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(companies, (c) => (
                <Table.Row key={c.id}>
                  <Table.Cell>{c.id}</Table.Cell>
                  <Table.Cell>{c.name}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      size="mini"
                      value={c}
                      onClick={() => this.goCompanyDetail(c.id)}
                      content="상세보기"
                    />
                    <Button
                      size="mini"
                      value={c}
                      onClick={this.showEditCompanyModal}
                      content="수정"
                    />
                    <Button
                      size="mini"
                      disabled={!!c.products?.length || !!c.pills?.length}
                      value={c}
                      onClick={this.showDeleteCompanyModal}
                      content="삭제"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {!!c.logoImageUrl ? (
                      <Image src={c.logoImageUrl} size="mini" />
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell width={4}>{c.description}</Table.Cell>
                  <Table.Cell>{c.homepageUrl}</Table.Cell>
                  <Table.Cell>
                    {_.map(c.products, (p) => (
                      <div key={p.id}>{p.name}</div>
                    ))}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>

        <Modal
          size="small"
          open={editCompanyModalOpen}
          onClose={this.closeEditCompanyModal}
        >
          <Modal.Header>회사 정보 수정</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>회사명</label>
                <Input
                  size="small"
                  name="name"
                  placeholder="회사명"
                  value={company.name}
                  onChange={this.handleCompanyInput}
                />
              </Form.Field>
              <Form.Field>
                <label>설명내용</label>
                <Input
                  name="description"
                  placeholder="산업군"
                  value={company.description}
                  onChange={this.handleCompanyInput}
                />
              </Form.Field>
              <Form.Field>
                <label>홈페이지 주소</label>
                <Input
                  name="homepageUrl"
                  placeholder="홈페이지 주소"
                  value={company.homepageUrl}
                  onChange={this.handleCompanyInput}
                />
              </Form.Field>
              <Form.Field>
                <label>로고</label>
                <Input
                  type="file"
                  name="logoImageUrl"
                  onChange={this.handleChangeSingleFile}
                />
                {!!company.logoImageUrl && (
                  <Image src={company.logoImageUrl} size="small" />
                )}
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="변경" onClick={this.editCompany} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeEditCompanyModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="small"
          open={deleteCompanyModalOpen}
          onClose={this.closeDeleteCompanyModal}
        >
          <Modal.Header>상품 삭제</Modal.Header>
          <Modal.Content>
            <p>선택하신 회사 [{company.name}]을(를) 삭제하시겠습니까?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="삭제" onClick={this.deleteCompany} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeDeleteCompanyModal}
            />
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CompanyTable;
