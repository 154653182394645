import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Image, Menu, Modal } from 'semantic-ui-react';
import { uploadImageFile } from '../../../utils/uploadImage';

const MarketingMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const [pathname, setPathname] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const menus = [
    { text: '배너 (통폐합)', value: '/banner' },
    { text: '추천 컨텐츠', value: '/contents' },
    { text: '이벤트 페이지', value: '/events' },
    { text: '이미지 링크 만들기', value: 'image-link' },
  ];

  const _click = (menu) => {
    if (menu.value === 'image-link') {
      setModalOpen(true);
    } else {
      history.push(menu.value);
    }
  };

  const closeModal = () => setModalOpen(false);

  const _handleChangeSingleFile = async (e) => {
    if (!e.target.files) return;

    const _url = await uploadImageFile(e.target.files[0]);
    setUrl(_url);
  };

  return (
    <>
      <Menu fluid secondary vertical>
        {menus.map((menu) => (
          <Menu.Item
            key={menu.value}
            active={menu.value === pathname}
            onClick={() => _click(menu)}
          >
            {menu.text}
          </Menu.Item>
        ))}
      </Menu>
      <Modal size="small" open={modalOpen} onClose={closeModal}>
        <Modal.Header>이미지 링크 만들기</Modal.Header>
        <Modal.Content>
          <input type="file" name="url" onChange={_handleChangeSingleFile} />
          <Image src={url} size="small" />
          {url}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" content="닫기" onClick={closeModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default MarketingMenu;
