import React from 'react';
import { Image } from 'semantic-ui-react';
import { uploadImageFile } from '@utils/uploadImage';
import { isDefaultRouteOfferWall, isOfferWallInRace } from '../../utils';
import * as S from '../../Style';

const OfferWallImage: React.FC<OfferWallImageProps> = (props) => {
  const {
    offerWallInfo,
    mainImageUrlList,
    onChangeValue,
    onChangeMainImageUrlList,
  } = props;

  const handleThumbnailChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const { name, files } = e.target;

    if (!files) return;

    const url = await uploadImageFile(files[0]);
    onChangeValue({ name, value: url });
  };

  const handleMainImageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    if (!e.target.files) return;

    const url = await uploadImageFile(e.target.files[0]);
    onChangeMainImageUrlList([url]);
  };

  return (
    <>
      <h2>이미지 세팅</h2>
      <S.RowContainer>
        <S.RowFormField>
          <label>리스트 썸네일 (사이즈 250 * 250)</label>
          <input
            name="thumbnailImageUrl"
            type="file"
            onChange={handleThumbnailChange}
          />
          <Image src={offerWallInfo.thumbnailImageUrl} size="medium" />
          {isOfferWallInRace(offerWallInfo.type) && (
            <label style={{ color: 'blue' }}>
              *랜선대회 상세 화면에 노출되는 롤링 배너에도 동일하게 세팅됩니다.
            </label>
          )}
        </S.RowFormField>
        {/* 자체 오퍼월의 경우 메인 이미지 설정 필요 */}
        {isDefaultRouteOfferWall(offerWallInfo.type) && (
          <S.RowFormField margin="0 0 0 10px">
            <label>브릿지 스크린 메인 이미지 (4:3)</label>
            <input
              type="file"
              name="bridge-main-image"
              onChange={handleMainImageChange}
            />
            <Image src={mainImageUrlList[0]} size="medium" />
          </S.RowFormField>
        )}
      </S.RowContainer>
    </>
  );
};

type OfferWallImageProps = {
  onChangeValue: ({ name, value }: { name: string; value: any }) => void;
  onChangeMainImageUrlList: (urls: string[]) => void;
  offerWallInfo: any;
  mainImageUrlList: string[];
};

export default OfferWallImage;
