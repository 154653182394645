import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { cropImage, uploadImageFiles } from './uploadImage';

const dataURItoBlob = (dataURI: string) => {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

export const cropAndUploadImage = async (file: File) => {
  const name = 'temp';
  const urls: string[] = [];
  // 파일 업로드
  const image = document.createElement('img');
  image.src = URL.createObjectURL(file);
  try {
    LoadingIndicator.show();
    await new Promise<void>((resolve) => {
      image.onload = async () => {
        const files = await cropImage(
          image,
          image.width,
          image.height,
          image.width,
        );
        const _urls = await uploadImageFiles(files);
        _urls.forEach((url: string) => urls.push(url));
        resolve();
      };
    });
  } catch (e) {
    console.log(e);
  } finally {
    LoadingIndicator.hide();
  }

  return urls;
};
