import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Grid,
  Form,
  Button,
  Radio,
  TextArea,
  Dropdown,
  Header,
  Image,
  Label,
  Icon,
} from 'semantic-ui-react';
import api, { apis } from '../../../api/index';
import { uploadImageFiles } from '../../../utils/uploadImage';

class BoardEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      board: {
        title: '',
        content: '',
        boardCategoryId: 1,
        imageUrls: [],
        isPublic: '',
        isDeleted: '',
      },
      redirect: false,
      modalOpen: false,
    };
  }

  async componentDidMount() {
    const res = await apis.getBoard(this.props.match.params.id);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { board } = res;
    this.setFormat(board);
    this.setState({ isMounted: true });
  }

  handleChange = (e) => {
    const { board } = this.state;
    board[e.target.name] = e.target.value;
    this.setState({ board });
  };
  handleChangeMultiFile = async (e) => {
    if (!e.target.files) return;

    const urls = await uploadImageFiles(e.target.files);
    this.setState({
      board: {
        ...this.state.board,
        [e.target.name]: urls,
      },
    });
  };

  changeImageOrder = (e, { name, value }) => {
    const { board } = this.state;
    if (name === 'up') {
      const removed = board.imageUrls.splice(value, 1);
      board.imageUrls.splice(value - 1, 0, removed[0]);
    } else {
      const removed = board.imageUrls.splice(value, 1);
      board.imageUrls.splice(value + 1, 0, removed[0]);
    }
    this.setState({ board });
  };

  deleteImage = (e, { name, value }) => {
    const { board } = this.state;
    board[name].splice(value, 1);
    this.setState({ board });
  };
  handleRadioChange = (e, { name, value }) => {
    const board = this.state.board;
    board[name] = value;
    this.setState({ board });
  };

  submit = async () => {
    const { board: _board } = this.state;
    _board.isDeleted = _board.isDeleted === 'true';
    _board.isPublic = _board.isPublic === 'true';

    const res = await apis.updateBoard(_board.id, _board);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { board } = res;
    this.setFormat(board);
    this.setState({ redirect: true });
  };

  setFormat = (board) => {
    board.isDeleted =
      board.isDeleted === false ? 'false' : (board.isDeleted = 'true');
    board.isPublic =
      board.isPublic === false ? 'false' : (board.isPublic = 'true');
    this.setState({ board });
  };

  modalShow = () => this.setState({ modalOpen: true });
  modalClose = () => this.setState({ modalOpen: false });

  render() {
    const { board } = this.state;
    if (this.state.redirect) {
      return <Redirect to={{ pathname: '/boards/' + board.id }} />;
    }
    return (
      <Container>
        <Grid columns="equal">
          <Grid.Column />
          <Grid.Column width={8}>
            <Header as="h3" textAlign="center">
              게시글 수정
            </Header>
            <Form onSubmit={this.submit}>
              <Form.Field>
                <label>제목</label>
                <input
                  name="title"
                  value={board.title}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>내용</label>
                <TextArea
                  name="content"
                  value={board.content}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>이미지선택</label>
                <input
                  type="file"
                  multiple
                  name="imageUrls"
                  onChange={this.handleChangeMultiFile}
                />
                {board.imageUrls.map((url, idx) => (
                  <div key={idx}>
                    <Label
                      color="black"
                      name="imageUrls"
                      value={idx}
                      onClick={this.deleteImage}
                    >
                      <Icon name="delete" />
                    </Label>
                    <Label
                      name="up"
                      value={idx}
                      onClick={this.changeImageOrder}
                    >
                      <Icon name="angle up" style={{ margin: 0 }} />
                    </Label>
                    <Label
                      name="down"
                      value={idx}
                      onClick={this.changeImageOrder}
                    >
                      <Icon name="angle down" style={{ margin: 0 }} />
                    </Label>
                    <Image src={url} size="small" />
                  </div>
                ))}
              </Form.Field>
              <Form.Group grouped>
                <label>공개여부</label>
                <Form.Field
                  control={Radio}
                  label="공개"
                  value="true"
                  name="isPublic"
                  checked={board.isPublic === 'true'}
                  onChange={this.handleRadioChange}
                />
                <Form.Field
                  control={Radio}
                  label="미공개"
                  value="false"
                  name="isPublic"
                  checked={board.isPublic === 'false'}
                  onChange={this.handleRadioChange}
                />
              </Form.Group>
              <Form.Group grouped>
                <label>삭제여부</label>
                <Form.Field
                  control={Radio}
                  label="삭제"
                  value="true"
                  name="isDeleted"
                  checked={board.isDeleted === 'true'}
                  onChange={this.handleRadioChange}
                />
                <Form.Field
                  control={Radio}
                  label="미삭제"
                  value="false"
                  name="isDeleted"
                  checked={board.isDeleted === 'false'}
                  onChange={this.handleRadioChange}
                />
              </Form.Group>
              <Button type="submit" fluid>
                Submit
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column />
        </Grid>
      </Container>
    );
  }
}

export default BoardEdit;
