import { Form, Image } from 'semantic-ui-react';
import FlexBox from '@component/FlexBox/FlexBox';
import _ from 'lodash';
import { cropAndUploadImageFile, uploadImageFile } from '@utils/uploadImage';
import { isGifFile } from '@utils/node.utils';
import { RaceOriginImageDescriptionModule } from '@container/Race/RaceForm/RaceForm.types';

const FullImageModule = ({
  idx,
  module,
  handleChange,
}: {
  idx: number;
  module: RaceOriginImageDescriptionModule;
  handleChange: any;
}) => {
  const handleMultiImageChange = async (e: {
    target: { name: string; files: FileList | null };
  }) => {
    if (!e.target.files) return;
    const { name, files } = e.target;
    const file = files[0];

    const urls: string[] = isGifFile(file)
      ? [await uploadImageFile(file)]
      : await cropAndUploadImageFile(file);

    handleChange(idx, 'urls', urls);
  };
  return (
    <div>
      <h4>이미지 (gif의 경우, 로드까지 2-3초 이상 걸릴 수 있습니다.)</h4>
      <Form.Field>
        <input type="file" name="url" onChange={handleMultiImageChange} />
        <FlexBox.Row style={{ width: '100%', overflow: 'auto' }}>
          {module.urls.map((url, _idx) => (
            <Image key={`${url}-${_idx}`} size={'small'} src={url} />
          ))}
        </FlexBox.Row>
      </Form.Field>
    </div>
  );
};

export default FullImageModule;
