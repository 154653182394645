import {
  ChallengeCustomField,
  ChallengeViralLoopShareableCustomField,
} from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'viralLoopShareable'>,
  Extract<ChallengeCustomField, { name: 'viralLoopShareable' }>
> = ({ viralLoopShareable }) => {
  if (!viralLoopShareable) return [];
  return [{ name: 'viralLoopShareable', values: viralLoopShareable }];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'viralLoopShareable'>
> = (customFields) => {
  const viralLoopShareable = customFields.find(
    ({ name }) => name === 'viralLoopShareable',
  );

  if (!viralLoopShareable) return {} as EmptyObj;

  const { values } =
    viralLoopShareable as ChallengeViralLoopShareableCustomField;

  return { viralLoopShareable: values };
};
