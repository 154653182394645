import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Grid,
  Form,
  Button,
  Radio,
  TextArea,
  Header,
  Image,
  Loader,
  Label,
  Icon,
} from 'semantic-ui-react';
import api, { apis } from '../../../api/index';
import { uploadImageFiles } from '../../../utils/uploadImage';

class BoardCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      board: {
        title: '',
        content: '',
        boardCategoryId: 1,
        imageUrls: [],
        isPublic: 'true',
      },
      loading: false,
      redirect: false,
    };
  }

  handleChange = (e) => {
    const board = this.state.board;
    board[e.target.name] = e.target.value;
    this.setState({ board });
  };
  handleChangeMultiFile = async (e) => {
    if (!e.target.files) return;

    const urls = await uploadImageFiles(e.target.files);
    this.setState({
      board: {
        ...this.state.board,
        [e.target.name]: urls,
      },
    });
  };
  changeImageOrder = (e, { name, value }) => {
    const { board } = this.state;
    if (name === 'up') {
      const removed = board.imageUrls.splice(value, 1);
      board.imageUrls.splice(value - 1, 0, removed[0]);
    } else {
      const removed = board.imageUrls.splice(value, 1);
      board.imageUrls.splice(value + 1, 0, removed[0]);
    }
    this.setState({ board });
  };
  deleteImage = (e, { name, value }) => {
    const { board } = this.state;
    board[name].splice(value, 1);
    this.setState({ board });
  };
  handleRadioChange = (e, { name, value }) => {
    const board = this.state.board;
    board[name] = value;
    this.setState({ board });
  };

  submit = async () => {
    this.setState({ loading: true });
    const { board: _board } = this.state;
    _board.isPublic = _board.isPublic === 'true';
    const res = await apis.createBoard(_board);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { board } = res;
    this.setFormat(board);
    this.setState({ redirect: true });
  };

  setFormat = (board) => {
    board.isPublic = board.isPublic === false ? 'false' : 'true';
    this.setState({ board: board });
  };

  render() {
    const { board } = this.state;
    if (this.state.redirect) {
      return <Redirect to={{ pathname: '/boards/' + board.id }} />;
    }
    if (this.state.loading) {
      return <Loader active inline="centered" />;
    }
    return (
      <Container>
        <Grid columns="equal">
          <Grid.Column />
          <Grid.Column width={8}>
            <Header as="h3" textAlign="center">
              게시글 작성
            </Header>
            <Form onSubmit={this.submit}>
              <Form.Field>
                <label>제목</label>
                <input
                  name="title"
                  value={board.title}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>내용</label>
                <TextArea
                  name="content"
                  value={board.content}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>이미지선택</label>
                <input
                  type="file"
                  multiple
                  name="imageUrls"
                  onChange={this.handleChangeMultiFile}
                />
                {board.imageUrls.map((url, idx) => (
                  <div key={idx}>
                    <Label
                      color="black"
                      name="imageUrls"
                      value={idx}
                      onClick={this.deleteImage}
                    >
                      <Icon name="delete" />
                    </Label>
                    <Label
                      name="up"
                      value={idx}
                      onClick={this.changeImageOrder}
                    >
                      <Icon name="angle up" style={{ margin: 0 }} />
                    </Label>
                    <Label
                      name="down"
                      value={idx}
                      onClick={this.changeImageOrder}
                    >
                      <Icon name="angle down" style={{ margin: 0 }} />
                    </Label>
                    <Image src={url} size="small" />
                  </div>
                ))}
              </Form.Field>
              <Form.Group grouped>
                <label>공개여부</label>
                <Form.Field
                  control={Radio}
                  label="공개"
                  value="true"
                  name="isPublic"
                  checked={board.isPublic === 'true'}
                  onChange={this.handleRadioChange}
                />
                <Form.Field
                  control={Radio}
                  label="미공개"
                  value="false"
                  name="isPublic"
                  checked={board.isPublic === 'false'}
                  onChange={this.handleRadioChange}
                />
              </Form.Group>
              <Button type="submit" fluid>
                Submit
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column />
        </Grid>
      </Container>
    );
  }
}

export default BoardCreate;
