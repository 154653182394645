import React, { Component } from 'react';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Image,
  Input,
  Loader,
  Menu,
  Modal,
  Table,
  TextArea,
} from 'semantic-ui-react';
import _ from 'lodash';
import api, { apis, getChallengeAchievements } from '../../../api';
import moment from 'moment';
import AchievementCard from '../AchievementCard/AchievementCard';

const reviewDeleteCommentOptions = [
  {
    key: 0,
    message:
      '해당 챌린지의 사진 업로드 날짜는 ㅇㅇ월 ㅇㅇ일(월) ~ ㅇㅇ월ㅇㅇ일(목)까지로 정해져있습니다. 회원님께서는 ㅇㅇ월 ㅇㅇ일에 사진을 업로드 하셔서 인증 규정에 어긋나 정상처리 되지 않았습니다😥 공정한 진행을 위해 아쉽게도 이번 인증샷은 삭제되오니 다음에는 인증 규정을 꼭 지켜주세요! 삭제된 인증샷은 결과 발표 30분 전까지 하트를 사용하셔서 만회하실 수 있습니다. 다음달에 재도전 하실 수 있으니 너무 상심마세요!',
    order: 0,
    text: '복근 - 인증 날짜 오인지 하여 업로드한 경우',
    value: 0,
  },
  {
    key: 1,
    message:
      '아쉽게도 댓글에 반대수가 더 많아 공정한 진행을 위해 인증샷이 삭제되었습니다.😥 다음달에 재도전 하실 수 있으니 너무 상심마세요! 삭제된 인증샷은 챌린지 결과 발표 30분 이전까지 하트를 사용하셔서 만회하실 수 있습니다. 다른 다양한 챌린지도 많이 준비되어 있습니다. 한 달간 고생 많으셨어요!',
    order: 1,
    text: '복근 - 반대수가 더 많은 경우',
    value: 1,
  },
  {
    key: 2,
    message:
      '아쉽게도 체지방 감량 목표수치를 달성하지 못한 것이 확인되어 인증샷이 삭제되었습니다.😥 다음달에 재도전 하실 수 있으니 너무 상심마세요! 삭제된 인증샷은 챌린지 결과 발표 30분 이전까지 하트를 사용하셔서 만회하실 수 있습니다. 다른 다양한 챌린지도 많이 준비되어 있습니다. 한 달간 고생 많으셨어요!',
    order: 2,
    text: '체지방 - Hello, World!',
    value: 2,
  },
  {
    key: 3,
    message:
      '[본 내용은 회원님에게만 보입니다. 다른 의견이 있으신 경우 댓글이 아닌 마이페이지탭의 문의하기를 통해 연락주세요]\n인증 규정과 달라 회원님의 인증샷이 정상처리 되지 않았습니다😥 공정한 진행을 위해 아쉽게도 이번 인증샷은 삭제되오니 다음에는 인증 규정을 꼭 지켜주세요!',
    order: 3,
    text: '삭제 - 푸시발송용',
    value: 3,
  },
  {
    key: 4,
    message:
      '[본 내용은 회원님에게만 보입니다. 다른 의견이 있으신 경우 댓글이 아닌 마이페이지탭의 문의하기를 통해 연락주세요]',
    order: 4,
    text: '삭제 - 인증처리용',
    value: 4,
  },
  {
    key: 5,
    message:
      '[챌린지 인증 보완 안내]\n\n안녕하세요. 챌린저스입니다.\n\n{{.Name}}님의 {{.ProductName}}의 {{.AchievementIndex}}회차 인증을 검토한 결과, \n{{.AchievementIndex}}회차 인증샷이 미션과 맞지 않는 것으로 확인되었습니다. \n\n건강한 챌린지 진행을 위해 해당 인증샷은 "삭제"됨을 알려드립니다.\n\n그러나 {{.AfterChallengeEndDate}} 오후 3시까지 {{.AchievementIndex}}회차를 규정에 맞는 방법으로 \n인증 보완 하신다면 정상적으로 인증 될 예정이니,\n인증 보완 마감 기한 날짜 전까지 인증샷 보완 부탁드립니다.\n\n■ 인증 보완 마감 기한\n {{.AfterChallengeEndDate}} 오후 3시\n*날짜 및 시간이 지난 이후 인증샷은 정상 인증이 어려우므로 반드시 정해진 기간에 재인증 부탁드립니다.\n\n■ 문의하기\n[챌린저스 앱 > 마이페이지 > 문의하기]\n\n감사합니다.\n챌린저스 드림.',
    order: 5,
    text: '인증 기간 종료 후',
    value: 5,
  },
];

class AchievementByChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filteredUsers: [],
      deleteModalOpen: false,
      achievement: {},
      deleteOptionValue: '',
      size: 'medium',
      commentModalOpen: false,
      commentCount: {
        success: 0,
        fail: 0,
      },
      loading: true,
      offset: 0,
      limit: 100,
      totalUserCount: 0,
      result: null,
      achievementIndex: undefined,
      memoModalOpen: false,
      totalAchievementCount: undefined,
    };
    this.deleteCommentEl = React.createRef();
    this.deleteComment = {
      value: '',
      commentCompleted: true,
    };
    this.pageNoInputRef = React.createRef();
  }

  componentDidMount() {
    this._getChallengeAchievements();
  }

  _getChallengeAchievements = () => {
    const { id } = this.props.match.params;
    const { offset, limit, result, achievementIndex } = this.state;
    getChallengeAchievements(id, {
      result,
      achievementIndex: achievementIndex ?? undefined,
      offset,
      limit,
    }).then((res) => {
      const { users, totalUserCount, totalAchievementCount } = res;
      this.setState({
        users,
        filteredUsers: users,
        loading: false,
        totalUserCount,
        totalAchievementCount,
      });
    });
  };

  _goToPage = (pageNo) => {
    this.setState({ loading: true }, () => {
      const { limit } = this.state;
      const offset = (pageNo - 1) * limit;
      this.setState({ offset }, () => {
        this._getChallengeAchievements();
      });
    });
  };

  filterUsers = (e) => {
    const { name, value } = e.target;
    if (name === 'result') {
      if (value === 'ALL') {
        this.setState({ result: null }, this._getChallengeAchievements);
      } else {
        this.setState({ result: value }, this._getChallengeAchievements);
      }
    } else {
      if (value === 'ALL') {
        this.setState(
          { achievementIndex: null },
          this._getChallengeAchievements,
        );
      } else {
        this.setState(
          { achievementIndex: value },
          this._getChallengeAchievements,
        );
      }
    }
  };

  changeImageSize = (e) => {
    this.setState({ size: e.target.value });
  };

  showDeleteModal = (user, achievement) => {
    this.setState({ deleteModalOpen: true, achievement, user });
  };
  closeDeleteModal = () =>
    this.setState({ deleteModalOpen: false, deleteOptionValue: '' });
  deleteAchievementFile = async (deleteType) => {
    const { achievement, user } = this.state;
    const isSmsSend =
      deleteType === 'PUSH' && this.state.deleteOptionValue === 5
        ? true
        : false;
    const res = await apis.deleteAchievementFile(
      achievement.achievementFile.id,
      {
        deleteType,
        reviewComment: this.deleteComment.value,
        userId: user.id,
        isSmsSend,
      },
    );
    if (res?.response?.data?.err) {
      this.setState({ deleteModalOpen: false });
      return;
    }

    const { achievementIds } = res;
    this.updateDeletedAchievement(achievementIds);
  };
  updateDeletedAchievement = (achievementIds, type = 'delete') => {
    const { user, users, filteredUsers } = this.state;
    if (type === 'delete') {
      user.achievements = user.achievements.filter(
        (a) => !achievementIds.includes(a.id),
      );
    } else {
      user.achievements.forEach((a) => {
        if (achievementIds.includes(a.id)) {
          a.achievementFile.url =
            'https://d246jgzr1jye8u.cloudfront.net/development/achievement/success.jpg';
        }
      });
    }
    const _users = users.map((u) => (u.id === user.id ? user : u));
    const _filteredUsers = filteredUsers.map((u) =>
      u.id === user.id ? user : u,
    );
    this.setState(
      {
        user: {},
        deleteModalOpen: false,
        achievement: {},
        users: _users,
        filteredUsers: _filteredUsers,
      },
      () => {
        alert('인증샷 삭제가 완료되었어요');
      },
    );
  };
  handleChangeDeleteCommentTemplate = (e, { value }) => {
    let reviewComment = reviewDeleteCommentOptions[value].message;
    this.setState({ deleteOptionValue: value });
    this.deleteComment.value = reviewComment;
    this.deleteCommentEl.current.ref.current.value = reviewComment;
  };
  handleChangeDeleteComment = (e) => {
    this.deleteComment.value = e.target.value;
  };

  showCommentModal = (achievement, commentCount) =>
    this.setState({ commentModalOpen: true, achievement, commentCount });
  closeCommentModal = () => this.setState({ commentModalOpen: false });

  showMemoModal = (achievement) =>
    this.setState({ memoModalOpen: true, achievement });
  closeMemoModal = () => this.setState({ memoModalOpen: false });

  render() {
    const {
      filteredUsers,
      deleteModalOpen,
      deleteOptionValue,
      size,
      memoModalOpen,
      commentModalOpen,
      achievement,
      commentCount,
      offset,
      limit,
      totalUserCount,
      result,
      achievementIndex,
      totalAchievementCount,
    } = this.state;
    const list = filteredUsers.map((u, idx) => {
      return (
        <Table.Row key={u.id}>
          <Table.Cell>{offset + idx + 1}</Table.Cell>
          <Table.Cell>
            {u.achievements?.[0]?.relationUserChallengeId}
          </Table.Cell>
          <Table.Cell>{u.id}</Table.Cell>
          <Table.Cell>{u.nickname}</Table.Cell>
          <Table.Cell>
            {u.result} ({u.achievementRate * 100}%)
          </Table.Cell>
          <Table.Cell>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {u.achievements.map((achievement, index) => {
                const commentCount = { success: 0, fail: 0 };
                achievement.comments?.forEach((c) => {
                  if (
                    c.comment.includes('인정') ||
                    c.comment.includes('ㅇㅈ')
                  ) {
                    commentCount.success += 1;
                  } else if (
                    c.comment.includes('반대') ||
                    c.comment.includes('ㅂㄷ')
                  ) {
                    commentCount.fail += 1;
                  }
                });
                return (
                  <AchievementCard
                    key={achievement.id}
                    achievement={achievement}
                    showDeleteModal={() => this.showDeleteModal(u, achievement)}
                    userId={u.id}
                    size={size}
                  />
                );
              })}
            </div>
          </Table.Cell>
        </Table.Row>
      );
    });
    return (
      <div style={{ margin: 40 }}>
        <Header as="h5">필터 구분</Header>
        <Form>
          <Form.Group inline>
            <label>결과값</label>
            <Form.Field
              label="전체"
              control="input"
              type="radio"
              name="result"
              value="ALL"
              checked={!result}
              onChange={this.filterUsers}
            />
            <Form.Field
              label="OVER_HUNDRED(하트없이 100%)"
              control="input"
              type="radio"
              name="result"
              checked={result === 'OVER_HUNDRED'}
              value="OVER_HUNDRED"
              onChange={this.filterUsers}
            />
            <Form.Field
              label="OVER_EIGHTY_FIVE(하트없이 85%이상)"
              control="input"
              type="radio"
              name="result"
              checked={result === 'OVER_EIGHTY_FIVE'}
              value="OVER_EIGHTY_FIVE"
              onChange={this.filterUsers}
            />
            <Form.Field
              label="NORMAL(하트를 썼거나 85% 미만)"
              control="input"
              type="radio"
              name="result"
              value="NORMAL"
              checked={result === 'NORMAL'}
              onChange={this.filterUsers}
            />
          </Form.Group>
          <Form.Group inline>
            <label>인증회차</label>
            <Form.Field
              label="전체"
              control="input"
              type="radio"
              name="achievementIndex"
              value={'ALL'}
              checked={!achievementIndex}
              onChange={this.filterUsers}
            />
            {!!totalAchievementCount &&
              Array.from({ length: totalAchievementCount }, (_, i) => i).map(
                (_, idx) => {
                  return (
                    <Form.Field
                      key={idx}
                      label={idx + 1}
                      control="input"
                      type="radio"
                      name="achievementIndex"
                      value={idx + 1}
                      checked={Number(achievementIndex) === idx + 1}
                      onChange={this.filterUsers}
                    />
                  );
                },
              )}
          </Form.Group>
          <Form.Group inline>
            <label>사이즈</label>
            <Form.Field
              label="짱크게"
              control="input"
              type="radio"
              name="size"
              value={'huge'}
              checked={size === 'huge'}
              onChange={this.changeImageSize}
            />
            <Form.Field
              label="크게"
              control="input"
              type="radio"
              name="size"
              value={'large'}
              checked={size === 'large'}
              onChange={this.changeImageSize}
            />
            <Form.Field
              label="중간"
              control="input"
              type="radio"
              name="size"
              value={'medium'}
              checked={size === 'medium'}
              onChange={this.changeImageSize}
            />
            <Form.Field
              label="작게"
              control="input"
              type="radio"
              name="size"
              value={'small'}
              checked={size === 'small'}
              onChange={this.changeImageSize}
            />
          </Form.Group>
        </Form>
        <b>조회 결과 {totalUserCount.toLocaleString()}개</b>
        <br />
        <Header as="h5">유저별 인증샷 내역</Header>
        <Table basic="very">
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>no</Table.HeaderCell>
              <Table.HeaderCell>신청id</Table.HeaderCell>
              <Table.HeaderCell>유저id</Table.HeaderCell>
              <Table.HeaderCell>닉네임</Table.HeaderCell>
              <Table.HeaderCell>달성률</Table.HeaderCell>
              <Table.HeaderCell>인증샷</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.loading ? (
              <Table.Row>
                <Table.Cell style={{ width: '100%' }}>
                  <Loader active inline="centered" />
                </Table.Cell>
              </Table.Row>
            ) : (
              list
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                <Input
                  ref={this.pageNoInputRef}
                  placeholder={'페이지 번호 입력'}
                />
                <Button
                  size="mini"
                  onClick={() => {
                    this._goToPage(
                      this.pageNoInputRef.current.inputRef.current.value,
                    );
                    this.pageNoInputRef.current.inputRef.current.value = '';
                  }}
                >
                  이동
                </Button>
                <Menu floated="right" pagination>
                  <Menu.Item
                    as="a"
                    icon
                    onClick={() => this._goToPage(offset / limit)}
                    disabled={offset / limit + 1 === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  {_.range(
                    Math.max(1, offset / limit - 9),
                    Math.min(
                      offset / limit + 11,
                      Math.ceil(totalUserCount / limit),
                    ) + 1,
                  ).map((item) => (
                    <Menu.Item
                      key={item}
                      onClick={() => this._goToPage(item)}
                      active={offset / limit + 1 === item}
                    >
                      {item}
                    </Menu.Item>
                  ))}
                  <Menu.Item
                    as="a"
                    icon
                    onClick={() => this._goToPage(offset / limit + 2)}
                    disabled={
                      offset / limit + 1 === Math.ceil(totalUserCount / limit)
                    }
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <Modal
          size="tiny"
          open={deleteModalOpen}
          onClose={this.closeDeleteModal}
        >
          <Modal.Header>인증샷 삭제</Modal.Header>
          <Modal.Content>
            인증샷 삭제를 하시겠습니까?
            <br />
            인증기간 내 인증샷 삭제의 경우 재인증이 가능합니다.
            <Form>
              <Form.Field>
                <label>메세지 템플릿</label>
                <Dropdown
                  placeholder="템플릿을 선택하세요."
                  fluid
                  search
                  selection
                  name="messageId"
                  value={deleteOptionValue}
                  options={reviewDeleteCommentOptions.map((template) => ({
                    ...template,
                    key: template.order,
                    value: template.order,
                    text: template.title,
                  }))}
                  onChange={this.handleChangeDeleteCommentTemplate}
                />
              </Form.Field>
              <Form.Field>
                <label>댓글 내용을 작성해주세요.</label>
                <TextArea
                  ref={this.deleteCommentEl}
                  rows={20}
                  onChange={this.handleChangeDeleteComment}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="삭제"
              onClick={() => this.deleteAchievementFile('DELETE')}
            />
            <Button
              color="blue"
              content="삭제(푸시발송)"
              onClick={() => this.deleteAchievementFile('PUSH')}
            />
            <Button
              color="blue"
              content="삭제(성공처리)"
              onClick={() => this.deleteAchievementFile('DELETE_AND_PASS')}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeDeleteModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal size="tiny" open={memoModalOpen} onClose={this.closeMemoModal}>
          <Modal.Header>메모 보기</Modal.Header>
          <Modal.Content>
            {achievement.achievementFile?.memo?.split('\n').map((line, idx) => {
              return (
                <span key={idx}>
                  {idx > 0 && <br />}
                  {line}
                </span>
              );
            })}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              content="닫기"
              onClick={this.closeMemoModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={commentModalOpen}
          onClose={this.closeCommentModal}
        >
          <Modal.Header>댓글 보기</Modal.Header>
          <Modal.Content>
            <p>
              인정: {commentCount.success} / 반대: {commentCount.fail}
            </p>
            {achievement.comments?.map((c) => {
              return (
                <p key={c.id}>
                  [{c.user.id}] {c.user.nickname} / {c.comment}
                  <br />
                  {moment(c.createdAt)
                    .add(9, 'h')
                    .format('YYYY-MM-DD HH:mm:ss')}
                </p>
              );
            })}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              content="닫기"
              onClick={this.closeCommentModal}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default AchievementByChallenge;
