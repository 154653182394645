import {
  convertData2Form,
  convertForm2Data,
} from './ViralLoopShareableForm.serializer';

export const ViralLoopShareableForm = () => {
  return <></>;
};

ViralLoopShareableForm.convertData2Form = convertData2Form;
ViralLoopShareableForm.convertForm2Data = convertForm2Data;
