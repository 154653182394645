import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import Spacing from '@component/Spacing';
import { Button, Header, Image } from 'semantic-ui-react';
import { PreAlramForm } from '../PreAlarmForm.types';
import { cropAndUploadImageFile } from '@utils/uploadImage';
import Label from '@component/Label';

const AlarmDetailInfo = ({
  thumbnailImageUrl,
  descriptionImageUrlList,
  handleChange,
  disabled,
}: Props) => {
  const handleFullImageChange = async (e: File | undefined) => {
    if (!e) return;

    const urls = await cropAndUploadImageFile(e);
    handleChange('descriptionImageUrlList', urls);
  };

  const handleRemove = (index: number) => {
    const newDescriptionImageUrlList = descriptionImageUrlList.filter(
      (_, idx) => idx !== index,
    );
    handleChange('descriptionImageUrlList', newDescriptionImageUrlList);
  };

  const handleReset = () => {
    if (!descriptionImageUrlList.length) return;

    if (window.confirm('챌린지 상세설명 이미지를 모두 지우시겠습니까?')) {
      handleChange('descriptionImageUrlList', []);
    }
  };

  return (
    <>
      <FlexBox.Column gap={5}>
        <Header as="h3">챌린지 상세페이지 관련 정보</Header>

        <Spacing vertical={10} />

        <ImageInput
          uploadOnChange
          showPreview
          previewSize={200}
          label="챌린지 썸네일"
          value={thumbnailImageUrl || undefined}
          onChange={(value) => {
            handleChange('thumbnailImageUrl', value);
          }}
          disabled={disabled}
        />

        <Spacing vertical={10} />

        <FlexBox.Row>
          <Label>챌린지 상세설명</Label>
          <Button onClick={() => handleReset()}>초기화</Button>
        </FlexBox.Row>

        <ImageInput
          showPreview
          onChange={handleFullImageChange}
          disabled={disabled}
        />

        <FlexBox.Row alignItems="flex-start" justifyContent="flex-start">
          {descriptionImageUrlList.map((url, index) => (
            <FlexBox.Column key={url}>
              <Image src={url} size="small" />
              <Button
                style={{ width: '100%' }}
                onClick={() => handleRemove(index)}
              >
                삭제
              </Button>
            </FlexBox.Column>
          ))}
        </FlexBox.Row>
      </FlexBox.Column>
    </>
  );
};

type Props = {
  thumbnailImageUrl: string;
  descriptionImageUrlList: string[];
  handleChange: <T extends keyof PreAlramForm>(
    key: T,
    value?: PreAlramForm[T],
  ) => void;
  disabled: boolean;
};

export default AlarmDetailInfo;
