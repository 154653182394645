import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Button, Modal, Grid } from 'semantic-ui-react';
import {
  defaultGoods,
  moduleLabel,
  modules,
} from '../../../../../constant/race';
import { generateId } from '../../../../../utils/number';
import { uploadImageFile } from '../../../../../utils/uploadImage';
import * as S from '../../../Style';
import DescriptionModuleField from '../DescriptionModuleField/DescriptionModuleField';
import ReactDragList from 'react-drag-list';
import { RaceFormDescriptionModule, RaceFormType } from '../../RaceForm.types';
import { Race } from '@types';

const RegisterInfoDescription = ({
  race,
  setRace,
}: {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
}) => {
  const { registerInfoDescriptionModule } = race.modules;
  const [modalOpen, setModalOpen] = useState(false);

  const showModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const selectModule = (moduleType: RaceFormDescriptionModule['type']) => {
    const module = _.cloneDeep(
      modules[moduleType],
    ) as RaceFormDescriptionModule;
    module.id = generateId();
    if (module.type === 'GOODS') {
      const _goods = _.cloneDeep(defaultGoods);
      _goods.id = generateId();
      _goods.additionalItems.forEach((item) => {
        item.id = generateId();
      });
      module.goodsList.push(_goods);
    }
    addModule(module);
    closeModal();
  };

  const addModule = (module: RaceFormDescriptionModule) => {
    const _race = _.cloneDeep(race);
    _race.modules.registerInfoDescriptionModule.push(module);
    setRace(_race);
  };

  const deleteModule = (idx: number) => {
    const _race = _.cloneDeep(race);
    _race.modules.registerInfoDescriptionModule.splice(idx, 1);
    setRace(_race);
  };

  const handleOrderChange = async (idx: number, direction: string) => {
    const _race = { ...race };
    const removed = _race.modules.registerInfoDescriptionModule.splice(
      idx,
      1,
    )[0];

    switch (direction) {
      case 'top':
        _race.modules.registerInfoDescriptionModule.unshift(removed);
        break;
      case 'up':
        _race.modules.registerInfoDescriptionModule.splice(idx - 1, 0, removed);
        break;
      case 'down':
        _race.modules.registerInfoDescriptionModule.splice(idx + 1, 0, removed);
        break;
      case 'bottom':
        _race.modules.registerInfoDescriptionModule.push(removed);
        break;
      default:
        break;
    }

    setRace(_race);
  };

  const handleChange = <K extends keyof RaceFormDescriptionModule>(
    idx: number,
    key: K,
    value: RaceFormDescriptionModule[K],
  ) => {
    const _race = _.cloneDeep(race);
    _race.modules.registerInfoDescriptionModule[idx][key] = value;
    setRace(_race);
  };

  const handleRouteChange = <
    K extends keyof Race.RaceHyperlinkDescriptionModule['route']['params'],
  >(
    idx: number,
    {
      key,
      value,
    }:
      | {
          key: 'type';
          value: 'APP_EVENT_SCREEN' | 'WEB' | 'RaceRegisterScreen';
        }
      | {
          key: K;
          value: Race.RaceHyperlinkDescriptionModule['route']['params'][K];
        },
  ) => {
    const _race = _.cloneDeep(race);
    if (key === 'type') {
      (
        _race.modules.registerInfoDescriptionModule[
          idx
        ] as Race.RaceHyperlinkDescriptionModule
      ).route.type = value;
    } else {
      (
        _race.modules.registerInfoDescriptionModule[
          idx
        ] as Race.RaceHyperlinkDescriptionModule
      ).route.params[key] = value as (
        | Race.AppEventScreenRouteParams
        | Race.WebRouteParams
        | Race.RaceRegisterScreenRouteParams
      )[K];
    }
    setRace(_race);
  };

  const handleSubBannerImageChange = async (idx: number, e: any) => {
    const _race = _.cloneDeep(race);

    if (!e.target.files) return;

    const url = await uploadImageFile(e.target.files[0]);
    (
      _race.modules.registerInfoDescriptionModule[
        idx
      ] as Race.RaceRegisterSubBannerModule
    ).imageUrl = url;
    setRace(_race);
  };

  const handleUpdate = async (e: unknown, updated: any) => {
    const _race = _.cloneDeep(race);
    _race.modules.registerInfoDescriptionModule = updated;
    setRace(_race);
  };

  return (
    <>
      <h3>대회 신청 화면 모듈</h3>
      <S.ButtonInForm content={'추가'} onClick={showModal} />
      <Grid style={{ fontWeight: 700 }}>
        <Grid.Row>
          <Grid.Column width={1}>no</Grid.Column>
          <Grid.Column width={11}>모듈</Grid.Column>
          <Grid.Column width={3}>이동/삭제</Grid.Column>
        </Grid.Row>
      </Grid>
      <ReactDragList
        key={registerInfoDescriptionModule?.map((o) => o.id).join('-')}
        rowKey="id"
        dataSource={[...registerInfoDescriptionModule]}
        handles={false}
        onUpdate={handleUpdate}
        style={{
          flex: 1,
        }}
        row={(module, idx) => (
          <Grid key={(module as RaceFormDescriptionModule).id}>
            <Grid.Column width={1}>{idx + 1}</Grid.Column>
            <Grid.Column width={11}>
              <DescriptionModuleField
                idx={idx}
                module={module as RaceFormDescriptionModule}
                moduleKey="registerInfoDescriptionModule"
                handleChange={handleChange}
                handleRouteChange={handleRouteChange}
                handleSubBannerImageChange={handleSubBannerImageChange}
                race={race}
                setRace={setRace}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <div>
                <S.ButtonInForm
                  content={'삭제'}
                  onClick={() => deleteModule(idx)}
                />
              </div>
              <div>
                <S.ButtonInForm
                  icon="angle double up"
                  onClick={() => handleOrderChange(idx, 'top')}
                />
              </div>
              <div>
                <S.ButtonInForm
                  icon="angle up"
                  onClick={() => handleOrderChange(idx, 'up')}
                />
              </div>
              <div>
                <S.ButtonInForm
                  icon="angle down"
                  onClick={() => handleOrderChange(idx, 'down')}
                />
              </div>
              <div>
                <S.ButtonInForm
                  icon="angle double down"
                  onClick={() => handleOrderChange(idx, 'bottom')}
                />
              </div>
            </Grid.Column>
          </Grid>
        )}
      />

      <Modal size="small" open={modalOpen} onClose={closeModal}>
        <Modal.Header>템플릿 추가</Modal.Header>
        <Modal.Content>
          {Object.values(modules)
            .filter(
              (m) =>
                !['LEAGUE-IMAGE-RECORDS', 'CONTENTS-CARDS'].includes(m.type),
            )
            .map((module) => (
              <S.ButtonInForm
                key={module.type}
                content={moduleLabel[module.type]}
                onClick={() =>
                  selectModule(module.type as RaceFormDescriptionModule['type'])
                }
              />
            ))}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" content="닫기" onClick={closeModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default RegisterInfoDescription;
