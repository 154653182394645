import _ from 'lodash';
import React from 'react';
import { Image } from 'semantic-ui-react';
import { cropAndUploadImageFile } from '../../../../../utils/uploadImage';
import * as S from '../../../Style';
import ProductDetailImagePreview from './ProductDetailImagePreview/ProductDetailImagePreview';
import { RaceFormType } from '../../RaceForm.types';

type Props = {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
};

const ProductDetailImageField = ({ race, setRace }: Props) => {
  const handleMultiImageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const _race = _.cloneDeep(race);
    const { name, files } = e.target;

    if (files === null) return;

    const urls = await cropAndUploadImageFile(files[0]);
    _race.info.productDetailImageUrls = urls;

    setRace(_race);
  };

  const deleteDetailImage = () => {
    const _race = _.cloneDeep(race);
    _race.info.productDetailImageUrls = [];
    setRace(_race);
  };

  return (
    <>
      <S.RowContainer>
        <h3>상품 사이즈 정보</h3>
        <S.ButtonInForm content={'삭제'} onClick={deleteDetailImage} />
      </S.RowContainer>
      <input type="file" name="url" onChange={handleMultiImageChange} />
      {race.info.productDetailImageUrls.map((url) => (
        <Image key={url} size={'small'} src={url} />
      ))}
      <ProductDetailImagePreview />
    </>
  );
};

export default ProductDetailImageField;
