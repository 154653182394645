import moment from 'moment';
import api, { _request, apiGo, apiMedia, apiV3 } from './factory';
import setAuthToken from './setAuthToken';
import { ApiType } from '../constant/apiType';
import question from './question';
import commerce from './commerce';
import common from './common';
import challenge from './challenge';
import user from './user';
import achievement from './achievement';
import preAlarm from './preAlarm';
import purchase from './purchase';
import reviewTemplates from './reviewTemplate';
import {
  RaceAllInfoForAdmin,
  RaceForAdmin,
  RaceForAdminOption,
  RaceImageFilterWithLeagueId,
  RaceReviewForAdmin,
  RaceUserWithImage,
  ReqCreateOrUpdateRaceSet,
  TransactionRaceForAdmin,
  ReqCreateOrUpdateProductPackage,
} from 'src/types/race.types';
import {
  ReqAddOrUpdateVirallLoop,
  ViralLoopForAdmin,
} from 'src/types/viralLoop.types';
import {
  Crm,
  Race,
  Banner,
  ChallengeCuration,
  ChallengeForOption,
  ChallengeIdTitle,
  ChallengeInfoForAdmin,
  ChallengeMiniInfoForAdmin,
  RegisterInfoForAdmin,
  ReqAddOrUpdateBanner,
  TransationProductForAdmin,
  LabelMiniInfo,
  CrmTemplate,
  CrmTemplateInfo,
} from '@types';
import race from './race';
import { PaginationResponse } from 'src/types/common.types';
import { Company, ReqCreateOrUpdateCompany } from 'src/types/company.types';
import {
  ProductWithChallenges,
  ReqCreateOrUpdateProduct,
} from 'src/types/product.types';
import { Board } from 'src/types/board.types';
import {
  AchievementAllInfoForAdmin,
  AchievementSetting,
  ReqGetAchievementSetting,
} from 'src/types/achievement.types';
import { RestDay } from 'src/types/restDay.types';
import {
  UserAchievementInfo,
  UserAdmin,
  UserMiniInfo,
} from 'src/types/user.types';
import {
  OfferWallForAdmin,
  OfferWallInfoForRaceAdmin,
  OfferWallParticipation,
  OfferWallWithUserFilterForAdmin,
} from 'src/types/offerwall.types';
import {
  UserFilterDetail,
  UserFilterItem,
  UserFilterMiniInfo,
} from 'src/types/userFilter.types';
import {
  Settlement,
  SettlementCollaboProduct,
} from '@container/Collabo/Settlement/Settlement.types';
import { EventData } from 'src/types/event';

export default api;

const apis = {
  getChallenges: async (params: {
    gteRegisterStartDate?: string;
    ltRegisterStartDate?: string;
    gteRegisterEndDate?: string;
    ltRegisterEndDate?: string;
    gteStartDate?: string;
    ltStartDate?: string;
    gteEndDate?: string;
    ltEndDate?: string;
    gteResultDate?: string;
    ltResultDate?: string;
    keyword?: string;
    userId?: number;
    isOfficial?: boolean;
    limit?: number;
    offset?: number;
  }): Promise<{
    challenges: ChallengeInfoForAdmin[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/challenges',
      params,
    });
  },

  getChallenge: async (
    challengeId: number,
  ): Promise<{
    challenge: ChallengeInfoForAdmin;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/challenges/${challengeId}`,
    });
  },

  updateChallengeReview: async (id: number, data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/challenge-reviews/${id}`,
      data,
    });
  },

  getChallengeOptions: async (params: {
    gteRegisterStartDate?: string;
    ltRegisterStartDate?: string;
    gteRegisterEndDate?: string;
    ltRegisterEndDate?: string;
    gteStartDate?: string;
    ltStartDate?: string;
    gteEndDate?: string;
    ltEndDate?: string;
    gteResultDate?: string;
    ltResultDate?: string;
    isOfficial?: boolean;
    isPublic?: boolean;
    isDeleted?: boolean;
    offset?: number;
    limit?: number;
    keyword?: string;
  }): Promise<{
    challenges: ChallengeForOption[];
    isEnd: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/challenges/options',
      params,
    });
  },

  copyChallenges: async (data: {
    challengeIds: number[];
  }): Promise<{
    challenges: ChallengeInfoForAdmin[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: '/admin/challenges/copy',
      data,
    });
  },

  intervalCopyChallenges: async (data: {
    challengeId?: number;
    totalDays?: number;
    interval?: number;
    adminUserIds?: number[];
  }): Promise<{
    challenges: ChallengeMiniInfoForAdmin[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: '/admin/challenges/interval-copy',
      data,
    });
  },

  deleteChallenges: async (data: {
    challengeIds: number[];
  }): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: '/admin/challenges/delete',
      data,
    });
  },

  setChallengesPublic: async (data: {
    challengeIds: number[];
  }): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: '/admin/challenges/public',
      data,
    });
  },

  getCollaboChallengeOptions: async (): Promise<{
    challenges: ChallengeIdTitle[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/challenges/collaboration/titles',
    });
  },

  getIngBeforeCalculatedChallenges: async (): Promise<{
    challenges: ChallengeMiniInfoForAdmin[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/challenges/ing-before-calculated',
    });
  },

  getStoreCategoryNames: async (): Promise<{
    categoryNameList: string[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/store/home-categories/name',
    });
  },

  getStoreCurations: async (): Promise<{
    storeCurationMiniInfoList: {
      id: number;
      title: string;
    };
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/store/curations',
    });
  },

  addGoalCategory: async (data: any) => {
    return _request({
      method: 'POST',
      url: '/goal-categories',
      data,
    });
  },

  updateAppSetting: async (data: any) => {
    return _request({
      method: 'PUT',
      url: '/app-setting',
      data,
    });
  },

  getProposals: async (params: any) => {
    return _request({
      method: 'GET',
      url: '/admin/challenge-proposals',
      params,
    });
  },

  getCompanyOptions: async (params?: {
    offset?: number;
    limit?: number;
    keyword?: string;
  }) => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/companies',
      params,
    });
  },

  getCompanyOptionsForSale: async (): Promise<{
    companyOptions: {
      id: number;
      name: string;
    }[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/companies/options-for-sale',
    });
  },

  getSupplierOptions: async (): Promise<{
    supplierOptions: {
      id: number;
      name: string;
      imageUrl: string;
    }[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/suppliers/options',
    });
  },

  getTagOptions: async (params: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/tags',
      params,
    });
  },

  createChallengeCuration: async (data: {
    title: string;
    subtitle: string;
    curations: any[];
  }): Promise<{
    challengeCuration: ChallengeCuration;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/challenges/curations`,
      data,
    });
  },

  copyChallengeCuration: async (
    id: number,
  ): Promise<{
    challengeCuration: ChallengeCuration;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/challenges/curations/${id}/copy`,
    });
  },

  getChallengeCurations: async (): Promise<{
    challengeCurations: ChallengeCuration[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/challenges/curations',
    });
  },

  getChallengeCuration: async (
    id: number,
  ): Promise<{
    challengeCuration: ChallengeCuration;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/challenges/curations/${id}`,
    });
  },

  updateChallengeCuration: async (
    id: number,
    data: {
      title?: string;
      subtitle?: string;
      isDisplayed: boolean;
      curations: any[];
    },
  ): Promise<{
    challengeCuration: ChallengeCuration;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/challenges/curations/${id}`,
      data,
    });
  },

  deleteChallengeCuration: async (
    id: number,
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/challenges/curations/${id}`,
    });
  },

  getBannerForOptionsNotEnded: async (): Promise<{
    banners: any[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/banners/options',
    });
  },

  initializeCart: async (data: any) => {
    return _request({
      method: 'POST',
      url: `/carts/initialize`,
      data,
    });
  },

  getBanners: async (params: {
    bannerType: string;
    order: string;
  }): Promise<{
    banners: Banner[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/banners`,
      params,
    });
  },

  checkBannerTargetFullRegistered: async (
    id: number,
  ): Promise<{
    fullRegistered: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/banners/${id}/full`,
    });
  },

  updateBannerDisplayed: async (
    id: number,
    data: {
      isDisplayed: boolean;
      isForced: boolean;
    },
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/banners/${id}/displayed`,
      data,
    });
  },

  updateBanner: async (
    id: number,
    data: ReqAddOrUpdateBanner,
  ): Promise<{
    banner: Banner;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/banners/${id}`,
      data,
    });
  },

  addBanner: async (
    data: ReqAddOrUpdateBanner,
  ): Promise<{
    banner: Banner;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/banners`,
      data,
    });
  },

  deleteBanner: async (
    id: number,
  ): Promise<{
    ok: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/banners/${id}`,
    });
  },

  updateBannerPriority: async (data: {
    priorityData: {
      id: number;
      priority: number;
    }[];
  }): Promise<{
    ok: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/banners/priority`,
      data,
    });
  },

  addEventData: async (data: {
    eventName: string;
    startTime: string;
    endTime: string;
    date: any;
  }): Promise<{
    event: EventData;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/event-data`,
      data,
    });
  },

  getEventDataList: async (): Promise<{
    events: EventData[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/event-data`,
    });
  },

  getEventData: async (
    id: number,
  ): Promise<{
    event: EventData;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/event-data/${id}`,
    });
  },

  updateEventData: async (
    id: number,
    data: {
      eventName: string;
      startTime: string;
      endTime: string;
      date: any;
    },
  ): Promise<{
    event: EventData;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/event-data/${id}`,
      data,
    });
  },

  deleteEventData: async (
    id: number,
  ): Promise<{
    ok: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/event-data/${id}`,
    });
  },

  copyEventData: async (
    eventId: number,
  ): Promise<{
    event: EventData;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/event-data/copy`,
      data: { eventId },
    });
  },

  addBlacklist: async (data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/blacklist`,
      data,
    });
  },

  getBlacklist: async (targetType: any, bannedType: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/blacklist`,
      params: { targetType, bannedType },
    });
  },

  updateBlacklist: async (id: any, data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/blacklist/${id}`,
      data,
    });
  },

  deleteBlacklist: async (id: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/blacklist/${id}`,
    });
  },

  deleteBlacklistByUserIds: async (data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/blacklist/delete`,
      data,
    });
  },

  getChallengeRegisters: async (
    challengeId: number,
    offset: number,
    limit: number,
  ): Promise<{
    relationUserChallenges: RegisterInfoForAdmin[];
    totalCount: number;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/challenges/${challengeId}/registers`,
      params: { offset, limit },
    });
  },

  getChallengeResultReport: async (
    challengeId: number,
  ): Promise<{
    url: string;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/challenges/${challengeId}/download-report`,
    });
  },

  downloadChallengeAchievementStatusExcel: async (challengeId: number) => {
    return _request({
      apiType: ApiType.media,
      method: 'GET',
      url: `/admin/challenges/${challengeId}/achievement-status/download`,
    });
  },

  addCompany: async (
    data: ReqCreateOrUpdateCompany,
  ): Promise<{
    company: Company;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/companies`,
      data,
    });
  },

  getCompanies: async (params?: {
    offset?: number;
    limit?: number;
    keyword?: string;
  }): Promise<{
    companies: Company[];
    isEnd: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/companies`,
      params,
    });
  },

  getCompaniesWithProductsAndPills: async () => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/companies/products-and-pills`,
    });
  },

  getProductsWithChallenges: async (params?: {
    offset?: number;
    limit?: number;
    keyword?: string;
  }): Promise<{
    companies: Company[];
    isEnd: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/products`,
    });
  },

  updateCompany: async (
    id: number,
    data: ReqCreateOrUpdateCompany,
  ): Promise<{
    company: Company;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/companies/${id}`,
      data,
    });
  },

  deleteCompany: async (
    id: number,
  ): Promise<{
    ok: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/companies/${id}`,
    });
  },

  addProduct: async (
    data: ReqCreateOrUpdateProduct,
  ): Promise<{
    product: ProductWithChallenges;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/products`,
      data,
    });
  },

  updateProduct: async (
    id: number,
    data: ReqCreateOrUpdateProduct,
  ): Promise<{
    product: ProductWithChallenges;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/products/${id}`,
      data,
    });
  },

  deleteProduct: async (
    id: number,
  ): Promise<{
    ok: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/products/${id}`,
    });
  },

  getCollaboAdminUserList: async (
    params: any,
  ): Promise<{
    adminUserList: { id: number; email: string; name: string }[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/collabo`,
      params,
    });
  },

  getCollaboAdminUserListByIds: async (params: {
    id: number[];
  }): Promise<{
    collaboAdminList: { id: number; email: string; name: string }[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/collabo/users`,
      params,
    });
  },

  searchCollaboAdminUserList: async (params: {
    keyword: string;
    offset: number;
    limit: number;
  }): Promise<
    {
      collaboAdminList: { id: number; email: string; name: string }[];
    } & PaginationResponse
  > => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/collabo/users/search`,
      params,
    });
  },

  deleteCollaboAdminUser: async (params: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/collabo/user`,
      params,
    });
  },

  creteCollaboAdminUser: async (data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/collabo/user`,
      data,
    });
  },

  updateCollaboAdminUser: async (data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/collabo/user`,
      data,
    });
  },

  createBoard: async (data: {
    title: string;
    content: string;
    boardCategoryId: number;
    imageUrls: string[];
    isPublic: boolean;
  }): Promise<{
    board: Board;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/boards`,
      data,
    });
  },

  getAllBoards: async (): Promise<{
    boards: Board[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/boards',
    });
  },

  getBoard: async (
    id: number,
  ): Promise<{
    board: Board;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/boards/${id}`,
    });
  },

  updateBoard: async (
    id: number,
    data: {
      title: string;
      content: string;
      boardCategoryId: number;
      imageUrls: string[];
      isPublic: boolean;
      isDeleted: boolean;
    },
  ): Promise<{
    board: Board;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/boards/${id}`,
      data,
    });
  },

  deleteBoard: async (id: number) => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/boards/${id}`,
    });
  },

  checkAchievementCardList: async (data: {
    goalId: number;
    startDate: string;
    endDate: string;
  }): Promise<{
    achievements: any[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/achievements/check-card-list`,
      data,
    });
  },

  getAchievement: async (
    id: number,
  ): Promise<{
    achievement: AchievementAllInfoForAdmin;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/achievements/${id}`,
    });
  },

  reviewAchievementFile: async (
    id: number,
    data: {
      achievementFileIds?: number[];
      reviewResult: string;
      reviewComment: string;
      isPushNeeded: boolean;
    },
  ): Promise<{
    achievementFileIds: number[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/achievement-files/${id}/reviews`,
      data,
    });
  },

  commentAchievementFile: async (
    id: number,
    data: {
      reviewComment: string;
      sendType: string;
    },
  ): Promise<{
    achievementFileComment: any;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/achievement-files/${id}/comments`,
      data,
    });
  },

  deleteAchievementFile: async (
    id: number,
    params: {
      userId: number;
      reviewComment: string;
      deleteType: string;
      isSmsSend?: boolean;
    },
  ): Promise<{
    achievementIds: number[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/achievement-files/${id}`,
      params,
    });
  },

  useAchievementItem: async (id: number) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/achievements/${id}/item`,
    });
  },

  deleteTransactionItem: async (
    id: number,
    params: {
      userId: number;
    },
  ): Promise<{
    achievementIds: number[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/achievements/${id}/item/delete`,
      params,
    });
  },

  completeTransactionTransfers: async (data: {
    transactionTransferIds: number[];
  }): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: '/admin/transaction-transfers/complete',
      data,
    });
  },

  updateTransactionTransfers: async (
    id: number,
    data: {
      answer: string;
    },
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/transaction-transfers/${id}/answer`,
      data,
    });
  },

  createRace: async (
    data: ReqCreateOrUpdateRaceSet,
  ): Promise<{ raceId: number }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/race`,
      data,
    });
  },

  updateRace: async (id: number, data: ReqCreateOrUpdateRaceSet) => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/race/${id}`,
      data,
    });
  },

  updateRaceDisplay: async (
    id: number,
    data: {
      displayType: string;
    },
  ) => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/race/${id}/displayed`,
      data,
    });
  },

  copyRace: async (id: number) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/race/${id}/copy`,
    });
  },

  removeRace: async (id: number) => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/race/${id}`,
      onError: (e) => {
        if (
          e?.response?.data?.err === 'err_cannot_delete_race_with_participants'
        ) {
          alert('참가자가 있어 대회를 삭제할 수 없어요.');
        } else {
          alert('삭제에 실패하였어요');
        }
        return e;
      },
    });
  },

  getRaceList: async (params: {
    orderBy: 'homecard' | 'setting';
  }): Promise<{ raceList: RaceForAdmin[] }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/race`,
      params,
    });
  },

  getRaceListForOption: async (): Promise<{
    raceList: RaceForAdminOption[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/race/options`,
    });
  },

  getRaceDetail: async (
    raceId: number,
  ): Promise<{
    race: RaceAllInfoForAdmin;
    packages: ReqCreateOrUpdateProductPackage[];
    filters: RaceImageFilterWithLeagueId[];
    offerWallInfo: OfferWallInfoForRaceAdmin[];
    labels: LabelMiniInfo[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/race/${raceId}`,
    });
  },

  getRaceTransactions: async (
    raceId: number,
  ): Promise<{ transactions: TransactionRaceForAdmin[] }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/race/${raceId}/transaction`,
    });
  },

  updateRaceTracking: async (
    raceId: number,
    data: {
      trackingList: {
        orderNo: string;
        productId: number;
        logisticsCompanyName: string;
        trackingNo: string;
      }[];
    },
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/race/${raceId}/tracking`,
      data,
    });
  },

  getRaceReviews: async (
    raceId: number,
  ): Promise<{ reviews: RaceReviewForAdmin[] }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/race/${raceId}/reviews`,
    });
  },

  getRaceImages: async (
    id: number,
  ): Promise<{
    users: RaceUserWithImage[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/race/${id}/images`,
    });
  },

  downloadRaceImages: async (id: number) => {
    return _request({
      apiType: ApiType.media,
      method: 'POST',
      url: `/admin/race/${id}/download`,
    });
  },

  createRaceSurvey: (data: { onboardingSheetUrl: string }) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/race/google-survey`,
      data,
    });
  },

  createViralLoop: async (
    data: ReqAddOrUpdateVirallLoop,
  ): Promise<{ viralLoop: ViralLoopForAdmin }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/viral-loop`,
      data,
    });
  },

  getViralLoopList: async (): Promise<{
    viralLoopList: ViralLoopForAdmin[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/viral-loop`,
    });
  },

  updateViralLoop: async (id: number, data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/viral-loop/${id}`,
      data,
    });
  },

  deleteViralLoop: async (id: number) => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/viral-loop/${id}`,
    });
  },

  getAppSettingSmsProvider: async (): Promise<{
    smsProvider: string;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/app-setting/sms-provider`,
    });
  },

  updateAppSettingSmsProvider: async (data: {
    smsProvider: string;
  }): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/app-setting/sms-provider`,
      data,
    });
  },

  createRestDay: async (data: {
    date: string;
    dateName: string;
  }): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/rest-days`,
      data,
    });
  },

  getRestDays: async (): Promise<{
    restDays: RestDay[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/rest-days`,
    });
  },

  updateRestDay: async (
    id: number,
    data: Pick<RestDay, 'date' | 'dateName'>,
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/rest-days/${id}`,
      data,
    });
  },

  deleteRestDay: async (id: number): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/rest-days/${id}`,
    });
  },

  createUserAdmin: async (data: {
    email: string;
    roles: string;
    name: string;
    phone: string;
    outlookPassword?: string;
  }): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/user-admin`,
      data,
    });
  },

  getUserAdminList: async (): Promise<{
    userAdminList: UserAdmin[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/user-admin`,
    });
  },

  updateUserAdmin: async (
    id: number,
    data: {
      email: string;
      roles: string;
      name: string;
      phone: string;
      outlookPassword?: string;
    },
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/user-admin/${id}`,
      data,
    });
  },

  deleteUserAdmin: async (id: number) => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/user-admin/${id}`,
    });
  },

  getOfferWalls: async (orderBy: string) => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/benefit/offer-walls?orderBy=${orderBy}`,
    });
  },

  addOfferWall: async (
    data: OfferWallForAdmin,
  ): Promise<{
    offerWallId: number;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/benefit/offer-walls`,
      data,
    });
  },

  editOfferWall: async (
    id: number,
    data: OfferWallForAdmin,
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/benefit/offer-walls/${id}`,
      data,
    });
  },

  deleteOfferWall: async (id: number): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/benefit/offer-walls/${id}`,
    });
  },

  copyOfferWall: async (
    id: number,
  ): Promise<{
    offerwall: OfferWallForAdmin;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/benefit/offer-walls/${id}/copy`,
    });
  },

  updateOfferWall: async (id: number, data: OfferWallForAdmin) => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/benefit/offer-walls/${id}`,
      data,
    });
  },

  getOfferWallAchievementsById: async (
    id: number,
  ): Promise<{
    offerWallParticipationList: OfferWallParticipation[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/benefit/offer-walls/${id}/participation`,
    });
  },

  getAllOfferWallAchievements: async (): Promise<{
    offerWallParticipationList: OfferWallParticipation[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/benefit/offer-walls/participation`,
    });
  },

  passOfferWallAchievement: async (
    achievementId: number,
    isSucceed: boolean,
  ): Promise<{
    success: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/benefit/offer-walls/participation/${achievementId}`,
      data: {
        isSucceed,
      },
    });
  },

  selectBestOfferWallAchievement: async (
    achievementId: number,
    isBest: boolean,
  ): Promise<{
    success: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/benefit/offer-walls/participation/${achievementId}`,
      data: {
        isBest,
      },
    });
  },

  updateOfferWallImageAchievement: async (
    achievementId: number,
    imageUrls: string[],
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/benefit/offer-walls/participation/${achievementId}`,
      data: {
        imageUrls,
      },
    });
  },

  getOfferWallDetailInfo: async (
    offerWallId: number,
  ): Promise<{
    offerWallInfo: OfferWallWithUserFilterForAdmin;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/benefit/offer-walls/${offerWallId}`,
    });
  },

  givePedometerInviteFriendReward: async (
    recommenderUserId: number,
    recommendeeUserId: number,
  ) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: '/admin/benefit/pedometer/invite-reward',
      data: {
        recommendeeUserId,
        recommenderUserId,
      },
    });
  },

  giveEventPoint: async (data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/event-point`,
      data,
    });
  },

  giveEventItem: async (data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/event-item`,
      data,
    });
  },

  createAchievementFile: async (id: number, data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/achievements/${id}`,
      data,
    });
  },

  getOfferWallOptions: async () => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `admin/benefit/offer-walls/options`,
    });
  },

  getGotchaWinners: (date: moment.MomentInput) =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `admin/gotchas/winners?date=${moment(date).format('YYYY-MM-DD')}`,
    }),

  checkGotchaWinners: (relationUserGotchaIds: number[]) =>
    _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/gotchas/winners/received`,
      data: { relationUserGotchaIds },
    }),

  // 기프티샵 구매 목록
  getPedometerGiftiShopPayments: (date: any) =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/benefit/pedometer/shop/payment?date=${date}`,
      onError: () => {},
    }),

  // 기프티샵 체크
  checkPedometerGiftiShopPayments: (paymentIds: any) =>
    _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/benefit/pedometer/shop/payment/received`,
      data: { paymentIds },
    }),

  // 기프티샵 아이템 리스트 조회
  getPedometerGiftiShopItemList: () =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/benefit/pedometer/shop`,
    }),

  // 기프티샵 아이템 단일 조회
  getPedometerGiftiShopItem: (id: number) =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/benefit/pedometer/shop/${id}`,
    }),

  // 기프티샵 아이템 수정
  editPedometerGiftiShopItems: (id: number, data: any) =>
    _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/benefit/pedometer/shop/${id}`,
      data,
    }),

  // 기프티샵 아이템 추가
  createPedometerGiftiShopItems: (data: any) =>
    _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/benefit/pedometer/shop`,
      data,
    }),

  getGotchas: () => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/gotchas`,
    });
  },

  editGotchas: (id: number, data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/gotchas/${id}`,
      data,
    });
  },

  createGotcha: (data: any) => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/gotchas`,
      data,
    });
  },

  // 라벨별 CRM 관리
  // CRM 템플릿 라벨 리스트 조회
  getCrmLabels: (params: {
    offset?: number;
    limit?: number;
  }): Promise<{
    labels: CrmTemplate[];
    totalCount: number;
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/crm/labels`,
      params,
    }),

  // CRM 템플릿 라벨 개별 조회
  getCrmLabelDetail: (
    id: number,
  ): Promise<{
    labels: CrmTemplate & {
      templateInfo: CrmTemplateInfo[];
    };
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/crm/labels/${id}`,
    }),

  // CRM 템플릿 라벨 수정
  updateCrmLabel: (
    id: string,
    data: {
      templateInfo: any;
      isActive?: boolean;
    },
  ): Promise<{ success: boolean }> =>
    _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/crm/labels/${id}`,
      data,
    }),

  // CRM 템플릿 라벨 생성
  createCrmLabel: (data: {
    labelId: number;
    templateInfo: any;
    isActive?: boolean;
  }): Promise<{ success: boolean }> =>
    _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/crm/labels`,
      data,
    }),

  // CRM 템플릿 라벨 패치
  patchCrmLabel: (
    id: number,
    data: {
      labelId?: number;
      templateInfo?: any;
      isActive?: boolean;
    },
  ): Promise<{ success: boolean }> =>
    _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/crm/labels/${id}`,
      data,
    }),

  // 챌린지 참여자 목록 조회
  getRegisteredUsers: (
    id: number,
  ): Promise<{
    users: UserMiniInfo[];
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/challenges/${id}/users`,
    }),

  /**
   *
   * @param params query parameters
   * @param {number} params.offset
   * @param {number} params.limit
   * @param {string} params.keyword
   * @returns
   */
  getUserFilters: (params: {
    offset?: number;
    limit?: number;
    keyword?: string;
  }): Promise<{
    totalCount: number;
    isEnd: boolean;
    userFilterList: UserFilterMiniInfo[];
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/user-filters',
      params,
    }),

  getUserFilterById: (id: number): Promise<{ userFilter: UserFilterDetail }> =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/user-filters/${id}`,
    }),

  // achievement setting
  getChallengeAchievementSetting: (
    challengeId: number,
  ): Promise<{
    achievementSetting: ReqGetAchievementSetting;
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/challenges/${challengeId}/achievement-setting`,
    }),

  editChallengeAchievementSetting: (
    challengeId: number,
    data: {
      list: AchievementSetting[];
    },
  ): Promise<{
    result: 'ok';
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/challenges/${challengeId}/achievement-setting`,
      data,
    }),

  // 제휴 챌린지 정산

  // 정산 리스트 조회
  /**
   * @param params query parameters
   * @param {string} params.gteChallengeResultDate
   * @param {string} params.ltChallengeResultDate
   * @param {boolean} params.isConfirmed
   * @param {number} params.offset
   * @param {number} params.limit
   * @param {number} params.companyId
   * @returns
   */
  getSettlementList: (params: {
    gteChallengeResultDate?: string | null;
    ltChallengeResultDate?: string | null;
    isConfirmed?: boolean | null;
    offset?: number | null;
    limit?: number | null;
    companyId?: number | null;
  }): Promise<{
    settlements: Settlement[];
    isEnd: boolean;
    totalCount: number;
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/collabo/settlements`,
      params,
    }),

  // 정산 엑셀 다운로드
  /**
   * @param data
   * @param {string} data.gteChallengeResultDate
   * @param {string} data.ltChallengeResultDate
   * @param {boolean} data.isConfirmed
   * @param {number} data.companyId
   * @param {number[]} data.ids
   * @returns
   */
  downloadSettlementExcel: (data: {
    gteChallengeResultDate?: string | null;
    ltChallengeResultDate?: string | null;
    isConfirmed?: boolean | null;
    companyName?: string | null;
    challengeTitle?: string | null;
    ids?: number[] | null;
  }) =>
    _request({
      apiType: ApiType.media,
      method: 'POST',
      url: `/admin/collabo/settlements/download`,
      data,
    }),

  // 정산 수정
  editSettlement: (
    settlementId: number,
    data: {
      companyId?: number;
      adsProductType?: string;
      productPriceToPurchase?: number;
      operatingFeePerPerson?: number;
      progressiveOperatingFeeForLte1000?: number;
      progressiveOperatingFeeForGt1000Lte2000?: number;
      progressiveOperatingFeeForGt2000?: number;
      bannerPrice?: number;
      instagramPrice?: number;
      naverBlogPrice?: number;
      naverAfterMonthReviewPrice?: number;
      etcPrice?: number;
      revenue?: number;
    },
  ): Promise<{
    revenue: number;
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/collabo/settlements/${settlementId}`,
      data,
    }),

  // 정산 초기화
  resetSettlement: (
    settlementId: number,
  ): Promise<{
    revenue: number;
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/collabo/settlements/${settlementId}/init`,
    }),

  // 매출 계산
  calculateSettlementRevenue: (
    settlementId: number,
    data: {
      operatingFeePerPerson: number;
      progressiveOperatingFeeForLte1000: number;
      progressiveOperatingFeeForGt1000Lte2000: number;
      progressiveOperatingFeeForGt2000: number;
      bannerPrice: number;
      instagramPrice: number;
      naverBlogPrice: number;
      naverAfterMonthReviewPrice: number;
      etcPrice: number;
    },
  ): Promise<{
    revenue: number;
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/collabo/settlements/${settlementId}/revenue`,
      data,
    }),

  // 정산서 확정
  confirmSettlement: (data: { ids: number[] }): Promise<{ success: boolean }> =>
    _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/collabo/settlements/confirm`,
      data,
    }),

  // 상품 옵션 리스트 관리 (collaboProducts)
  // 상품 옵션 리스트 조회
  getCollaboProductList: (params?: {
    companyId?: number;
    name?: string;
  }): Promise<{
    isEnd: boolean;
    collaboProducts: SettlementCollaboProduct[];
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/collabo/products`,
      params,
    }),

  // 상품 옵션 가격 수정
  /**
   * @param data
   * @param {number} data.price
   * @returns
   */
  editCollaboProductPrice: (
    productId: number,
    data: {
      companyId?: number;
      name?: string;
      price?: number;
      fee?: number;
      shippingPrice?: number;
      isAddressNeeded?: boolean;
      messageTemplate?: string;
      productGroupId?: number;
    },
  ): Promise<{
    product: ProductWithChallenges;
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/collabo/products/${productId}`,
      data,
    }),

  /**
   * 유저필터 검색
   * @param params
   * @param {string} params.keyword
   * @param {number} params.offset
   * @param {number} params.limit
   * @returns
   */
  searchUserFilter: ({
    keyword,
    offset,
    limit,
  }: {
    keyword: string;
    offset: number;
    limit: number;
  }): Promise<{
    totalCount: number;
    userFilterList: UserFilterItem[];
    isEnd: boolean;
  }> =>
    _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/user-filters`,
      params: { keyword, offset, limit },
    }),

  getTransactionProducts: async (
    challengeId: number,
    offset?: number,
    limit?: number,
  ): Promise<{
    transactionProducts: TransationProductForAdmin[];
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/challenges/${challengeId}/transaction-products`,
      params: { offset, limit },
    });
  },

  updateTransactionProduct: async (
    id: number,
    data: {
      userName: string;
      phone: string;
      zipcode: string;
      addressBase: string;
      addressDetail: string;
    },
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/transaction-products/${id}`,
      data,
    });
  },

  downloadOfferWallImage: async (offerWallId: number) => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `admin/benefit/offer-walls/${offerWallId}/download-image`,
    });
  },

  getCrmInfo: async (
    serviceId: number,
    serviceType: 'RACE',
    offerWallType?: Crm.CrmOfferWallType[],
  ): Promise<{ templates: Crm.CrmTemplate[] }> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/admin/crm-templates`,
      params: {
        serviceId,
        serviceType,
        offerWallType,
      },
    });
  },

  createCrmInfo: async (
    data: Omit<Race.RaceCrmInfo, 'id'>,
  ): Promise<{
    success: boolean;
  }> => {
    return _request({
      apiType: ApiType.go,
      method: 'POST',
      url: `/admin/crm-templates`,
      data,
    });
  },

  updateCrmInfo: async (
    id: number,
    data: Pick<Race.RaceCrmInfo, 'title' | 'body' | 'deepLink'>,
  ) => {
    return _request({
      apiType: ApiType.go,
      method: 'PUT',
      url: `/admin/crm-templates/${id}`,
      data,
    });
  },

  deleteCrmInfo: async (id: number) => {
    return _request({
      apiType: ApiType.go,
      method: 'DELETE',
      url: `/admin/crm-templates/${id}`,
    });
  },

  updateRucPublicType: async (
    rucId: number,
    data: {
      PublicType: string;
    },
  ): Promise<{ success: boolean }> => {
    return _request({
      apiType: ApiType.go,
      method: 'PATCH',
      url: `/admin/relation-user-challenges/${rucId}`,
      data,
    });
  },

  question,

  commerce,

  common,

  challenge,

  user,

  achievement,

  race,

  preAlarm,

  purchase,

  reviewTemplates,
};

export const getTags = async (
  params: { offset: number; limit: number; keyword: string } | undefined,
) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: '/admin/tags',
    params,
  });
};

export const updateTag = async (id: any, data: { tag: string }) => {
  return _request({
    method: 'PUT',
    url: `/tags/${id}`,
    data,
  });
};

export const addTag = async (data: {
  tag: string;
  interestText: string;
  category: string;
  imageUrl: string;
}) => {
  return _request({
    method: 'POST',
    url: '/tags',
    data,
  });
};

export const deleteTag = async (id: any) => {
  return _request({
    method: 'DELETE',
    url: `/tags/${id}`,
  });
};

export const getChallengeAchievements = async (
  challengeId: number,
  params: {
    result: string | null;
    achievementIndex: number | null;
    offset: number;
    limit: number;
  },
): Promise<{
  totalAchievementCount: number;
  totalUserCount: number;
  users: UserAchievementInfo[];
}> => {
  const { result, achievementIndex, offset, limit } = params;
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/challenges/${challengeId}/achievements`,
    params: {
      result: result ?? undefined,
      achievementIndex,
      offset,
      limit,
    },
  });
};

export const checkAchievementMetaInfo = async (data: any) => {
  return _request({
    method: 'POST',
    url: '/admin/achievements/check-meta-info',
    data,
  });
};

export const updateExperimentUser = async (
  userId: number,
  data: { experimentId: number; groupId: number },
) => {
  return _request({
    method: 'PUT',
    url: `/admin/users/${userId}/experiment`,
    data,
  });
};

export const login = async (data: {
  email: string;
  password: string;
}): Promise<{
  user: UserAdmin;
  token: string;
}> => {
  return _request({
    method: 'POST',
    url: '/admin/login',
    data,
  });
};

export const updateUser = async (data: {
  password: string;
  newPassword: string;
}) => {
  return _request({
    apiType: ApiType.python,
    method: 'PUT',
    url: '/admin/users/me',
    data,
  });
};

export const updateRacePriority = async (data: {
  raceId: number;
  newPriorityIndex: number;
}): Promise<{ success: boolean }> => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: '/admin/race/priority',
    data,
  });
};

export const updateOfferwallPriority = async (data: {
  priority: { [x: number]: number };
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: '/admin/benefit/offer-walls/priority',
    data,
  });
};

export const uploadImage = async (formData: FormData) => {
  return _request({
    apiType: ApiType.V3,
    method: 'POST',
    url: '/storage/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export { setAuthToken, api, apiMedia, apiGo, apis, apiV3 };
