import { useQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import { QUERY_KEY } from './queryKey';

type Params = {
  gteCreatedAt: number;
  ltCreatedAt: number;
  deletedIncluded: boolean;
  userId?: number;
};

export const useGetQuestionList = (params: Params) => {
  const query = useQuery({
    queryKey: [QUERY_KEY.GET_QUESTION_LIST, params],
    queryFn: () => apis.question.getQuestions(params),
  });

  return query;
};
