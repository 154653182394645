import React, { useEffect, useState } from 'react';
import {
  Feed,
  Button,
  Modal,
  TextArea,
  Form,
  Header,
  Label,
  Dropdown,
  Image,
  Accordion,
  Icon,
  Loader,
  Dimmer,
  DropdownProps,
} from 'semantic-ui-react';
import { chlngersUrl } from '../../../constant/imgUrls';
import resizeImage from '../../../global/resizeImage';
import moment from 'moment';
import { apis } from '../../../api';
import _ from 'lodash';
import ChallengeGoalSummary from '../ChallengeGoalSummary';
import { uploadImageFiles } from '../../../utils/uploadImage';
import {
  Question,
  QuestionCategory,
  QuestionMainCategory,
  QuestionSubCategory,
  QuestionTemplate,
} from '@types';
import { subYears } from 'date-fns';

const EMOTION = {
  1: '😠 화났어요',
  2: '😨 당황했어요',
  3: '🤔 궁금해요',
  4: '🥺 걱정돼요',
  5: '😌 평온해요',
  6: '🤩 건의할게요',
  7: '조금 시간이 걸려도 괜찮으니 정확한 답변을 부탁드려요 🙏',
  8: '최대한 빠른 답변을 부탁드려요 ⚡️',
  9: '조심스럽게 건의하고 싶은게 있어요 💐',
} as const;

type QuestionItemProps = {
  question: Question;
  templates: QuestionTemplate[];
  filterQuestion: (questionId: number) => void;
  questionAllTagOptions: any;
  handleQuestionTagAddition: (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => void;
  questionCategories: QuestionCategory[];
};

const QuestionItem = (props: QuestionItemProps) => {
  const [question, setQuestion] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [answer, setAnswer] = useState('');
  const [message, setMessage] = useState('');
  const [urls, setUrls] = useState<string[]>([]);
  const [userQuestionModalOpen, setUserQuestionModalOpen] = useState(false);
  const [questionCategories, setQuestionCategories] = useState<
    QuestionCategory[]
  >([]);
  const [mainCategories, setMainCategories] = useState<QuestionMainCategory[]>(
    [],
  );
  const [subCategories, setSubCategories] = useState<QuestionSubCategory[]>([]);
  const [showChallenge, setShowChallenge] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userQuestions, setUserQuestions] = useState<any>([]);

  useEffect(() => {
    const _question = { ...props.question };
    if (!_question.category) {
      _question.category = {} as QuestionCategory;
    }
    if (!_question.categoryInternal) {
      _question.categoryInternal = {} as any;
    }
    setQuestion(_question);
  }, [props.question]);

  useEffect(() => {
    setCategories();
  }, [questionCategories]);

  useEffect(() => {
    if (props.questionCategories !== questionCategories) {
      setQuestionCategories(props.questionCategories);
    }
  }, [props.questionCategories]);

  const setCategories = () => {
    const [_mainCategories, _subCategories, mainCategoryKeywords] = [
      [] as QuestionMainCategory[],
      [] as QuestionSubCategory[],
      [] as string[],
    ];
    questionCategories.forEach((_category: QuestionCategory) => {
      if (!mainCategoryKeywords.includes(_category.mainCategory)) {
        mainCategoryKeywords.push(_category.mainCategory);
        _mainCategories.push({
          key: _category.mainCategory,
          value: _category.mainCategory,
          text: _category.mainCategory,
        });
      }
      _subCategories.push({
        key: _category.id,
        value: _category.id,
        text: _category.mainCategory + ' / ' + _category.subCategory,
        mainCategory: _category.mainCategory,
      });
    });
    setMainCategories(_mainCategories);
    setSubCategories(_subCategories);
  };

  const modalShow = (e: any, { value }: any) => {
    setQuestion({
      ...question,
      content: value,
    });
    setModalOpen(true);
  };

  const modalClose = () => setModalOpen(false);

  const deleteModalShow = (e: any, { value }: any) => {
    setQuestion({
      ...question,
      content: value,
    });
    setDeleteModalOpen(true);
  };
  const deleteModalClose = () => setDeleteModalOpen(false);

  const handleChangeAnswer = (e: any, { value }: any) => {
    setAnswer(value);
  };
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      replyQuestion(null, { name: 'PUSH', value: question });
    }
  };

  const replyQuestion = async (e: any, { name, value }: any) => {
    if (isLoading) return;
    setIsLoading(true);

    const answerQuestionBody = {
      NO_PUSH_UPDATE: {
        answerType: name,
        answerImageUrls: urls,
        tags: value.tags,
        questionCategoryId: value.category?.id,
        questionCategoryInternalId: value.categoryInternal?.id,
      },
      NO_PUSH: {
        answer,
        answerImageUrls: urls,
        answerType: name,
        tags: value.tags,
        questionCategoryId: value.category?.id,
        questionCategoryInternalId: value.categoryInternal?.id,
      },
      PUSH: {
        answer,
        answerImageUrls: urls,
        answerType: name,
        tags: value.tags,
        questionCategoryId: value.category?.id,
        questionCategoryInternalId: value.categoryInternal?.id,
      },
    } as any;

    const res = await apis.question.answerQuestion({
      id: value.id,
      answer: answerQuestionBody[name],
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { _question } = res;
    setModalOpen(false);
    setQuestion(_question);
    setIsLoading(false);
  };

  const deleteQuestion = (e: any, { value }: any) => {
    apis.question
      .deleteQuestion({ id: value.id })
      .then(({ question: _question }) => {
        setDeleteModalOpen(false);
        props.filterQuestion(_question.id);
      });
  };

  const handleChangeMessage = (e: any, { value }: any) => {
    const template = props.templates.find(
      (_template: any) => _template.title === value,
    );
    if (!template) return;
    const _answer = template.content.replace(
      /\${nickname}/g,
      props.question.user?.nickname,
    );
    setMessage(value);
    setAnswer(_answer);
  };

  const handleChangeMultiFile = async (e: any) => {
    if (!e.target.files) return;

    const _urls = await uploadImageFiles([...e.target.files]);
    setUrls(_urls);
  };

  const showUserQuestionModal = async () => {
    const { questions: _userQuestions } = await apis.question.getQuestions({
      gteCreatedAt: subYears(new Date(), 5).getTime(),
      ltCreatedAt: new Date().getTime(),
      userId: props.question.user?.id,
      deletedIncluded: true,
    });

    setUserQuestions(_userQuestions);
    setUserQuestionModalOpen(true);
  };

  const closeUserQuestionModal = () => setUserQuestionModalOpen(false);

  const handleRadioChange = (e: any, { value }: any) => {
    setQuestion({
      ...question,
      tags: value,
    });
  };

  const handleCategoryRadioChange = (e: any, { name, value }: any) => {
    if (name === 'mainCategory' && value) {
      const _subCategories: any = [];
      questionCategories.forEach((_category: any) => {
        if (_category.mainCategory === value) {
          _subCategories.push({
            key: _category.id,
            value: _category.id,
            text: _category.mainCategory + ' / ' + _category.subCategory,
          });
        }
      });
      setSubCategories(_subCategories);
      setQuestion({
        ...question,
        category: {
          ...question.category,
          mainCategory: value,
        },
      });
    } else if (name === 'subCategory' && value) {
      const targetCategory: any = questionCategories.find(
        (_category: any) => _category.id === value,
      );
      setQuestion({
        ...question,
        category: {
          ...question.category,
          id: value,
          subCategory: targetCategory.subCategory,
        },
      });
    } else {
      setQuestion({
        ...question,
        categoryInternal: {},
      });
    }
  };

  const handleChallengeAccordion = () => {
    setShowChallenge(!showChallenge);
  };

  if (!question) return null;

  const { questionAllTagOptions, handleQuestionTagAddition } = props;

  const pictureUrl =
    question?.user?.pictureUrl === '' ? chlngersUrl : question.user?.pictureUrl;

  return (
    <Feed.Event>
      <Feed.Label image={resizeImage(pictureUrl, 80)} />
      <Feed.Content>
        <Feed.Summary>
          <Feed.User>
            <span style={{ cursor: 'default' }}>[{question.user?.id}] </span>
            <a
              href={`https://admin.whitecube.co.kr/users/${question.user?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {question.user?.nickname}
            </a>
          </Feed.User>
          <span>
            {' / '} {question.versionNumber} / {question.deviceModel}
          </span>
          <Feed.Date>
            {moment(question.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </Feed.Date>
          <Button
            basic
            size="mini"
            style={{ marginLeft: 10 }}
            onClick={showUserQuestionModal}
            content="이전 문의보기(최근 5년)"
          />
          <Button
            basic
            size="mini"
            style={{ marginLeft: 4 }}
            onClick={() =>
              window.navigator.clipboard.writeText(question.user?.id)
            }
            content="유저 ID 복사"
          />
          {question.answer === null && (
            <>
              <Button
                floated="right"
                size="mini"
                value={question.content}
                onClick={modalShow}
              >
                답글
              </Button>
              <Button
                floated="right"
                size="mini"
                value={question.content}
                onClick={deleteModalShow}
              >
                삭제
              </Button>
            </>
          )}
        </Feed.Summary>
        <Feed.Extra text style={{ width: '100%' }}>
          {question.category?.mainCategory && (
            <>
              <Label color="teal" size={'tiny'}>
                {question.category.mainCategory}
              </Label>
            </>
          )}
          {question.tags &&
            question.tags.map((element: string) => {
              return (
                <Label key={element} color={'red'} size={'tiny'}>
                  {element}
                </Label>
              );
            })}
          {question.categoryInternal?.id && (
            <Label
              key={'categoryInternal' + question.categoryInternal.id}
              color={'purple'}
              size={'tiny'}
            >
              {question.categoryInternal.text}
            </Label>
          )}
          {!!question.challenge && question.challenge.id > 0 && (
            <Accordion>
              <Accordion.Title
                active={showChallenge}
                index={question.id}
                // style={{backgroundColor: 'blue', borderRadius: 4}}
                onClick={handleChallengeAccordion}
              >
                <Header as={'h5'} color={'violet'}>
                  <Icon name="dropdown" />[{question.challenge.id}]{' '}
                  {question.challenge.title}
                  <a
                    href={`https://admin.whitecube.co.kr/challenges/${question.challenge.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'gray' }}
                  >
                    {' '}
                    (상세페이지로 이동)
                  </a>
                </Header>
              </Accordion.Title>
              <Accordion.Content
                active={showChallenge}
                style={{ padding: 20, width: '100%' }}
              >
                <ChallengeGoalSummary challenge={question.challenge} />
              </Accordion.Content>
            </Accordion>
          )}

          {question.race && question.race.id && (
            <Header as={'h5'} color={'violet'}>
              {question.race.raceTitle}
              <a
                href={`https://admin.whitecube.co.kr/race/${question.race.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'gray' }}
              >
                {' '}
                (상세페이지로 이동)
              </a>
            </Header>
          )}

          {!!question.purchaseChallenge && (
            <Header as="h5" color={'violet'}>
              {`[${question.purchaseChallenge.id}] ${question.purchaseChallenge.title}`}
              <a
                href={`https://boost.chlngers.com/confirm-photo/${question.purchaseChallenge.id}?tab=user`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'gray' }}
              >
                {' '}
                (인증 검토 페이지로 이동)
              </a>
            </Header>
          )}
          <Header as="h5" style={{ marginTop: 4 }}>
            {question.emotionId && question.emotionId !== 1
              ? `${EMOTION[question.emotionId as keyof typeof EMOTION]}`
              : ''}
          </Header>
          {question.content.split('\n').map((line: string, idx: number) => {
            return (
              <span key={idx}>
                {line}
                <br />
              </span>
            );
          })}
          {question.contentImageUrls.length > 0 &&
            _.map(question.contentImageUrls, (url, idx) => (
              <a href={url} key={idx} target="_blank" rel="noopener noreferrer">
                <Image src={url} size="mini" style={{ display: 'inline' }} />
              </a>
            ))}
        </Feed.Extra>
        {question.answer !== null && (
          <Feed>
            <Feed.Event>
              <Feed.Label image={chlngersUrl} />
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>ADMIN ({question.userAdminName})</Feed.User>
                  <Feed.Date>
                    {moment(question.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                  </Feed.Date>
                  <Button
                    floated="right"
                    size="mini"
                    value={question.content}
                    onClick={modalShow}
                  >
                    수정
                  </Button>
                </Feed.Summary>
                <Feed.Extra text>
                  {question.answer
                    .split('\n')
                    .map((line: string, idx: number) => {
                      return (
                        <span key={idx}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                  {question.answerImageUrls?.length > 0 &&
                    _.map(question.answerImageUrls, (url, idx) => (
                      <a
                        href={url}
                        key={idx}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={url}
                          size="mini"
                          style={{ display: 'inline' }}
                        />
                      </a>
                    ))}
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        )}
      </Feed.Content>

      <Modal size="tiny" open={modalOpen} onClose={modalClose}>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Modal.Header>1:1문의 답글</Modal.Header>
        <Modal.Content>
          <p>
            [{question.user?.id}]{question.user?.nickname}
          </p>
          {!!question.challenge && question.challenge.id > 0 && (
            <Accordion>
              <Accordion.Title
                active={showChallenge}
                index={question.id}
                // style={{backgroundColor: 'blue', borderRadius: 4}}
                onClick={handleChallengeAccordion}
              >
                <Header as={'h5'} color={'violet'}>
                  <Icon name="dropdown" />[{question.challenge.id}]{' '}
                  {question.challenge.title}
                  <a
                    href={`http://admin.whitecube.co.kr/challenges/${question.challenge.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'gray' }}
                  >
                    {' '}
                    (상세페이지로 이동)
                  </a>
                </Header>
              </Accordion.Title>
              <Accordion.Content
                active={showChallenge}
                style={{ padding: 20, width: '100%' }}
              >
                <ChallengeGoalSummary challenge={question.challenge} />
              </Accordion.Content>
            </Accordion>
          )}
          <p>{question.content}</p>
          {question.contentImageUrls.length > 0 &&
            _.map(question.contentImageUrls, (url, idx) => (
              <Image key={idx} src={url} style={{ width: '100%' }} />
            ))}
          <Form>
            <Form.Field>
              <label>메인 카테고리</label>
              <Dropdown
                placeholder="메인 카테고리"
                search
                selection
                clearable
                name="mainCategory"
                options={mainCategories}
                value={question.category?.mainCategory || ''}
                onChange={handleCategoryRadioChange}
              />
            </Form.Field>
            <Form.Field>
              <label>서브 카테고리</label>
              <Dropdown
                placeholder="서브 카테고리"
                search
                selection
                clearable
                name="subCategory"
                options={subCategories}
                value={question.category?.id || ''}
                onChange={handleCategoryRadioChange}
              />
            </Form.Field>
            <Form.Field>
              <label>메세지 템플릿</label>
              <Dropdown
                placeholder="템플릿을 선택하세요."
                fluid
                search
                selection
                name="messageId"
                value={message}
                options={props.templates.map(
                  (template: QuestionTemplate, index: number) => ({
                    key: index,
                    text: template.title,
                    value: template.title,
                  }),
                )}
                onChange={handleChangeMessage}
              />
            </Form.Field>
            <Form.Field>
              <label>답글</label>
              <TextArea
                rows={6}
                name="answer"
                value={answer}
                onChange={handleChangeAnswer}
                onKeyDown={handleKeyPress}
              />
            </Form.Field>
            <Form.Field>
              <label>첨부 이미지</label>
              <input
                name="answerImageUrls"
                type="file"
                onChange={handleChangeMultiFile}
                multiple
              />
              {urls.map((url, idx) => (
                <Image key={idx} src={url} size="medium" />
              ))}
            </Form.Field>
            <Form.Field>
              <label>태그 찾기</label>
              <Dropdown
                placeholder="태그를 선택하세요."
                fluid
                multiple
                search
                selection
                allowAdditions
                name="questionTags"
                options={questionAllTagOptions}
                value={question.tags}
                onAddItem={handleQuestionTagAddition}
                onChange={handleRadioChange}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            floated="left"
            name="NO_PUSH_UPDATE"
            content="업데이트"
            value={question}
            onClick={replyQuestion}
          />
          <Button
            color="blue"
            name="PUSH"
            content="답변(푸시)"
            value={question}
            onClick={replyQuestion}
          />
          <Button
            name="NO_PUSH"
            content="답변"
            value={question}
            onClick={replyQuestion}
          />
          <Button color="black" content="닫기" onClick={modalClose} />
        </Modal.Actions>
      </Modal>

      <Modal size="tiny" open={deleteModalOpen} onClose={deleteModalClose}>
        <Modal.Header>1:1문의 삭제</Modal.Header>
        <Modal.Content>
          <p>질문 : {question.content}</p>
          <p>위 1:1 문의를 삭제하시겠습니까?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            content="삭제"
            value={question}
            onClick={deleteQuestion}
          />
          <Button color="black" content="닫기" onClick={deleteModalClose} />
        </Modal.Actions>
      </Modal>

      <Modal
        size="tiny"
        open={userQuestionModalOpen}
        onClose={closeUserQuestionModal}
      >
        <Modal.Header>유저 문의내역</Modal.Header>
        <Modal.Content>
          <Feed>
            {_.map(userQuestions, (q) => (
              <Feed.Event key={q.id}>
                <Feed.Label image={resizeImage(pictureUrl, 80)} />
                <Feed.Content>
                  <Feed.Summary>
                    <Feed.User
                      as="a"
                      href={`http://admin.whitecube.co.kr/users/${q.user?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {q.isDeleted && '[삭제됨] '}
                      {q.user?.nickname} / ({q.user?.id})
                    </Feed.User>
                    <Feed.Date>
                      {moment(q.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </Feed.Date>
                  </Feed.Summary>
                  {q.challenge && (
                    <a
                      href={`http://admin.whitecube.co.kr/challenges/${q.challenge.id}`}
                      key={q.id}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: 'hotpink',
                        textDecoration: 'underline',
                      }}
                    >
                      {`[${q.challenge.id}] ${q.challenge.title}`}
                    </a>
                  )}
                  {q.purchaseChallenge && (
                    <a
                      href={`https://boost.chlngers.com/campaigns/${q.purchaseChallenge.purchaseCampaignId}/challenges/${q.purchaseChallenge.id}`}
                      key={q.id}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: 'hotpink',
                        textDecoration: 'underline',
                      }}
                    >
                      {`[${q.purchaseChallenge.id}] ${q.purchaseChallenge.title}`}
                    </a>
                  )}
                  <Feed.Extra text>
                    {q.content.split('\n').map((line: string, idx: number) => {
                      return (
                        <span key={idx}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                    {q.contentImageUrls.length > 0 &&
                      _.map(q.contentImageUrls, (url, idx) => (
                        <a
                          href={url}
                          key={idx}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={url}
                            size="mini"
                            style={{ display: 'inline' }}
                          />
                        </a>
                      ))}
                  </Feed.Extra>
                  {q.answer !== null && (
                    <Feed style={{ marginLeft: 20 }}>
                      <Feed.Event>
                        <Feed.Content>
                          <Feed.Summary>
                            <Feed.User>ADMIN ({q.userAdminName})</Feed.User>
                            <Feed.Date>
                              {moment(q.updatedAt).format(
                                'YYYY-MM-DD HH:mm:ss',
                              )}
                            </Feed.Date>
                          </Feed.Summary>
                          <Feed.Extra text>
                            {q.answer
                              .split('\n')
                              .map((line: string, idx: number) => {
                                return (
                                  <span key={idx}>
                                    {line}
                                    <br />
                                  </span>
                                );
                              })}
                            {q.answerImageUrls.length > 0 &&
                              _.map(q.answerImageUrls, (url, idx) => (
                                <a
                                  href={url}
                                  key={idx}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Image
                                    src={url}
                                    size="mini"
                                    style={{ display: 'inline' }}
                                  />
                                </a>
                              ))}
                          </Feed.Extra>
                        </Feed.Content>
                      </Feed.Event>
                    </Feed>
                  )}
                </Feed.Content>
              </Feed.Event>
            ))}
          </Feed>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            content="닫기"
            onClick={closeUserQuestionModal}
          />
        </Modal.Actions>
      </Modal>
    </Feed.Event>
  );
};

export default QuestionItem;
