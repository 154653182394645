import React, { Component } from 'react';
import {
  Container,
  Button,
  Header,
  Modal,
  Label,
  Form,
  Loader,
} from 'semantic-ui-react';
import ChallengeCurationForm from '../ChallengeCurationForm';
import { apis } from '../../../../api';
import { Redirect } from 'react-router-dom';
import { handleChangeSingleFile } from '../../../../utils/uploadImage';
import { getGoalOptions } from '../../../../utils/dropdownOptions';
import moment from 'moment';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';

const categorySubTypeOptions = [
  { key: '1-1', text: '전체 챌린지', value: 'ALL' },
  { key: '1-2', text: '신규 챌린지', value: 'NEW' },
  { key: '1-3', text: '인기 챌린지', value: 'POPULAR' },
];
const COLLECTION_TYPE_NAMES = [
  'CUSTOM',
  'TAG',
  'GOAL',
  'GOAL_CATEGORY_LV1',
  'GOAL_CATEGORY_LV2',
  'GOAL_CATEGORY_LV3',
];

function isValidCuration(curation) {
  return COLLECTION_TYPE_NAMES.includes(curation.type);
}
class ChallengeCurationCreateOrEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      challengeCuration: {
        title: '',
        subtitle: '',
        curations: [],
      },
      curationModalOpen: false,
      type: 0,
      isSubmitCompleted: true,
      redirect: false,
      tagOptions: [],
      loading: true,
    };
    this.COLLECTION_TYPES = {
      CUSTOM: {
        type: 'CUSTOM',
        text: '커스텀 (전체, 신규 등)',
        options: categorySubTypeOptions,
      },
      TAG: { type: 'TAG', text: '태그', options: [] },
      GOAL: { type: 'GOAL', text: '목표', options: [] },
      GOAL_CATEGORY_LV1: {
        type: 'GOAL_CATEGORY_LV1',
        text: '목표카테고리 LV1',
        options: [],
      },
      GOAL_CATEGORY_LV2: {
        type: 'GOAL_CATEGORY_LV2',
        text: '목표카테고리 LV2',
        options: [],
      },
      GOAL_CATEGORY_LV3: {
        type: 'GOAL_CATEGORY_LV3',
        text: '목표카테고리 LV3',
        options: [],
      },
    };
    this.isEditing = !!props.match.params.id;
    this.challenges = [];
  }

  async componentDidMount() {
    const { challenges } = await apis.getChallenges({
      ltRegisterStartDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      gteRegisterEndDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      offset: 0,
      limit: 1000,
      userId: 1,
    });
    this.challenges = challenges;

    if (this.isEditing) {
      const res = await apis.getChallengeCuration(this.props.match.params.id);
      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }
      const { challengeCuration } = res;
      this.setState({ challengeCuration });
      challengeCuration.curations.forEach((module) => {
        const value = module.type === 'GOAL' ? module.goalIds : module.subType;
        return this.setChallengesToCurationModule(module, value);
      });
    }

    const { tags } = await apis.getTagOptions();
    let tagOptions = [];
    for (let i = 0; i < tags.length; i++) {
      tagOptions.push({
        key: tags[i].id,
        text: tags[i].tag,
        value: tags[i].id,
      });
    }
    this.COLLECTION_TYPES.TAG.options = tagOptions;
    this.COLLECTION_TYPES.GOAL.options = await getGoalOptions({
      isOfficial: 'True',
    });

    const res = await apis.challenge.getGoalCategoryLevelOptions();
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { level1, level2, level3 } = res;
    this.COLLECTION_TYPES.GOAL_CATEGORY_LV1.options = level1.map((item) => ({
      key: item,
      text: item,
      value: item,
    }));
    this.COLLECTION_TYPES.GOAL_CATEGORY_LV2.options = level2.map((item) => ({
      key: item,
      text: item,
      value: item,
    }));
    this.COLLECTION_TYPES.GOAL_CATEGORY_LV3.options = level3.map((item) => ({
      key: item,
      text: item,
      value: item,
    }));
    this.setState({ loading: false });
  }

  curationModalShow = () => this.setState({ curationModalOpen: true });
  curationModalClose = () => this.setState({ curationModalOpen: false });
  appendModule = (e, { value }) => {
    const { curations } = this.state.challengeCuration;
    if (value === 1)
      curations.push({
        type: 'CUSTOM',
        subType: '',
        title: '',
        subTitle: '',
        buttonText: '',
        imageUrl: '',
      });
    else if (value === 2)
      curations.push({
        type: 'TAG',
        subType: '',
        title: '',
        subTitle: '',
        buttonText: '',
        imageUrl: '',
      });
    else if (value === 3)
      curations.push({
        type: 'GOAL',
        subType: '',
        title: '',
        subTitle: '',
        buttonText: '',
        imageUrl: '',
        goalIds: [],
      });
    else if (value === 4)
      curations.push({
        type: 'GOAL_CATEGORY_LV1',
        subType: '',
        title: '',
        subTitle: '',
        buttonText: '',
        imageUrl: '',
      });
    else if (value === 5)
      curations.push({
        type: 'GOAL_CATEGORY_LV2',
        subType: '',
        title: '',
        subTitle: '',
        buttonText: '',
        imageUrl: '',
      });
    else if (value === 6)
      curations.push({
        type: 'GOAL_CATEGORY_LV3',
        subType: '',
        title: '',
        subTitle: '',
        buttonText: '',
        imageUrl: '',
      });
    this.setState({ curationModalOpen: false, curations });
  };
  spliceCuration = (e, { value }) => {
    const { curations } = this.state.challengeCuration;
    curations.splice(value, 1);
    this.setState({ curations });
  };
  curationChangeOrder = (e, { name, value }) => {
    const { curations } = this.state.challengeCuration;
    const removed = curations.splice(value, 1);
    if (name === 'up') {
      curations.splice(value - 1, 0, removed[0]);
    } else if (name === 'down') {
      curations.splice(value + 1, 0, removed[0]);
    } else if (name === 'doubleUp') {
      curations.splice(0, 0, removed[0]);
    } else {
      curations.splice(curations.length, 0, removed[0]);
    }
    this.setState({ curations });
  };
  curationHandleChange = (e) => {
    const { curations } = this.state.challengeCuration;
    const split = e.target.name.split('-');
    curations[split[1]][split[0]] = e.target.value;
    this.setState({ curations });
  };
  curationHandleRadioChange = async (e, { name, value }) => {
    const { curations } = this.state.challengeCuration;
    const split = name.split('-');
    const module = this.setChallengesToCurationModule(
      curations[split[1]],
      value,
    );
    module[split[0]] = value;
    this.setState({ curations });
  };
  handleChange = (e) => {
    const { challengeCuration } = this.state;
    challengeCuration[e.target.name] = e.target.value;
    this.setState({ challengeCuration });
  };
  curationHandleChangeSingleFile = async (e) => {
    const { curations } = this.state.challengeCuration;
    const split = e.target.name.split('-');
    e.target.name = split[0];

    if (!e.target.files) return;

    const url = await uploadImageFile(e.target.files[0]);
    this.setState({
      curations: {
        ...curations,
        [e.target.name]: url,
      },
    });
  };
  setChallengesToCurationModule = (module, subType) => {
    if (module.type === 'TAG') {
      // tag 포함여부 바탕으로 챌린지 집어넣기
      module['challenges'] = this.challenges.filter((c) =>
        c.tags?.map((t) => t.id).includes(subType),
      );
    } else if (module.type === 'GOAL') {
      module['challenges'] = this.challenges.filter((c) =>
        subType.includes(Number(c.goalId)),
      );
    } else if (module.type === 'GOAL_CATEGORY_LV1') {
      module['challenges'] = this.challenges.filter(
        (c) => c.goal.goalCategory.level1 === subType,
      );
    } else if (module.type === 'GOAL_CATEGORY_LV2') {
      module['challenges'] = this.challenges.filter(
        (c) => c.goal.goalCategory.level2 === subType,
      );
    } else if (module.type === 'GOAL_CATEGORY_LV3') {
      module['challenges'] = this.challenges.filter(
        (c) => c.goal.goalCategory.level3 === subType,
      );
    }
    return module;
  };

  submit = async () => {
    const { challengeCuration } = this.state;

    LoadingIndicator.show();

    try {
      challengeCuration.curations.forEach((c) => delete c['challenges']);
      if (this.isEditing) {
        const res = await apis.updateChallengeCuration(challengeCuration.id, {
          title: challengeCuration.title,
          subtitle: challengeCuration.subtitle,
          curations: challengeCuration.curations,
        });
        if (res?.response?.data && 'err' in res?.response?.data) {
          return;
        }

        const { challengeCuration: _challengeCuration } = res;
        this.setState({
          challengeCuration: _challengeCuration,
          isSubmitCompleted: true,
          redirect: true,
        });
      } else {
        const res = await apis.createChallengeCuration({
          title: challengeCuration.title,
          subtitle: challengeCuration.subtitle,
          curations: challengeCuration.curations,
        });
        const { challengeCuration: _challengeCuration } = res;
        this.setState({
          challengeCuration: _challengeCuration,
          isSubmitCompleted: true,
          redirect: true,
        });
      }
    } finally {
      LoadingIndicator.hide();
    }
  };

  render() {
    const { loading, redirect, curationModalOpen, challengeCuration } =
      this.state;
    if (redirect)
      return <Redirect to={{ pathname: '/challenges/curations' }} />;
    if (loading) {
      return <Loader active inline="centered" />;
    }

    return (
      <Container>
        <Header as="h3">
          챌린지 큐레이션 {this.isEditing ? '수정' : '추가'}
        </Header>
        <Form>
          <Form.Field>
            <label>타이틀</label>
            <input
              name="title"
              value={challengeCuration.title}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>서브 타이틀</label>
            <input
              name="subtitle"
              value={challengeCuration.subtitle}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Header as="h5">컬렉션</Header>
          {challengeCuration.curations
            .filter((curation) => isValidCuration(curation))
            .map((curation, idx) => (
              <ChallengeCurationForm
                key={idx}
                object={curation}
                idx={idx}
                collectionTypes={this.COLLECTION_TYPES}
                spliceCuration={this.spliceCuration}
                curationChangeOrder={this.curationChangeOrder}
                curationHandleChange={this.curationHandleChange}
                curationHandleRadioChange={this.curationHandleRadioChange}
                curationHandleChangeSingleFile={
                  this.curationHandleChangeSingleFile
                }
              />
            ))}
        </Form>
        <Container style={styles.float}>
          <Label
            size="large"
            style={{ cursor: 'pointer' }}
            onClick={this.curationModalShow}
          >
            컬렉션 추가
          </Label>
          <Button floated="right" onClick={this.submit}>
            Submit
          </Button>
        </Container>
        <div style={{ height: 50 }} />
        <Modal
          size="small"
          open={curationModalOpen}
          onClose={this.curationModalClose}
        >
          <Modal.Header>컬렉션 종류</Modal.Header>
          <Modal.Content>
            {COLLECTION_TYPE_NAMES.map((item, idx) => (
              <Button
                key={item}
                size="tiny"
                value={idx + 1}
                onClick={this.appendModule}
              >
                {this.COLLECTION_TYPES[item].text}
              </Button>
            ))}
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" onClick={this.curationModalClose}>
              취소
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

export default ChallengeCurationCreateOrEdit;

const styles = {
  float: {
    backgroundColor: 'white',
    paddingTop: 10,
    paddingBottom: 20,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    zIndex: 100,
  },
};
