import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { cropAndUploadImageFile } from '@utils/uploadImage';
import OfferWallModuleItem from '../../OfferWallModuleItem/OfferWallModuleItem';
import * as S from '../../Style';
import {
  getIsModuleDisabled,
  getModules,
  isDefaultRouteOfferWall,
} from '../../utils';

const OfferWallModule: React.FC<OfferWallModuleProps> = (props) => {
  const {
    offerWallInfo,
    onMoveModule,
    removeModule,
    addModule,
    handleChangeModuleValue,
  } = props;

  // 모듈 관련
  const [moduleModalOpen, setModuleModalOpen] = useState(false);

  //
  const handleChangeModuleFile: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const { name } = e.target;

    if (!e.target.files) return;

    const urls = await cropAndUploadImageFile(e.target.files?.[0]);
    const splits = name.split('-');
    handleChangeModuleValue(Number(splits[0]), splits[1], urls);
  };

  return (
    <>
      <h2>모듈 설정</h2>
      {isDefaultRouteOfferWall(offerWallInfo.type) && (
        <Button
          color="green"
          content="모듈 추가"
          onClick={() => setModuleModalOpen(true)}
        />
      )}
      <div>
        {/* 모듈 리스트 */}
        {(offerWallInfo.moduleList || []).map((module: any, idx: number) => (
          <S.ModuleWrapper key={`${module.type}-${idx}`}>
            <S.RowContainer>
              <Button
                size="mini"
                icon="angle up"
                onClick={() => onMoveModule(idx, 'up')}
                disabled={getIsModuleDisabled(offerWallInfo.type, module.type)}
              />
              <Button
                size="mini"
                icon="angle down"
                onClick={() => onMoveModule(idx, 'down')}
                disabled={getIsModuleDisabled(offerWallInfo.type, module.type)}
              />
              <Button
                size="mini"
                icon="delete"
                value={0}
                onClick={() => removeModule(idx)}
                disabled={getIsModuleDisabled(offerWallInfo.type, module.type)}
              />
            </S.RowContainer>

            <OfferWallModuleItem
              module={module}
              moduleIdx={idx}
              handleChangeFile={handleChangeModuleFile}
              handleChangeModuleValue={handleChangeModuleValue}
            />
          </S.ModuleWrapper>
        ))}
      </div>

      <Modal
        size="tiny"
        open={moduleModalOpen}
        onClose={() => setModuleModalOpen(false)}
      >
        <Modal.Header>모듈 선택</Modal.Header>
        <Modal.Content>
          <S.RowWrapContainer>
            {getModules(offerWallInfo).map((module, idx) => {
              return (
                <S.LabelButton
                  key={`${module.type}-${idx}`}
                  margin="4px"
                  onClick={() => {
                    addModule(module);
                    setModuleModalOpen(false);
                  }}
                >
                  {module.label}
                </S.LabelButton>
              );
            })}
          </S.RowWrapContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModuleModalOpen(false)}>
            닫기
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

type OfferWallModuleProps = {
  offerWallInfo: any;
  onMoveModule: (idx: number, direction: string) => void;
  removeModule: (idx: number) => void;
  addModule: (module: any) => void;
  handleChangeModuleValue: (
    moduleIdx: number,
    name: string,
    value: string[],
  ) => void;
};

export default OfferWallModule;
