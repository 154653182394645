import React, { useState } from 'react';
import {
  Checkbox,
  Divider,
  Form,
  GridColumn,
  Header,
  Image,
  Label,
  Table,
} from 'semantic-ui-react';
import { reactionDefaultList } from '../../../../../constant/race';
import { generateId } from '@utils/number';
import { uploadImageFiles } from '@utils/uploadImage';
import _ from 'lodash';
import { RaceFormType } from '../../RaceForm.types';

type Props = {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
};
const ExtraInfoField = ({ race, setRace }: Props) => {
  const [useReaction, setUseReaction] = useState(!!race.reactions || false);

  const handleInstaHashTagChange = (value: string) => {
    const _race = _.cloneDeep(race);
    _race.info.instagramHashtag = value;
    setRace(_race);
  };

  const addReaction = () => {
    if (!useReaction) {
      alert('응원 리액션 사용 여부를 체크해주세요!');
      return;
    }
    const _race = _.cloneDeep(race);
    if (_race.reactions) {
      _race.reactions.push({
        id: generateId(),
        imageUrl: '',
      });
    } else {
      _race.reactions = [
        {
          id: generateId(),
          imageUrl: '',
        },
      ];
    }

    setRace(_race);
  };

  const deleteReaction = (idx: number) => {
    const _race = _.cloneDeep(race);
    _race.reactions.splice(idx, 1);
    setRace(_race);
  };

  const handleReactionImageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const _race = _.cloneDeep(race);
    const { name, files } = e.target;

    if (!files) return;

    const urls = await uploadImageFiles([...files]);
    _race.reactions = [
      ...urls.map((url: string) => ({ imageUrl: url })),
      ..._race.reactions,
    ];
    setRace(_race);
  };

  const toggleUseReaction = () => {
    const _race = _.cloneDeep(race);
    if (!useReaction) {
      _race.reactions = [...reactionDefaultList];
      setUseReaction(true);
    } else {
      _race.reactions = [];
      setUseReaction(false);
    }
    setRace(_race);
  };

  return (
    <>
      <Header as={'h3'}>랜선 대회 추가 정보</Header>
      <Form.Group grouped>
        <GridColumn>
          <Checkbox
            checked={useReaction}
            onChange={toggleUseReaction}
            label={'응원 리액션 사용 여부'}
            style={{
              marginBottom: 20,
            }}
          />
        </GridColumn>
        <label>응원 리액션 정보</label>
        <Label
          style={{ marginLeft: 10, cursor: 'pointer' }}
          content={'추가'}
          onClick={addReaction}
        />
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>
                리액션 이모지 이미지
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <input
                  type="file"
                  multiple
                  name="imageUrl"
                  onChange={handleReactionImageChange}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row style={{ display: 'flex' }}>
              {(race.reactions || []).map((reaction, idx: number) => (
                <Table.Cell key={reaction.id}>
                  <div style={{ textAlign: 'center' }}>
                    <Image src={reaction.imageUrl} size="small" />
                    <Label
                      content={'삭제'}
                      onClick={() => deleteReaction(idx)}
                    />
                  </div>
                </Table.Cell>
              ))}
            </Table.Row>
          </Table.Body>
        </Table>
      </Form.Group>
      <Divider />
      {(race.raceType === 'cook' ||
        race.raceType === 'ohunwan' ||
        race.raceType === 'cheer' ||
        race.raceType === 'record') && (
        <Form.Group grouped>
          <label>인스타그램 해쉬태그 키워드</label>
          <label
            style={{
              color: 'blue',
              marginLeft: 12,
            }}
          >
            * 사용하지 않을 경우 빈 값으로 남겨주세요!
          </label>
          <input
            value={race.info.instagramHashtag}
            onChange={(e) => handleInstaHashTagChange(e.target.value)}
          />
        </Form.Group>
      )}
    </>
  );
};

export default ExtraInfoField;
